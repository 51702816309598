import { RefFormData, FormText } from 'Components/FormComponents';

export default class NotesObjectForm extends RefFormData {
  static isClassComponent = true;

  constructor({ ctrl, readOnly = false, ref }) {
    super();

    if (ref) ref(this);

    this._ctrl = ctrl;
    this._readOnly = readOnly;
    this.root = Object.keys(ctrl.notesFields || []).map(fieldName => <FormText form={this} name={fieldName} label={fieldName} />);
  }

  render(data) {
    this._dataOrig = data || {};
    this._permissions = this._ctrl.notesFieldsPermissions;

    this.getAllFields().forEach(field => {
      const permission = this._permissions[field.name];

      if (permission.read)
        this.set(field.name, this._dataOrig[field.name] || '');

      this.setVisibility(field.name, permission.read);
      this.setDisabled(field.name, this._readOnly || !permission.write);
    });
  }

  getData() {
    const ret = {
      ...this._dataOrig,
    };

    this.getAllFields().forEach(field => {
      const { name } = field;
      if (!this._permissions[name].write)
        return;

      const val = this.get(name);
      if (val) {
        ret[name] = val;
      } else {
        delete ret[name];
      }
    });

    if (!Object.keys(ret).length)
      return null;

    return ret;
  }
}
