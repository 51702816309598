import { ConfAppController } from './ConfAppController';

export class EmbeddedConfAppController extends ConfAppController {
  constructor({
    appConfig,
    alwaysListenOnly = false,
    enableSettings = true,
    getConferenceID,
  }) {
    super({
      appConfig,
      localStorageKey: 'webCall_embeddedConnectParams',
      storedFields: [
        'fromName',
        'email',
        'userID',
      ],

      alwaysListenOnly,
      enableSettings,
    });

    this._getConferenceID = getConferenceID;

    this._isConnectModalOpen = false;
  }

  startConnect() {
    this._isConnectModalOpen = true;
    this.emit('update');
  }

  cancelConnect() {
    this._isConnectModalOpen = false;
    this.emit('update');
  }

  connect(formData) {
    this._isConnectModalOpen = false;
    super.connect(formData);
  }

  get isConnectModalOpen() {
    return this._isConnectModalOpen;
  }

  createFormDefaults(appConfig) {
    return {
      fromName: '',
      email: '',
      userID: '',
    };
  }
}
