import { AppController } from './AppController';
import { PlayerCallController } from './PlayerCallController';
import WindowEventHandler from './WindowEventHandler';
import Exceptions from './Exceptions';

function sanitizeHash(hashParams) {
  return Object.keys(hashParams).reduce((acc, key) => {
    if (key === 'id' || key === 'pin') {
      return acc;
    }

    if (acc) {
      acc += '&';
    }

    return acc + encodeURIComponent(key) + (hashParams[key] ? '=' + encodeURIComponent(hashParams[key]) : '');
  }, '');
}

export class PlayerAppController extends AppController {
  constructor({
    appConfig,
    handleHash = false,
  }) {
    super({
      appConfig,
    });

    this._debugMode = false;

    this.callController = new PlayerCallController({
      instanceName: 'PlayerApp',
    });
    this.addCallController(this.callController);

    this.callController.on('update', () => this.emit('update'));

    this.windowEventHandler = new WindowEventHandler({ ctrl: this, handleHash });

    this.loadStoredParams();

    this.usePinFromForm = true;
    if (handleHash) {
      const { hashParams } = this.windowEventHandler;

      if (hashParams.id)
        this._formDefaults.conferenceID = hashParams.id;

      if (this._formDefaults.conferenceID && 'hide-id' in hashParams && (hashParams['hide-id'] || hashParams['hide-id'] === '')) {
        const conferenceID = this._formDefaults.conferenceID.replace(/[^\d#*]/g, '');
        const matches = conferenceID.match(/^(\d+)\D*/);

        if (matches && matches[1]) {
          this.usePinFromForm = false;
          this._formDefaults.conferenceIDLabel = matches[1];
        }
      }

      window.location.hash = sanitizeHash(hashParams);
    }
  }

  start() {
    this.callController.start();
  }

  connect(formData) {
    this.initiateConnection(this.callController, formData, formData => this._getConnectParams(formData));
  }

  setDebugMode(debugMode) {
    this._debugMode = debugMode;
    this.callController.setDebugMode(debugMode);
  }

  get loadingState() {
    return this.callController.loadingState;
  }

  createFormDefaults(appConfig) {
    return {
      conferenceID: '',
      conferenceIDLabel: '',
    };
  }

  _getConnectParams(formData) {
    let conferenceID;
    if (this._getConferenceID) {
      // used by embedded
      conferenceID = this._getConferenceID();
    } else {
      // used by standalone
      conferenceID = formData.conferenceID.replace(/[^\d#*]/g, '');

      if (!conferenceID.length)
        throw new Exceptions.ConnectionError('ERR_INVALID_CONFERENCE_ID');

      if (this.usePinFromForm) {
        const { pin } = formData;
        if (!pin.match(/^\d+$/)) {
          throw new Exceptions.ConnectionError('ERR_INVALID_PIN');
        }

        conferenceID += `*${pin}`;
      }
    }

    return {
      wsSipURI: this._appConfig.wsSipURI,
      fetchLocationURL: this._appConfig.fetchLocationURL,
      fetchRTCConfigurationURL: this._appConfig.fetchRTCConfigurationURL,
      fetchIceServersURL: this._appConfig.fetchIceServersURL,

      toSipURI: this._appConfig.toUriPrefix + conferenceID + this._appConfig.toUriSuffix,
      fromSipURI: this._appConfig.fromSipURI
        ? `sip:${this._appConfig.fromSipURI}`
        : 'sip:caller@invalid',
      fromName: this._appConfig.fromName,
    };
  }
}
