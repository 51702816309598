import EventEmitter from 'events';
import * as MOS from './MOS';

const GET_STATS_INTERVAL_MS = 1000;

const SAMPLE_BUFFER_SIZE = 5;
const SAMPLE_IGNORE_COUNT = 10;

const SAMPLE_COUNT_CLEAR_WARNING = 0;
const SAMPLE_COUNT_SET_WARNING = 3;

const WARNING_TTL = 5000;

const DEFAULT_WARNING_CONDITIONS = {
  ppl : {
    max: 1
  },
  jitter : {
    max: 0.03
  },
  rtt : {
    max: 400
  },
  mos: {
    min: 3
  }
};

export default class StatsMonitor extends EventEmitter {
  constructor() {
    super();
  }

  start(pc) {
    this._pc = pc;
    this._buffer = [];

    this._totalSamples = 0;
    this._warningConditions = Object.assign({}, DEFAULT_WARNING_CONDITIONS);
    this._warnings = {};

    this._interval = setInterval(this._poll.bind(this), GET_STATS_INTERVAL_MS);

    return this;
  }

  stop() {
    clearInterval(this._interval);
    this._interval = null;

    return this;
  }

  _getStats(getExtended) {
    return Promise.resolve()
      .then(() => this._pc.getStats())
      .then(report => {
        function getCodec(id) {
          var pos, cat = report.get(id);
          if (cat && cat.mimeType) {
            pos = cat.mimeType.indexOf('audio/');
            if (pos === 0) {
              return cat.mimeType.substr(6);
            }
          }

          return false;
        }

        var ts,
            transportId = null,
            transport,
            candidatePair,
            inboundCodecId = null,
            outboundCodecId = null,
            stats = {
              packetsReceived : 0,
              bytesReceived : 0,
              packetsLost : 0,
              jitter : 0,
              rtt : 0,

              packetsSent : 0,
              bytesSent : 0
            };

        report.forEach(cur => {
          ts = ts || cur.timestamp;

          if (cur.isRemote) {
            return;
          }

          switch (cur.type) {
          case 'inbound-rtp':
            if (cur.ssrc === '0') // Edge contains useless inbound-rtp/outbound-rtp categories, skip them
              return;

            stats.timestamp = stats.timestamp || cur.timestamp;
            stats.packetsReceived = cur.packetsReceived;
            stats.bytesReceived = cur.bytesReceived;
            stats.packetsLost  = cur.packetsLost;
            stats.jitter = cur.jitter;

            if (getExtended && cur.codecId)
              inboundCodecId = cur.codecId;

            break;

          case 'outbound-rtp':
            if (cur.ssrc === '0')
              return;

            stats.timestamp = stats.timestamp || cur.timestamp;
            stats.packetsSent = cur.packetsSent;
            stats.bytesSent = cur.bytesSent;

            if (getExtended && cur.codecId)
              outboundCodecId = cur.codecId;

            break;

          case 'transport':
            if (cur.dtlsState == 'connected') {
              transportId = cur.id;
            }
            break;
          }
        });

        stats.timestamp = stats.timestamp || ts;

        if (getExtended) {
          if (inboundCodecId)
            stats.decodeCodec = getCodec(inboundCodecId);

          if (outboundCodecId)
            stats.encodeCodec = getCodec(outboundCodecId);
        }

        if (transportId) {
          transport = report.get(transportId);
          if (transport) {
            candidatePair = report.get(transport.selectedCandidatePairId);
            if (candidatePair) {
              stats.rtt = candidatePair.currentRoundTripTime * 1000;
            }
          }
        }

        return stats;
      });
  }

  _poll() {
    this._getStats(true)
      .then(stats => this._processSample(stats))
      .catch(error => this._error(error));
  }

  _processSample(stats) {
    var sample = this._createSample(stats);

    this._totalSamples++;

    this._addSample(sample);
    if (this._totalSamples > SAMPLE_IGNORE_COUNT) {
      this._processWarnings();
    }
    this.emit('sample', sample);
  }

  _addSample(sample) {
    var buffer = this._buffer;

    buffer.push(sample);

    if (buffer.length > SAMPLE_BUFFER_SIZE) {
      buffer.splice(0, buffer.length - SAMPLE_BUFFER_SIZE);
    }
  }

  _createSample(stats) {
    var prev = this._buffer.length && this._buffer[this._buffer.length - 1],
        prevTotals;

    if (prev) {
      prevTotals = prev.totals;
    } else {
      prevTotals = {
        packetsSent : 0,
        packetsReceived : 0,
        packetsLost : 0,
        bytesReceived : 0,
        bytesSent : 0,
        maxJitter : 0
      };
    }

    var currentPacketsSent = stats.packetsSent - prevTotals.packetsSent;
    var currentPacketsReceived = stats.packetsReceived - prevTotals.packetsReceived;
    var currentPacketsLost = stats.packetsLost - prevTotals.packetsLost;
    var currentInboundPackets = currentPacketsReceived + currentPacketsLost;
    var totalInboundPackets = stats.packetsReceived + stats.packetsLost;
    var lossRatio = (currentInboundPackets > 0) ?
      (currentPacketsLost / currentInboundPackets) : 0;
    var ppl = lossRatio * 100;

    var totals = {
      packetsReceived : stats.packetsReceived,
      packetsLost : stats.packetsLost,
      packetsSent : stats.packetsSent,
      totalPpl : (totalInboundPackets > 0) ?
        (stats.packetsLost / totalInboundPackets) * 100 : 100,
      bytesReceived : stats.bytesReceived,
      bytesSent : stats.bytesSent,
      maxJitter : Math.max(stats.jitter !== undefined ? stats.jitter : 0, prevTotals.maxJitter)
    };

    var mos = MOS.calculate(ppl, stats.rtt);

    var ret = {
      totals,
      ppl,
      timestamp : stats.timestamp,
      packetsReceived : currentPacketsReceived,
      packetsLost : currentPacketsLost,
      packetsSent : currentPacketsSent,
      bytesReceived : stats.bytesReceived - prevTotals.bytesReceived,
      bytesSent : stats.bytesSent - prevTotals.bytesSent,
      jitter : stats.jitter,
      rtt : stats.rtt,
      mos : mos.mos,
      rFactor : mos.rFactor,
      qualityLevel: getQualityLevel(mos.mos),
    };

    if (stats.encodeCodec)
      ret.encodeCodec = stats.encodeCodec;

    if (stats.decodeCodec)
      ret.decodeCodec = stats.decodeCodec;

    return ret;
  }

  _processWarnings() {
    for (var stat in this._warningConditions) {
      this._checkWarningConditions(stat);
    }
  }

  _checkWarningConditions(stat) {
    var opts = this._warningConditions[stat],
        samples = this._buffer,
        values = samples.map(function (sample) { return sample[stat]; });

    // skip check if we have invalid values fir that stat in any of the samples
    if (values.some(function (value) { return value === undefined || value === null; })) {
      return;
    }

    var count,
        stateChanged = false;

    if (opts.max !== undefined) {
      count = values.reduce(function(total, value) { return total += (value > opts.max) ? 1 : 0; }, 0);
      if (this._processWarningCondition(stat, 'max', count)) {
        stateChanged = true;
      }
    }

    if (opts.min !== undefined) {
      count = values.reduce(function(total, value) { return total += (value < opts.min) ? 1 : 0; }, 0);
      if (this._processWarningCondition(stat, 'min', count)) {
        stateChanged = true;
      }
    }

    if (stateChanged) {
      this.emit('warningUpdate', Object.assign({}, this._warnings));
    }
  }

  // returns true if warning has been added or cleared
  _processWarningCondition(stat, conditionType, count) {
    var stateChanged = false;

    if (count >= SAMPLE_COUNT_SET_WARNING) {
      stateChanged = this._addWarning(stat, conditionType);
    } else if (count <= SAMPLE_COUNT_CLEAR_WARNING) {
      stateChanged = this._clearWarning(stat, conditionType);
    }

    return stateChanged;
  }

  _addWarning(stat, conditionType) {
    var name = stat + ':' + conditionType;
    if (this._warnings[name]) {
      return false;
    }
    this._warnings[name] = {
      timestamp : Date.now()
    };

    return true;
  }

  _clearWarning(stat, conditionType) {
    var name = stat + ':' + conditionType;
    if (!this._warnings[name] || Date.now() - this._warnings[name].ts < WARNING_TTL) {
      return false;
    }

    delete this._warnings[name];

    return true;
  }

  _error(error) {
    this.stop();
    this.emit('error', error);
  }
}

function getQualityLevel(mos) {
  if (mos >= 3.8) {
    return 4;
  } else if (mos >= 3.3) {
    return 3;
  } else if (mos >= 2.8) {
    return 2;
  } else if (mos >= 2.4) {
    return 1;
  }

  return 0;
}
