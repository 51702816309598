import localStorage from 'Browser/localStorage';

import { CallController } from './CallController';
import ConfClient from './ConfClient';
import { DebugConfStatusController } from './DebugController';

import s from './strings';

export const OUT_GAIN_MIN = -15;
export const OUT_GAIN_MAX = 15;

const COMMANDS = [
  {
    name: 'changeRole',
    desc: 'changeRole (pin)',
    host: false,
  },
  {
    name: 'setInGain',
    desc: 'setInGain (default: 0)',
    host: false,
  },
  {
    name: 'setOutGain',
    desc: 'setOutGain (default: 0)',
    host: false,
  },
  {
    name: 'adjustInGain',
    desc: 'adjustInGain (positive value to increase, negative to decrease)',
    host: false,
  },
  {
    name: 'adjustOutGain',
    desc: 'adjustOutGain (positive value to increase, negative to decrease)',
    host: false,
  },
  {
    name: 'moh',
    desc: 'moh (default: toggle)',
    host: false,
  },
  {
    name: 'mute',
    desc: 'mute (default: toggle)',
    host: false,
  },
  {
    name: 'raiseHand',
    desc: 'raiseHand (default: toggle)',
    host: false,
  },
  {
    name: 'lock',
    desc: 'lock (default: toggle)',
    host: true,
  },
  {
    name: 'callRecording',
    desc: 'callRecording (values: 0 - stop, 1 - start, 2 - pause; default: toggle)',
    host: true,
  },
  {
    name: 'confMode',
    desc: 'confMode (prev, presentation, conversation, qa, hostsOnly)',
    host: true,
  },
  {
    name: 'enterMode',
    desc: 'enterMode (chime, name, none, default)',
    host: true,
  },
  {
    name: 'exitMode',
    desc: 'exitMode (chime, name, none, default)',
    host: true,
  },
  {
    name: 'enterExitMode',
    desc: 'enterExitMode (chime, name, none, default)',
    host: true,
  },
  {
    name: 'help',
    desc: 'help (no value)',
    host: false,
  },
  {
    name: 'hostConfirm',
    desc: 'hostConfirm (no value)',
    host: true,
  },
  {
    name: 'startConference',
    desc: 'startConference (no value)',
    host: true,
  },
  {
    name: 'endConference',
    desc: 'endConference (no value)',
    host: true,
  },
  {
    name: 'noop',
    desc: 'noop (no value)',
    host: false,
  },
  {
    name: 'rollCall',
    desc: 'rollCall (no value)',
    host: false,
  }
];

export class ConfCallController extends CallController {
  constructor(options) {
    super(options);

    this._hideHostControls = options.hideHostControls;

    this._isChangeRoleOpen = false;
    this._isEndConferenceOpen = false;

    this.debugConfStatusController = new DebugConfStatusController();
  }

  _initClient(clientOpts) {
    this.client = new ConfClient(clientOpts);

    this.client.on('confUpdate', () => {
      this.emit('update');
      this.emit('confUpdate', this.client.status);
      this.debugConfStatusController.update(this.client.status);
    });
  }

  onConnectStart() {
    super.onConnectStart();

    this.debugConfStatusController.clear();
  }

  onDisconnected() {
    super.onDisconnected();

    this._isChangeRoleOpen = false;
    this._isEndConferenceOpen = false;
  }

  toggleMute() {
    this.log('toggleMute()');
    this.client.toggleMute();
    this.emit('update');
  }

  toggleHold() {
    this.log('toggleHold()');
    this.client.toggleHold();
    this.emit('update');
  }

  toggleHandRaised() {
    this.log('toggleHandRaised()');
    this.client.toggleHandRaised();
  }

  toggleMusicOnHold() {
    this.log('toggleMusicOnHold()');
    this.client.toggleMusicOnHold();
  }

  toggleLocked() {
    this.log('toggleLocked()');
    this.client.toggleLocked();
  }

  toggleRecording() {
    this.log('toggleRecording()');
    this.client.toggleRecording();
  }

  startConference() {
    this.client.startConference();
  }

  finishNameRecording() {
    this.client.sendDTMF('#');
  }

  setOutGain(value) {
    this.log(`setOutGain(${value})`);
    localStorage.webCallConfVolume = value;
    this.client.setOutGain(value);
  }

  setConfMode(value) {
    this.log(`setConfMode(${value})`);
    this.client.setConfMode(value);
  }

  changeRoleStart() {
    this._isChangeRoleOpen = true;
    this.emit('update');
  }

  changeRoleSubmit(pin) {
    this.log('changeRoleSubmit()');
    this.client.changeRole(pin);

    this._isChangeRoleOpen = false;
    this.emit('update');
  }

  changeRoleCancel() {
    this._isChangeRoleOpen = false;
    this.emit('update');
  }

  endConferenceStart() {
    this._isEndConferenceOpen = true;
    this.emit('update');
  }

  endConferenceSubmit() {
    this.log('endConferenceSubmit()');
    this.client.endConference();

    this._isEndConferenceOpen = false;
    this.emit('update');
  }

  endConferenceCancel() {
    this._isEndConferenceOpen = false;
    this.emit('update');
  }

  sendConfCommand(command, value) {
    this.client.sendConfCommand(command, value);
  }

  get isChangeRoleOpen() {
    return this._isChangeRoleOpen;
  }

  get isEndConferenceOpen() {
    return this._isEndConferenceOpen;
  }

  get muted() {
    return this.client.getConfMute();
  }

  get confStatus() {
    return this.client.getConfStatus();
  }

  get confMode() {
    return this.client.getConfMode();
  }

  get host() {
    return this.client.getHost();
  }

  get muteLocked() {
    return this.client.getMuteLocked();
  }

  get locked() {
    return this.client.getLocked();
  }

  get recording() {
    return this.client.getRecording() === 1;
  }

  get handRaised() {
    return this.client.getHandRaised();
  }

  get musicOnHold() {
    return this.client.getMusicOnHold() && this.confStatus !== 'running';
  }

  get isNameRecording() {
    return this.client.getCallStatus() === 'recordName';
  }

  get outGain() {
    return this.client.getOutGain();
  }

  get statusMessageText() {
    const { confStatus } = this;

    let str = s.CONF_STATUS_MAP[confStatus] || confStatus;
    if (this.host)
      str = `${s.lblHost} ${str}`;

    return str;
  }

  get statusMessageVisible() {
    return !this.canStartConf && !this.isNameRecording;
  }

  get canStartConf() {
    if (this.confStatus !== 'pending')
      return false;

    const startMode = this.client.getConfStartMode();
    return this.host && startMode && startMode.toLowerCase() === 'hostconfirms' && !this.isNameRecording;
  }

  get canChangeConfMode() {
    if (!this.host)
      return false;

    return this.confMode !== 'broadcast';
  }

  get hideHostControls() {
    return this._hideHostControls;
  }

  get availableCommands() {
    const { host } = this;
    return COMMANDS.filter(cmd => host ? true : !cmd.host);
  }
}
