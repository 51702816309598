import $ from 'jquery';

import ContextMenu from 'Components/ContextMenu';
import { hide, show, cachedUpdate } from 'Components/domHelpers';
import { Cond } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import TbIcons from 'Components/TbIcons';
import { secondsToHHMMSS } from 'DateTime';

import CallListTable from './CallListTable';
import { ToggleButton } from './LcmComponents';
import CallCommand from './CallCommand';
import CommentDialog from './CommentDialog';
import { CallerInfoAddressBookModal } from './CallerInfoModal';
import getRecordingURLs from './getRecordingURLs';
import HandRaisingModal from './HandRaisingModal';
import isPSTN from './isPSTN';
import MakeCallModal from './MakeCallModal';
import ModalConfirm from './ModalConfirm';
import playNameRecording from './playNameRecording';
import SingleInputModal from './SingleInputModal';
import WebCallLoader from './WebCallLoader';
import s from './strings';
import errors from './errors';

const SELECT_PROP = 'actionSelect';

export default class CallList {
  static isClassComponent = true;

  constructor({ config, onRetry, ctrl, ref }) {
    ref(this);

    this._ctrl = ctrl;
    this._handRaisingModal = new HandRaisingModal({ ctrl });

    this._actionCall                = null;
    this._pendingActionTarget       = null;

    this._modalEndConference = new ModalConfirm({
      title: s.lblDialogTitleEndConference,
      message: s.lblAreYouSureEndConference,
      confirmLabel: s.lblDialogTitleEndConference,
      danger: true,
      icon: TbIcons.CROSS_CIRCLE,
      confirm: () => {
        this._ctrl.endConference();
      },
    });

    this._modalMergeConference = new ModalConfirm({
      title: s.lblDialogTitleMergeSubConference,
      message: s.lblAreYouSureMergeConference,
      confirmLabel: s.lblDialogTitleMergeSubConference,
      icon: TbIcons.CONF_MERGE,
      confirm: () => {
        this._ctrl.endConference();
      },
    });

    this._modalAssignReference = new CommentDialog('active');

    this._modalSendToSubConf = new SingleInputModal({
      title: s.lblSendToSubConference,
      label: s.lblSubConference,
      invalidErrorMsg: errors.ERR_INVALID_SUB_CONFERENCE_REQUIRED,
      onSave: data => {
        CallCommand.setSubConf(this._pendingActionTarget, data);
      },
    });

    this._modalSendToConf = new SingleInputModal({
      title: s.lblSendToAnotherConference,
      label: s.lblConferenceID,
      invalidErrorMsg: errors.ERR_INVALID_CONFERENCE_ID_REQUIRED,
      onSave: data => {
        const conferenceID = data.replace(/\D/g, '');
        CallCommand.sendToConference(this._pendingActionTarget, conferenceID);
      },
    });

    this._makeCallModal = new MakeCallModal(this._ctrl);

    this._buttons = {};

    const hooks = this.hooks = new Hooks();
    const hooksFeatures = this.hooksFeatures = new Hooks();

    const formatDuration = val => val === null ? '' : secondsToHHMMSS(val);
    const formatRecordCalls = val => val === null
      ? ''
      : val === 1 ? s.lblOn : s.lblOff;
    const formatConfMode = val => val === null ? '' : s.CONF_MODE_MAP[val];

    this.root =
      <>
        <div class="conf-controls">
          <div class="btn-group context-menu rw-only btn-select-all">
            <ToggleButton className="btn btn-primary" ref={this._buttons.selectionCheckbox} onclick={e => this._onSelectionCheckbox(e)} />
            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ref={this._buttons.selectionMenu}><span></span></button>
            <ul class="dropdown-menu">
            </ul>
          </div>

          <button type="button" class="btn btn-primary rw-only btn-mute-all" ref={this._buttons.muteAll} onclick={() => this._ctrl.setSelectedMute(SELECT_PROP, true)}>{s.lblMuteAll}</button>
          <button type="button" class="btn btn-primary rw-only btn-unmute-all" ref={this._buttons.unmuteAll} onclick={() => this._ctrl.setSelectedMute(SELECT_PROP, false)}>{s.lblUnmuteAll}</button>

          <div class="context-menu btnAction rw-only" ref={this._selectionActionMenu}>
            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ref={this._buttons.selectionActionButton}>
              <span>{s.lblAction}</span>
            </button>
            <ul class="dropdown-menu">
            </ul>
          </div>

          <div class="conf-status rw-only">
            <div>
              <span class="status-label">{s.lblStatusCallers}:</span>
              <span use:hook={hooks.text('callerCountDisplay')} />
            </div>

            <div>
              <span class="status-label">{s.lblStatusStarted}:</span>
              <span use:hook={hooks.text('startedDateDisplay')} />
            </div>

            <div>
              <span class="status-label">{s.lblStatusDuration}:</span>
              <span use:hook={hooks.text('duration', formatDuration)} />
            </div>
          </div>

          <button
            type="button"
            class="btn btn-primary tbicon rw-only in-main-conf"
            title={s.lblEndConference}
            ref={this._buttons.endConference}
            onclick={() => this._modalEndConference.display()}
          >
            {TbIcons.CROSS_CIRCLE}
          </button>
          <button
            type="button"
            class="btn btn-primary tbicon rw-only in-sub-conf"
            title={s.lblMergeWithMainConference}
            ref={this._buttons.mergeConference}
            onclick={() => this._modalMergeConference.display()}
          >
            {TbIcons.CONF_MERGE}
          </button>
          <button
            type="button"
            class="btn btn-primary tbicon rw-only in-main-conf"
            title={s.Broadcast.enqueue}
            use:hook={hooksFeatures.show('broadcast')}
            ref={this._buttons.broadcastAddModalOpen}
            onclick={() => ctrl.broadcastController.addModalOpen()}
          >
            {TbIcons.PLAYLIST_PLAY}
          </button>
          <button
            type="button"
            class="btn btn-primary tbicon rw-only in-main-conf"
            title={s.lblEditReference}
            ref={this._buttons.assignReference}
            onclick={() => this._onAssignReference()}
          >
            {TbIcons.COMMENT}
          </button>
          <button
            type="button"
            class="btn btn-primary tbicon rw-only requires-make-call"
            title={s.lblDialOutToParticipant}
            ref={this._buttons.makeCall}
            onclick={() => this._makeCallModal.show()}
          >
            {TbIcons.MAKE_CALL}
          </button>

          <div class="conf-status ro-only">
            <div class="in-main-conf">
              <span class="status-label">{s.lblMode}:</span>
              <span use:hook={hooks.text('confMode', formatConfMode)} />
              <span class="tbicon-lock" use:hook={hooks.show('locked')} />
            </div>

            <div>
              <span class="status-label">{s.lblStatusCallers}:</span>
              <span use:hook={hooks.text('callerCountDisplay')} />
            </div>

            <div>
              <span class="status-label">{s.lblStatusStarted}:</span>
              <span use:hook={hooks.text('startedDateDisplay')} />
            </div>

            <div>
              <span class="status-label">{s.lblStatusDuration}:</span>
              <span use:hook={hooks.text('duration', formatDuration)} />
            </div>

            <div class="in-main-conf">
              <span class="status-label">{s.lblRecording}:</span>
              <span use:hook={hooks.text('recordCalls', formatRecordCalls)} />
            </div>
          </div>

          <input type="text" class="form-control conf-controls-filter" name="filter" aria-label={s.lblFilter} onkeyup={() => this.changeFilter()} ref={this._filterInput} />
        </div>

        <div class="table-position-wrapper">
          <div class="divCalls">
            <div class="status-overlays" ref={this._statusOverlays}>
              <div class="panel panel-primary panel-icon" ref={this._confPending}>
                <div class="panel-heading">
                  <h3 class="panel-title in-main-conf">{s.lblConferencePending}</h3>
                  <h3 class="panel-title in-sub-conf">{s.lblSubConferencePending}</h3>
                </div>
                <div class="panel-body">
                  <div class="panel-icon-body">
                    <div class="icon"></div>
                    <div class="content">
                      <div class="in-main-conf">
                        <span class="message-text">{s.lblNoConferenceInProgress}</span><br/>
                        <button type="button" class="btn btn-link rw-only requires-make-call" onclick={() => this._makeCallModal.show()}>{s.lblDialOutToParticipant}</button><br/>
                        <Cond test={WebCallLoader.startPlayer}>
                          <button
                            type="button"
                            class="btn btn-link rw-only"
                            use:hook={hooksFeatures.show('webCall')}
                            onclick={() => WebCallLoader.startPlayer()}
                          >
                            {s.lblPlayAudioFile}
                          </button>
                        </Cond>
                        <div
                          class="rw-only"
                          use:hook={hooksFeatures.show('broadcast')}
                        >
                          <br/>
                          <button
                            type="button"
                            class="btn btn-link"
                            onclick={() => ctrl.broadcastController.addModalOpen()}
                          >
                            {s.Broadcast.enqueue}
                          </button>
                        </div>
                      </div>
                      <div class="in-sub-conf">
                        <span class="message-text">{s.lblNoSubConferenceInProgress}</span><br/>
                      </div>
                    </div>
                  </div>
                  <Cond test={config.confPendingMessage}>
                    <div class="mt-4" innerHTML={config.confPendingMessage} />
                  </Cond>
                </div>
              </div>

              <div class="panel panel-danger panel-icon" ref={this._bridgeInactive}>
                <div class="panel-heading">
                  <h3 class="panel-title">{s.lblBridgeUnavailable}</h3>
                </div>
                <div class="panel-body">
                  <div class="panel-icon-body">
                    <div class="icon"></div>
                    <div class="content">
                      <span class="message-text">{s.lblBridgeTemporarilyUnavailable}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="panel panel-danger panel-icon connection-error" ref={this._connectionError}>
                <div class="panel-heading">
                  <h3 class="panel-title">{s.lblError}</h3>
                </div>
                <div class="panel-body">
                  <div class="panel-icon-body">
                    <div class="icon"></div>
                    <div class="content">
                      <span class="message-text">{s.lblRetryErrorMessage}</span><br/>
                      <span class="message-text">{s.lblTryingAgain} <span ref={this._retryLabel} /></span>
                      <button type="button" class="btn btn-link btn-retry" onclick={onRetry}>{s.lblRetryNow}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <CallListTable ref={this._callListTable} ctrl={ctrl} colConfig={config.colConfig} fillerRowTotal={config.fillerRowTotal} onUpdate={() => this.render()} onCellButtonClick={e => this._onCellButtonClick(e)} />
          </div>
        </div>
      </>;

    this._updaters = {
      state: cachedUpdate(val => {
        switch (val) {
        case 'active':
          hide(this._statusOverlays);
          break;

        case 'pending':
          hide(this._connectionError);
          hide(this._bridgeInactive);
          show(this._confPending);
          show(this._statusOverlays);
          break;

        case 'error':
          hide(this._bridgeInactive);
          hide(this._confPending);
          show(this._connectionError);
          show(this._statusOverlays);
          break;

        case 'inactive':
          hide(this._confPending);
          hide(this._connectionError);
          show(this._bridgeInactive);
          show(this._statusOverlays);
          break;
        }
      }),
      retryWaitSecs: cachedUpdate(val => {
        this._retryLabel.textContent = val === 0 ?
          s.lblTryingAgainNow :
          `${s.lblTryingAgainIn} ${val}s`;
      }),
      isConfActive: cachedUpdate(val => {
        if (!val && ContextMenu.isOpen())
          ContextMenu.close();

        Object.values(this._buttons).forEach(button => button.disabled = !val);

        this._filterInput.disabled = !val;
        if (!val) {
          this._filterInput.value = '';
          this._callListTable.setFilter('');
        }

        this._callListTable.setDisabled(!val);
      }),
    };

    this.init(config);
  }

  render() {
    const {
      writable,
      state,
      retryWaitSecs,
      isConfActive,

      setCallProps,
      totals,

      namePlayingParticipantID,
    } = this._ctrl;

    this._updaters.state(state);
    this._updaters.retryWaitSecs(retryWaitSecs);
    this._updaters.isConfActive(isConfActive);

    this.hooks.run(this._ctrl);
    this.hooksFeatures.run(this._ctrl.features);

    if (ContextMenu.isOpen()) {
      return;
    }

    const { showCallerID } = this._ctrl.features;

    const extra = {
      writable,
      setCallProps,
      totals,
      namePlayingParticipantID,
      showCallerID,
    };

    this._callListTable.render(extra);

    const { allSelected, someSelected } = this._ctrl.getSelectState(SELECT_PROP);
    let checked = false;
    let indeterminate = false;
    if (allSelected) {
      checked = true;
    } else if (someSelected) {
      indeterminate = true;
    }
    this._buttons.selectionCheckbox.toggleAttribute('data-checked', checked);
    this._buttons.selectionCheckbox.toggleAttribute('data-indeterminate', indeterminate);

    if (someSelected) {
      this._buttons.muteAll.textContent = s.lblMute;
      this._buttons.unmuteAll.textContent = s.lblUnMute;
    } else {
      this._buttons.muteAll.textContent = s.lblMuteAll;
      this._buttons.unmuteAll.textContent = s.lblUnmuteAll;
    }
  }

  init(config) {
    this._callerInfoModal = new CallerInfoAddressBookModal({
      ctrl: this._ctrl,
    });

    this._modalDisconnectCall = new ModalConfirm({
      title: s.lblDialogTitleEndCall,
      message: s.lblAreYouSureDropCaller,
      confirm: () => {
        CallCommand.sendDisconnectCall(this._pendingActionTarget);
      },
    });

    // re-render when a contextMenu is closed
    $(this.root).on('hidden.tb.contextMenu', () => this.render());

    $(this.root).on('show.tb.contextMenu', '.btn-select-all', () => {
      var menu = [
        {
          value : 'all',
          label : s.lblAll
        },
        {
          value : 'none',
          label : s.lblNone
        },
        {
          value : 'starred',
          label : s.lblStarred
        },
        {
          value : 'hosts',
          label : s.lblHosts
        },
        {
          value : 'participants',
          label : s.lblParticipants
        },
        {
          value : 'raisedHands',
          label : s.lblSelectRaisedHands
        },
        {
          value : 'onHold',
          label : s.lblOnHold
        }
      ];

      return menu;
    });

    $(this.root).on('select.tb.contextMenu', '.btn-select-all', event => {
      var val = $(event.target).attr('href');

      this.filterSelect(val);
    });

    $(this._selectionActionMenu).on('show.tb.contextMenu', event => {
      this._actionCall = null;

      const { someSelected } = this._ctrl.getSelectState(SELECT_PROP);

      const menu = someSelected
        ? this._getActionMenu()
        : [{
          value: 'unstarAll',
          label: s.lblRemoveAllStars
        }];

      if (WebCallLoader.startPlayer && this._ctrl.features.webCall) {
        menu.push({
          value : 'player',
          label : s.lblPlayAudioFile
        });
      }

      if (!someSelected) {
        menu.push({
          disabled : true,
          label    : s.lblSelectCallsToSeeMoreActions
        });
      }

      return menu;
    });

    $(this._callListTable.root).on('show.tb.contextMenu', event => {
      const participantID = this._callListTable.getKeyByElement(event.target);
      this._actionCall = this._ctrl.getCallByParticipantID(participantID);

      return this._getActionMenu(this._actionCall);
    });

    $(this.root).on('select.tb.contextMenu', '.btnAction', event => {
      var val = $(event.target).attr('href');

      let participantID = null;
      let target = [];

      if (this._actionCall) {
        participantID = this._actionCall.participantID;
        target.push(this._actionCall.callID);
      } else {
        target = this._ctrl
          .getCalls(call => !call.disconnected && call[SELECT_PROP])
          .map(call => call.callID);
      }

      this._pendingActionTarget = target;

      switch (val) {
      case 'star':
        CallCommand.setStar(target, 1);
        break;

      case 'unstar':
        CallCommand.setStar(target, 0);
        break;

      case 'unstarAll':
        this._ctrl.unstarAll();
        break;

      case 'sendToMainConf':
        CallCommand.setSubConf(target, '');
        break;

      case 'sendToSubConf':
        this._modalSendToSubConf.display();
        break;

      case 'sendToConf':
        this._modalSendToConf.display();
        break;

      case 'host':
        CallCommand.setHost(target, 1);
        break;

      case 'mute':
        CallCommand.setMute(target, 1);
        break;

      case 'unmute':
        CallCommand.setMute(target, 0);
        break;

      case 'hold':
        CallCommand.setHold(target, 1);
        break;

      case 'unhold':
        CallCommand.setHold(target, 0);
        break;

      case 'drop':
        this._modalDisconnectCall.display();
        break;

      case 'callerInfo':
        this.openCallerInfo(participantID);
        break;

      case 'raiseHand':
        if (participantID) {
          this._ctrl.handRaise(participantID);
        }
        break;

      case 'lowerHand':
        if (participantID) {
          this._ctrl.handLower(participantID);
        } else {
          this._ctrl.handLowerSelected(SELECT_PROP);
        }
        break;

      case 'gainInc':
        CallCommand.adjustGain(target, 4);
        break;

      case 'gainDec':
        CallCommand.adjustGain(target, -4);
        break;

      case 'player':
        WebCallLoader.startPlayer();
        break;

      case 'talkToOperator':
        this._ctrl.talkToOperator(target);
        break;

      case 'sendToHelpQueue':
        this._ctrl.sendToHelpQueue(participantID);
        break;
      }

      if (!this._actionCall)
        this.filterSelect('none');
    });
  }

  filterSelect(type) {
    this._ctrl.select(SELECT_PROP, type);
  }

  openCallerInfo(participantID) {
    if (!this._ctrl.writable)
      return;

    const call = this._ctrl.getCallByParticipantID(participantID);

    this._callerInfoModal.display(call);
  }

  changeFilter() {
    this._callListTable.changeFilter(this._filterInput.value);
  }

  _onAssignReference() {
    this._modalAssignReference.open(this._ctrl.comment);
  }

  _onSelectionCheckbox(e) {
    const checked = e.target.hasAttribute('data-checked');
    this.filterSelect(checked ? 'none' : 'all');
  }

  _getActionMenu(call = null) {
    const menu = [];

    if (!call || !call.starred)
      menu.push({
        value : 'star',
        label : s.lblAddStar
      });

    if (!call || call.starred)
      menu.push({
        value : 'unstar',
        label : s.lblRemoveStar
      });


    if (this._ctrl.features.subConfs && !(call && call.isBroadcast)) {
      menu.push({
        value : 'sendToSubConf',
        label : s.lblSendToSubConference
      });

      if (this._ctrl.isSubConfActive) {
        menu.push({
          value : 'sendToMainConf',
          label : s.lblSendToMainConference
        });
      }
    }

    if (this._ctrl.operatorFlag) {
      menu.push({
        value : 'sendToConf',
        label : s.lblSendToAnotherConference
      });
    }

    if (!call || !call.host) {
      menu.push({
        value : 'host',
        label : s.lblPromote
      });
    }

    if (call && call.muted)
      menu.push({
        value : 'unmute',
        label : s.lblUnMuteCaller
      });

    if (call && !call.muted)
      menu.push({
        value : 'mute',
        label : s.lblMuteCaller
      });

    if (!call || call.hold)
      menu.push({
        value : 'unhold',
        label : s.lblTakeOffHold
      });

    if (!call || !call.hold)
      menu.push({
        value : 'hold',
        label : s.lblPlaceOnHold
      });

    if (call) {
      menu.push({
        value : 'gainInc',
        label : s.lblIncreaseVolume
      });

      menu.push({
        value : 'gainDec',
        label : s.lblDecreaseVolume
      });
    }

    if (this._ctrl.operatorFlag) {
      const { operatorParticipantID } = this._ctrl;

      if (operatorParticipantID && (!call || call.participantID !== operatorParticipantID)) {
        menu.push({
          value : 'talkToOperator',
          label : s.lblTalkToOperator
        });
      }

      if (call && (!operatorParticipantID || call.participantID !== operatorParticipantID)) {
        menu.push({
          value : 'sendToHelpQueue',
          label : s.lblSendToHelpQueue,
        });
      }
    }

    menu.push({
      value : 'drop',
      label : (call ? s.lblDropCaller : s.lblDropCallers)
    });

    if (call)
      menu.push({
        value : 'callerInfo',
        label : (isPSTN(call)) ? s.lblAddToCallerList : s.lblUpdateCallerInfo
      });

    if (call && call.handRaisedIndexDisplay === null) {
      menu.push({
        value : 'raiseHand',
        label : s.lblRaiseHand
      });
    }

    if (!call || call.handRaisedIndexDisplay !== null) {
      menu.push({
        value : 'lowerHand',
        label : s.lblLowerHand
      });
    }

    return menu;
  }

  _onCellButtonClick({ key: participantID, colId }) {
    switch (colId) {
    case 'nameRecorded':
      this._playNameRecording(participantID);
      break;

    case 'callerName':
      this.openCallerInfo(participantID);
      break;

    case 'handRaised':
      this._handRaisingModal.open(participantID);
      break;

    case SELECT_PROP:
    case 'starred':
    case 'host':
    case 'muted':
      this._ctrl.toggleCallProperty(participantID, colId);
      break;
    }
  }

  _playNameRecording(participantID) {
    const { callID } = this._ctrl.getCallByParticipantID(participantID);
    const urls = getRecordingURLs('', 'LCM', 'getCallNameRecording', { callID });

    playNameRecording(urls, this._ctrl.namePlayingParticipantID === participantID, isPlaying => {
      this._ctrl.namePlayingParticipantID = isPlaying ? participantID : null;
    });
  }
}
