import EventEmitter from 'events';

import { TimelineDataSeries } from 'Components/Graphs/TimelineDataSeries';

class DebugController extends EventEmitter {
  constructor({ fields }) {
    super();

    this.fields = fields;
    this.clearFields();
  }

  updateFields(data) {
    Object.keys(this.fields).forEach(field => {
      const val = data[field];
      this[field] = this.fields[field].tableToFixed
        ? val.toFixed(this.fields[field].tableToFixed)
        : data[field];
    });
  }

  clearFields() {
    Object.keys(this.fields).forEach(field => this[field] = null);
  }

  clear() {
    this.clearFields();
    this.emit('update');
  }
}

export class DebugClientStatusController extends DebugController {
  constructor() {
    super({
      fields: {
        clientMute: {},
        clientHold: {},
      },
    });
  }

  update(clientStatus) {
    this.updateFields(clientStatus);

    this.emit('update');
  }
}

export class DebugCallStatsController extends DebugController {
  constructor() {
    super({
      fields: {
        packetsReceived: {},
        packetsLost: {},
        packetsSent: {},
        totalPpl: {
          tableToFixed: 2,
        },
        bytesReceived: {},
        bytesSent: {},
        maxJitter: {},

        qualityLevel: {},
        mos: {
          tableToFixed: 2,
        },
        rFactor: {
          tableToFixed: 2,
        },
        ppl: {
          tableToFixed: 2,
        },
        rtt: {
        },
      },
    });

    this.timestamp = 0;

    this.dataSeries = {};
    for (let statName of [ 'mos', 'rFactor', 'ppl', 'rtt' ])
      this.dataSeries[statName] = new TimelineDataSeries(statName);
  }

  update(statsSample) {
    const { timestamp } = statsSample;
    if (timestamp <= this.timestamp) {
      return;
    }
    this.timestamp = timestamp;

    const {
      mos,
      rFactor,
      ppl,
      rtt,
    } = statsSample;

    this.dataSeries.mos.addPoint(timestamp, mos);
    this.dataSeries.rFactor.addPoint(timestamp, rFactor);
    this.dataSeries.ppl.addPoint(timestamp, ppl);
    this.dataSeries.rtt.addPoint(timestamp, rtt);

    this.updateFields(statsSample);

    this.emit('update');
  }

  clear() {
    this.dataSeries.mos.clear();
    this.dataSeries.rFactor.clear();
    this.dataSeries.ppl.clear();
    this.dataSeries.rtt.clear();
    super.clear();
  }
}

export class DebugWarningStatusController extends EventEmitter {
  constructor() {
    super();
    this.warnings = [];
  }

  update(warnings) {
    this.warnings = warnings;
    this.emit('update');
  }

  clear() {
    this.warnings = [];
    this.emit('update');
  }
}

export class DebugDevicesController extends EventEmitter {
  constructor() {
    super();
    this.devices = '';
  }

  update(devices) {
    this.devices = JSON.stringify(devices, null, 2);
    this.emit('update');
  }
}

export class DebugConfStatusController extends DebugController {
  constructor() {
    super({
      fields: {
        status: {},
        muted: {},
        hold: {},
        callID: {},
        participantID: {},
        host: {},
        handRaised: {},
        inGain: {},
        outGain: {},
        confMode: {},
        confStatus: {},
        confLocked: {},
        recordCall: {},
        musicOnHold: {},
        muteLocked: {},
      },
    });
  }

  update(confStatus) {
    this.updateFields(confStatus);

    this.emit('update');
  }
}
