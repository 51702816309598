import { emptyNode, toggleClass } from 'Components/domHelpers';
import { Cond } from 'Components/FormComponents';

const TAB_ACTIVE_CLASS = 'switcher-tab-active';

export default class Tabs {
  static isClassComponent = true;

  constructor({ tabs = [], tabsRight = [], ref }) {
    if (ref) {
      ref(this);
    }

    this._tabs = [];
    this._tabsKeyed = {};

    this.root = (
      <div class="tab-toolbar">
        <ul class="tab-group" ref={this._tabsLeft} />
        <Cond test={tabsRight.length}>
          <ul class="tab-group tab-group-right">
            {tabsRight.map(tab => this._addTab(tab))}
          </ul>
        </Cond>
      </div>
    );

    if (tabs.length) this.setTabs(tabs);
  }

  setTabs(tabs) {
    this._tabs = [];
    this._tabsKeyed = {};

    tabs.map(tab => this.addTab(tab));
  }

  setActive(key) {
    this._tabs.forEach(tab => {
      toggleClass(tab.li, TAB_ACTIVE_CLASS, tab.key === key);
    });
  }

  setLabel(key, label) {
    const tab = this._tabsKeyed[key];
    if (!tab) {
      return;
    }

    if (typeof label === 'string') {
      tab.a.textContent = label;
    } else if (label instanceof Node) {
      emptyNode(tab.a);
      tab.a.append(label);
    }
  }

  addTab(tab, afterKey) {
    const li = this._addTab(tab);

    if (afterKey) {
      this._tabsKeyed[afterKey].li.after(li);
    } else {
      this._tabsLeft.append(li);
    }
  }

  _addTab(tab) {
    const { key, label, target, href = '#', cssClass = null } = tab;

    this._tabs.push(tab);
    if (key) {
      this._tabsKeyed[key] = tab;
    }

    return (
      <li classList={{'switcher-tab': true, ...(cssClass && { [cssClass]: true })}} tabindex="-1" ref={tab.li} onclick={e => this._onclick(e, tab)}>
        <a href={href} tabindex="-1" ref={tab.a} target={target}>{label}</a>
      </li>
    );
  }

  removeTab(key) {
    const tab = this._tabsKeyed[key];
    if (!tab) return;

    tab.li.remove();

    delete this._tabsKeyed[key];

    const index = this._tabs.indexOf(tab);
    if (index !== -1)
      this._tabs.splice(index, 1);
  }

  _onclick(e, tab) {
    if (tab.isLink) return;

    e.preventDefault();

    if (tab.onclick) {
      tab.onclick();
      return;
    }
  }
}
