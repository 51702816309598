import { ApiErrorResult } from 'Api/ApiErrors';
import { Cond } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import TbIcons from 'Components/TbIcons';

import Subpage from './Subpage';
import { TableToolbar, SubpageSearchInput } from './LcmComponents';
import { SortableTable, Sorter } from './Tables';
import { PagingToolbar } from './Paging';
import { getRecordingInfo } from './ApiGetters';
import s from  './strings';

import { BroadcastEnqueueModal, BroadcastEnqueueNote } from './Broadcast';
import { RecordingDeleteModal, RecordingOptionsModal } from './RecordingModals';

export default class Recordings extends Subpage {
  constructor() {
    super();

    this._metadata = {
      object: {
        requestGroup: 'CDR',
      },
      params: {
        search: {
          defaultVal:  '',
        },
      },

      sortColumnDefault: 'startedDate',
      sortColumns: [
        'startedDate', 'customID',
      ],
      transformApiParams(params) {
        if (params.sortColumn === 'startedDate')
          params.sortDirection = params.sortDirection === 'asc' ? 'desc' : 'asc';
      },

      options: {
        back: {
          defaultVal: '',
        },
      }
    };
  }

  init(config) {
    const hooks = this.hooks = new Hooks();

    const ctx = {
      hooks,
      ctrl: this,
    };

    const upload = () => {
      this.openSubpage('uploadRecording', {
        back: this.getCanonicalHash(),
      });
    };

    const root = (
      <div class="subpage subpage-recordings">
        <div class="subpage-content">
          <TableToolbar>
            <span class="table-note" use:hook={hooks.show('enableBroadcast')}>
              <BroadcastEnqueueNote />
            </span>

            <SubpageSearchInput ctx={ctx} />

            <button
              type="button"
              class="btn btn-primary"
              onclick={upload}
            >
              {s.Broadcast.upload}
            </button>
          </TableToolbar>

          <RecordingsTable ref={this._table} onSort={e => this._onSort(e)} onCellButtonClick={e => this._onCellButtonClick(e)} />
          <PagingToolbar
            ref={this._pagingToolbar}
            resultCountOpts={this._resultCountOpts}
            onPageChange={page => this.onPageChange(page)}
            onResultCountChange={resultCount => this.onResultCountChange(resultCount)} />
        </div>
      </div>
    );

    super.init(root, {
      enablePaging: true,
      enableSortingParams: true,
    });

    this._broadcastEnqueueModal = new BroadcastEnqueueModal({
      ctrl: this.ctrl.broadcastController,
      onComplete: () => this.openSubpage('lcm'),
    });

    this._optionsModal = new RecordingOptionsModal({
      onSuccess: () => this.redispatch(),
    });

    this._deleteModal = new RecordingDeleteModal({
      onSuccess: () => this.redispatch(),
    });
  }

  activate() {
    return this.ctrl.fetchBridgeData()
      .then(() => getRecordingInfo({
        ...this.getStateValues([
          'startOffset', 'resultCount', 'search', 'sortColumn', 'sortDirection',
        ]),
        timezone: this.ctrl.timezone,
        recordingType: 'upload',
      }))
      .then(result => {
        this._result = result;

        if (!this.updatePaging(result)) {
          return;
        }

        this.hooks.run({
          formData: {
            search: this._params.search,
          },
          enableBroadcast: this.ctrl.features.broadcast,
        });

        this.render();
      })
      .catch(err => {
        if (err instanceof ApiErrorResult) {
          let errorCode = null;
          const { code, parameterName } = err;
          if (code === 'ERR_API_REQUEST_PARAMETER_INVALID') {
            switch (parameterName) {
            case 'startedDate':
              errorCode = 'ERR_INVALID_STARTED_DATE';
              break;

            case 'endedDate':
              errorCode = 'ERR_INVALID_ENDED_DATE';
              break;
            }
          }

          if (errorCode) {
            this.displayError(errorCode);
            return;
          }
        }

        throw err;
      });
  }

  render() {
    this._pagingToolbar.render(this.getPagingState());

    const { items } = this._result;
    let recordingNumberExists = false;
    items.forEach(cur => {
      if (cur.recordingNumber)
        recordingNumberExists = true;
    });

    const extra = {
      recordingNumberExists,
      enableBroadcast: this.ctrl.features.broadcast,
    };

    this._table.clear();
    this._table.render(items, extra, this.sortProps);
  }

  _onSort({ sortColumn, sortDirection }) {
    this.changeSort(sortColumn, sortDirection);
  }

  _onCellButtonClick({ key: cdrID, itemIdx, colId }) {
    const item = this._result.items[itemIdx];
    const { recID } = item;

    switch (colId) {
    case 'customID':
      this.openSubpage('conferenceRecording', {
        id: cdrID,
        recID,
        fromTab: this.moduleName,
        back: this.getCanonicalHash(),
      });
      break;

    case 'enqueueBroadcast':
      this._broadcastEnqueueModal.display({
        cdrID,
        recID,
        timezone: this.ctrl.timezone,
      });
      break;

    case 'edit':
      this._optionsModal.display(item, this.ctrl.timezone);
      break;

    case 'delete':
      this._deleteModal.display(cdrID);
      break;
    }
  }
}

class RecordingsTable extends SortableTable {
  constructor({ ref, onSort, onCellButtonClick }) {
    super({
      ref,
      onCellButtonClick,
      className: 'nowrap striped dataTable subpage-table',
      itemKey: 'cdrID',
      noDataString: s.lblNoData,
      onHeaderClick: e => {
        this._sorter.onHeaderClick(e);

        const [ sortProp ] = this._sorter.getSortProps();

        onSort({
          sortColumn: sortProp.colId,
          sortDirection: sortProp.order,
        });
      },
      columns: [
        {
          id: 'enqueueBroadcast',
          className: 'hover-cell shrink',
          visibility: ({ enableBroadcast }) => enableBroadcast,
          create(cell) {
            return <button type="button" class="btn-table-icon" title={s.Broadcast.enqueue}>{TbIcons.PLUS_CIRCLE}</button>;
          },
        },
        {
          id: 'customID',
          colKey: 'customID',
          className: 'column-customID hover-cell',
          title: s.conferenceRecording.name,
          sorting: true,
          create(cell) {
            return <button type="button">{cell}</button>;
          },
        },
        {
          colKey: 'durationDisplay',
          className: 'text-right shrink',
          title: s.lblDuration,
        },
        {
          colKey: 'sizeDisplay',
          className: 'text-right shrink',
          title: s.conferenceRecording.size,
        },
        {
          id: 'startedDate',
          colKey: 'startedDate',
          className: 'text-right shrink',
          title: s.conferenceRecording.startedDate,
          sorting: true,
        },
        {
          id: 'edit',
          className: 'hover-cell shrink',
          title: s.Broadcast.options,
          create() {
            return <button type="button" class="btn-table-icon" title={s.Broadcast.options}>{TbIcons.SETTINGS}</button>;
          },
        },
        {
          colKey: [ 'playBackSelected', 'playBackDefault', 'passcode' ],
          className: 'shrink',
          title: s.conferenceRecording.playback,
          create({ playBackSelected, playBackDefault, passcode }) {
            let selectionIcon = TbIcons.BLANK;
            let selectionTitle = null;
            if (playBackDefault) {
              selectionIcon = TbIcons.STAR_CHECKED;
              selectionTitle = s.lblRecordingPlaybackOptionsDefault;
            } else if (playBackSelected) {
              selectionIcon = TbIcons.CHECK;
              selectionTitle = s.lblRecordingPlaybackOptionsSelected;
            }

            return (
              <>
                <span class="tbicon-large" title={selectionTitle}>
                  {selectionIcon}
                </span>
                <Cond test={passcode}>
                  <span class="tbicon-large" title={s.lblLocked}>
                    {TbIcons.LOCK}
                  </span>
                </Cond>
              </>
            );
          },
        },
        {
          id: 'delete',
          className: 'hover-cell shrink',
          create() {
            return <button type="button" class="btn-table-icon" title={s.lblDelete}>{TbIcons.TRASH}</button>;
          },
        },
        {
          colKey: 'recordingNumber',
          className: 'text-right shrink',
          title: s.lblRecordingNumberColumn,
          visibility: ({ recordingNumberExists }) => recordingNumberExists,
        },
      ],
    });

    this._sorter = new Sorter({
      table: this,
    });
  }

  render(data, extra, sortProps) {
    this._sorter.setSortProps(sortProps);
    this.renderSort(this._sorter.getSortProps());
    super.render(data, extra);
  }
}
