import JsSIP from 'jssip/JsSIP';
import Transactions from 'jssip/Transactions';
import Transport from 'jssip/Transport';

// Add browser userAgent to JsSIP

JsSIP.C.USER_AGENT += ' Browser - ' + navigator.userAgent;

// Override JsSIP Transport _reconnect to disable auto-reconnect

Transport.prototype._reconnect = () => {};

// Override JsSIP AckClientTransaction so that it fires UA newTransaction event

class AckClientTransaction extends Transactions.AckClientTransaction {
  constructor(ua, transport, request, eventHandlers)
  {
    super(ua, transport, request, eventHandlers);

    ua.emit('newAckClientTransaction', {
      transaction: this
    });
  }
}

Transactions.AckClientTransaction = AckClientTransaction;

// Do Browser feature detection

function isAddTransceiverReal() {
  const tempPc = new RTCPeerConnection();
  let canAddTransceiver = false;
  try {
    tempPc.addTransceiver('audio');
    canAddTransceiver = true;
  } catch (e) {
  }
  tempPc.close();
  return canAddTransceiver;
}

const hasTransceivers =
  window.RTCRtpSender &&
  window.RTCPeerConnection &&
  window.RTCPeerConnection.prototype &&
  window.RTCPeerConnection.prototype.addTransceiver &&
  isAddTransceiverReal();

JsSIP.hasTransceivers = function() {
  return hasTransceivers;
};

export default JsSIP;
