import logger from 'Log/logger';

import strings from './strings';

function getTabKey(subConfID) {
  return `lcm_${subConfID}`;
}

function getTabLabel(subConfID, handRaisedCount) {
  let label = subConfID === null
    ? strings.lblLiveConference
    :`${strings.lblSubConferenceTab} ${subConfID}`;

  if (handRaisedCount) {
    label = <span>{label} <span class="tab-toolbar-notice">&nbsp;{handRaisedCount}&nbsp;</span></span>;
  }

  return label;
}

export default class SubConfManager {
  constructor(subpageDispatcher, tabs, ctrl) {
    this.log = logger('Lcm:SubConfManager');

    this._subpageDispatcher = subpageDispatcher;
    this._tabs = tabs;
    this._ctrl = ctrl;
    this._subConfs = {};
    this._mainConfHandRaisedCount = 0;

    this._ctrl.on('update', () => this._update());
  }

  _addSubConf(subConfID, subConf) {
    this.log(`addSubConf(${subConfID})`);

    const { handRaisedCount = 0 } = subConf;

    this._tabs.addTab({
      key: getTabKey(subConfID),
      label: getTabLabel(subConfID, handRaisedCount),
      onclick: () => this._subpageDispatcher.openSubpage('lcm', { id: subConfID }),
    }, 'lcm');

    this._subConfs[subConfID] = {
      handRaisedCount,
    };
  }

  _updateSubConf(subConfID, subConf) {
    const cached = this._subConfs[subConfID];
    const { handRaisedCount = 0 } = subConf;

    if (handRaisedCount === cached.handRaisedCount) {
      return;
    }

    this._tabs.setLabel(
      getTabKey(subConfID),
      getTabLabel(subConfID, handRaisedCount)
    );
    cached.handRaisedCount = handRaisedCount;
  }

  _removeSubConf(subConfID) {
    this.log(`removeSubConf(${subConfID})`);

    this._tabs.removeTab(getTabKey(subConfID));
    delete this._subConfs[subConfID];
  }

  _updateMainConfCount(handRaisedCount = 0) {
    if (handRaisedCount === this._mainConfHandRaisedCount) {
      return;
    }

    this._tabs.setLabel('lcm', getTabLabel(null, handRaisedCount));
    this._mainConfHandRaisedCount = handRaisedCount;
  }

  _update() {
    const { subConfs, handRaisedCount } = this._ctrl;

    // update labels, remove inactive tabs
    Object.keys(this._subConfs).forEach(subConfID => {
      const subConf = subConfs.find(cur => cur.name === subConfID);
      if (!subConf) {
        this._removeSubConf(subConfID);
      } else {
        this._updateSubConf(subConfID, subConf);
      }
    });

    // add tabs for subconfs that do not exist
    subConfs.forEach(subConf => {
      const subConfID = subConf.name;
      if (!this._subConfs[subConfID])
        this._addSubConf(subConfID, subConf);
    });

    // update label for main conf tab
    this._updateMainConfCount(handRaisedCount);
  }
}
