import Exceptions from './Exceptions';

import Client from './Client';

export default class PlayerClient extends Client {
  constructor(options) {
    super(options);

    this._playing = false;
    this._audioBuffer = null;

    this._startOffset = 0;
    this._startTime   = 0;
  }

  connect(deviceConfig, listenOnly) {
    const context = this.getAudioContext();

    this._audioDestination = context.createMediaStreamDestination();
    this._localMediaStream = this._audioDestination.stream;

    this._attachVolumeMeter(this._localMediaStream);

    this._sendingAudio = true;

    return Promise.resolve()
      .then(() => {
        if (!this._connectParams) {
          throw new Exceptions.ConnectionError('ERR_WEBCALL_CLIENT_UNCONFIGURED');
        }

        return Promise.resolve()
          .then(() => this._connectWS())
          .catch(err => {
            this._disconnected(new Exceptions.ConnectionError('ERR_WEBCALL_WS_CONNECTION', err));
          });
      });
  }

  _disconnected(err) {
    this._startTime = this._startOffset = 0;
    this._stop();

    super._disconnected(err);
  }

  // dont actually do anything with remote audio
  _ontrack(e) {
  }

  setAudioData(data) {
    // create throway context for decodeAudioData
    const context = new this.AudioContext();

    return new Promise((resolve, reject) => {
      const ret = context.decodeAudioData(data, buffer => {
        if (context && context.close) {
          context.close();
        }

        this._audioBuffer = buffer;
        resolve();
      }, err => {
        if (context && context.close) {
          context.close();
        }

        reject(err);
      });

      if (ret instanceof Promise) {
        ret.catch(err => this.log('decodeAudioData promise rejection', err));
      }
    });
  }

  play() {
    this.log('play()');

    const context = this.getAudioContext();

    this._startTime = context.currentTime;

    // Create the sound source
    this._soundSource = context.createBufferSource();
    this._soundSource.buffer = this._audioBuffer;

    this._soundSource.onended = e => {
      this.log('soundSource.onended');

      this._stop();
      this.disconnect();
    };

    this._soundSource.connect(this._audioDestination);
    this._soundSource.start(0, this._startOffset);

    this._playing = true;
  }

  _stop() {
    this.log('_stop()');

    if (!this._playing)
      return;

    this._soundSource.onended = null;

    this._soundSource.stop();
    this._soundSource.disconnect();

    this._soundSource = null;

    this._playing = false;
  }

  togglePause() {
    this.log('togglePause()');

    if (this._playing)
      this._pause();
    else
      this.play();
  }

  _pause() {
    this.log('_pause()');

    const context = this.getAudioContext();

    if (this._playing) {
      this._stop();

      this._startOffset += context.currentTime - this._startTime;
    }
  }

  getCurrentTime() {
    const context = this.getAudioContext();

    return this._playing ?
      Math.floor((context.currentTime - this._startTime) + this._startOffset) :
      Math.floor(this._startOffset);
  }

  isPlaying() {
    return this._playing;
  }
}
