import { Cond } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';

import s from './strings';

export class PagingToolbar {
  static isClassComponent = true;

  constructor({ ref, resultCountOpts = null, onPageChange, onResultCountChange = null }) {
    ref(this);

    const hooks = this.hooks = new Hooks();

    this._onPageChange = onPageChange;
    this._onResultCountChange = onResultCountChange;

    const keyHandler = e => {
      if (e.which === 13)
        e.target.blur();
    };

    this.root =
      <div class="paging-control">
        <div class="paging-toolbar" ref={this._toolbar}>
          <div class="paging-toolbar-nav btn-group" role="group">
            <button type="button" class="btn btn-primary btn-first" title={s.lblFirst} onclick={() => this._emitPageChange(1)} />
            <button type="button" class="btn btn-primary btn-prev" title={s.lblPrevious} onclick={() => this._emitPageChange(this._state.prevPage)} />
            <input
              type="text"
              class="form-control"
              name="page"
              use:hook={hooks.prop('page', 'value')}
              onkeypress={keyHandler}
              onblur={e => this._onPageInputChange(e.target)}
            />
            <div use:hook={hooks.text('totalPages')} />
            <button type="button" class="btn btn-primary btn-next" title={s.lblNext} onclick={() => this._emitPageChange(this._state.nextPage)} />
            <button type="button" class="btn btn-primary btn-last" title={s.lblLast} onclick={() => this._emitPageChange(this._state.totalPages)} />
          </div>

          <Cond test={resultCountOpts}>
            <div class="paging-toolbar-resultCount">
              <span>{s.lblResultCount}</span>

              <div class="btn-group" role="group">
                {resultCountOpts.map(rc => <button type="button" class="btn btn-primary" onclick={() => this._emitResultCountChange(rc)} use:hook={hooks.toggleClass('resultCount', 'active', val => val === rc)}>{rc}</button>)}
              </div>
            </div>
          </Cond>
        </div>

        <div class="print-header-overlay">
          <div class="print-header-overlay-inner">
            <div>
              <button type="button" class="btn btn-primary btn-print-confirm">{s.lblPrint}</button>
              <button type="button" class="btn btn-primary btn-print-cancel">{s.lblCancel}</button>
            </div>

            <div ref={this._printHeader}>
            </div>
          </div>
        </div>

        <div class="paging-control-print-footer">
          {s.lblPageLabelStart}
          {' '}
          <span use:hook={hooks.text('page')} />
          {' '}
          {s.lblPageLabelDelim}
          {' '}
          <span use:hook={hooks.text('totalPages')} />
        </div>
      </div>;
  }

  render(state) {
    this.hooks.run(state);

    this._state = state;

    const { print } = state;
    const toolbar = this._toolbar;
    const newParent = print ? this._printHeader : this.root;
    if (toolbar.parentNode !== newParent) {
      newParent.prepend(toolbar);
    }
  }

  _emitPageChange(newPage) {
    if (newPage !== this._state.page)
      this._onPageChange(newPage);
  }

  _emitResultCountChange(newResultCount) {
    if (newResultCount !== this._state.resultCount)
      this._onResultCountChange(newResultCount);
  }

  _onPageInputChange(input) {
    const revert = () => {
      input.value = this._state.page;
    };

    const newVal = input.value.trim();

    if (newVal.match(/^\d+$/) === null) {
      revert();
      return;
    }

    let val = parseInt(newVal, 10);

    if (val < 1 || val > this._state.totalPages) {
      revert();
      return;
    }

    this._emitPageChange(val);
  }
}
