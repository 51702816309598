import EventEmitter from 'events';

import Loader from 'Loader';

import Api from 'Api/Api';
import { ApiErrorResult } from 'Api/ApiErrors';
import ApiContextAuthNone from 'Api/ApiContextAuthNone';
import Location from 'Browser/Location';
import HookedAlert from 'Components/HookedAlert';
import { RefFormData, FormField, FormText, FormPassword } from 'Components/FormComponents';
import { toggle } from 'Components/domHelpers';
import { Hooks } from 'Components/Hooks';
import LoadingIndicator from 'Components/LoadingIndicator';

import validateNewPassword from './validateNewPassword';

import appErrorHandler from './appErrorHandler';
import s from './strings';
import errors from './errors';

const RESET_PASSWORD_AWAITING_TOKEN = 0;
const RESET_PASSWORD_NEW_PASSWORD = 1;
const RESET_PASSWORD_FINISHED = 2;

class ResetPasswordController extends EventEmitter {
  constructor() {
    super();

    this._state = null;
    this._loading = false;
    this._errorCode = null;

    this._token = null;
  }

  _beginOperation() {
    this._loading = true;
    this._errorCode = null;
    this.emit('update');
  }

  _endOperation() {
    this._loading = false;
    this.emit('update');
  }

  init() {
    this._state = RESET_PASSWORD_AWAITING_TOKEN;
    if (Location.query.token) {
      this.submitToken(Location.query.token);
    } else {
      this.emit('update');
    }
  }

  submitToken(token) {
    if (this._state !== RESET_PASSWORD_AWAITING_TOKEN)
      throw new Error('invalid state');

    this._checkResetToken(token);
  }

  _checkResetToken(token) {
    this._beginOperation();

    const params = {
      token,
    };

    Api.get('Account', 'checkResetToken', params, {}, 'authNone')
      .then(data => {
        this._state = RESET_PASSWORD_NEW_PASSWORD;

        this._token = token;
      })
      .catch(err => {
        if (err instanceof ApiErrorResult) {
          if (err.isInvalidParamError() && err.parameterName === 'token') {
            if (err.code === 'ERR_API_REQUEST_PARAMETER_INVALID_EXPIRED')
              this._errorCode = 'ERR_INVALID_TOKEN_EXPIRED';
            else
              this._errorCode = 'ERR_INVALID_TOKEN';
          }
        }
        if (!this._errorCode)
          this._errorCode = appErrorHandler(err);
      })
      .then(() => {
        this._endOperation();
      });
  }

  submitPassword(password, passwordConfirm) {
    let errorCode = validateNewPassword(password, passwordConfirm);
    if (errorCode) {
      this._errorCode = errorCode;
      this.emit('update');
      return;
    }

    this._beginOperation();

    const params = {
      token: this._token,
      password,
    };

    Api.get('Account', 'resetPassword', params, {}, 'authNone')
      .then(data => {
        this._state = RESET_PASSWORD_FINISHED;
      })
      .catch(err => {
        if (err instanceof ApiErrorResult) {
          if (err.isInvalidParamError() && err.parameterName === 'token') {
            if (err.code === 'ERR_API_REQUEST_PARAMETER_INVALID_EXPIRED')
              this._errorCode = 'ERR_INVALID_TOKEN_EXPIRED';
            else
              this._errorCode = 'ERR_INVALID_TOKEN';
          }
        }
        if (!this._errorCode)
          this._errorCode = appErrorHandler(err);
      })
      .then(() => {
        this._endOperation();
      });
  }

  submitFinished() {
    window.open('/index.html', '_self');
  }

  get state() {
    return this._state;
  }

  get loading() {
    return this._loading;
  }

  get errorCode() {
    return this._errorCode;
  }
}

class ResetPasswordPage {
  static isClassComponent = true;

  constructor({ ctrl }) {
    this.ctrl = ctrl;
    this.ctrl.on('update', () => this.render());

    this.hooks = new Hooks();

    let formContainer;
    this.root =
      <div class="portal-container">
        <div class="portal-container-panel">
          <div class="portal-container-panel-inner">
            <div class="logo-container" />

            <div class="panel panel-primary">
              <div class="panel-heading">
                <h3 class="panel-title">{s.ResetPassword.title}</h3>
              </div>
              <div class="panel-body">
                <div ref={formContainer}>
                  <TokenForm ctx={this} ref={this._tokenForm} />
                  <ResetForm ctx={this} ref={this._resetForm} />
                  <FinishedForm ctx={this} ref={this._finishedForm} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>;

    this._loading = new LoadingIndicator(formContainer);
  }

  render() {
    const { state, loading } = this.ctrl;

    this._loading.toggle(loading);

    toggle(this._tokenForm.root, state === RESET_PASSWORD_AWAITING_TOKEN);
    toggle(this._resetForm.root, state === RESET_PASSWORD_NEW_PASSWORD);
    toggle(this._finishedForm.root, state === RESET_PASSWORD_FINISHED);

    this.hooks.run(this.ctrl);
  }
}

class TokenForm extends RefFormData {
  static isClassComponent = true;

  constructor({ ctx: { ctrl, hooks }, ref }) {
    super();

    ref(this);

    this.ctrl = ctrl;
    this.hooks = hooks;

    this.root =
      <div class="form-horizontal">
        <HookedAlert hooks={hooks} showProp="errorCode" textContentProp="errorCode" textContentTransform={val => errors[val]} />
        <p>{s.ResetPassword.tokenDesc}</p>
        <FormText form={this} name="token" label={s.ResetPassword.tokenLabel} />
        <FormField>
          <button type="button" class="btn btn-primary" onclick={() => this.submit()}>{s.ResetPassword.continue}</button>
        </FormField>
      </div>;
  }

  submit() {
    const token = this.get('token');
    this.ctrl.submitToken(token);
  }
}

class ResetForm extends RefFormData {
  static isClassComponent = true;

  constructor({ ctx: { ctrl, hooks }, ref }) {
    super();

    ref(this);

    this.ctrl = ctrl;
    this.hooks = hooks;

    this.root =
      <div class="form-horizontal">
        <HookedAlert hooks={hooks} showProp="errorCode" textContentProp="errorCode" textContentTransform={val => errors[val]} />
        <FormPassword form={this} name="password" label={s.ResetPassword.password} />
        <FormPassword form={this} name="passwordConfirm" label={s.ResetPassword.passwordConfirm} />
        <FormField>
          <button type="button" class="btn btn-primary" onclick={() => this.submit()}>{s.ResetPassword.continue}</button>
        </FormField>
      </div>;
  }

  submit() {
    this.ctrl.submitPassword(this.get('password'), this.get('passwordConfirm'));
  }
}

class FinishedForm extends RefFormData {
  static isClassComponent = true;

  constructor({ ctx: { ctrl, hooks }, ref }) {
    super();

    ref(this);

    this.ctrl = ctrl;
    this.hooks = hooks;

    this.root =
      <div class="form-horizontal">
        <p>{s.ResetPassword.finished}</p>
        <div class="btn-toolbar-discrete">
          <button type="button" class="btn btn-primary ml-auto" onclick={() => this.ctrl.submitFinished()}>{s.ResetPassword.login}</button>
        </div>
      </div>;
  }
}

export default function main() {
  Loader.load()
    .then(siteConfig => {
      Api.addContext(new ApiContextAuthNone('authNone', {
        ...siteConfig,
      }));

      const ctrl = new ResetPasswordController();

      document.body.appendChild(
        <ResetPasswordPage ctrl={ctrl} />
      );
      ctrl.init();
    })
    .then(() => Loader.loadComplete())
    .then(() => Loader.setLogo())
    .catch(err => Loader.loadError(err));
}
