import Api from 'Api/Api';

import Subpage from './Subpage';
import validateEmail from 'Util/validateEmail';
import { emptyNode } from 'Components/domHelpers';
import { RefFormData, FormField, FormText, FormSelect, FormTextarea, Cond, wrapSubmitHandler } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import LoadingIndicator from 'Components/LoadingIndicator';
import Modal from 'Components/Modal';
import { secondsToHHMMSS, getTimezoneOptions } from 'DateTime';

import appErrorHandler from './appErrorHandler';
import AudioSelect from './AudioSelect';
import AudioUploadControl from './AudioUploadControl';
import { AudioRecorder } from './AudioRecorder';
import { PlayStopButton, PanelDismissButton } from './LcmComponents';

import {
  BSC_DEFAULT,
  BSC_WELCOME_PROMPT_CHOOSE_ACTION,
  BSC_WELCOME_PROMPT_FILE,
  BSC_WELCOME_PROMPT_FILE_UPLOADING,
  BSC_WELCOME_PROMPT_TTS,
  BSC_WELCOME_PROMPT_RECORD,
  BSC_WELCOME_PROMPT_RECORD_UPLOADING,
  BSC_WELCOME_PROMPT_DIAL_IN,
  BSC_WELCOME_PROMPT_TTS_UPLOADING,
  BSC_WELCOME_PROMPT_CONFIRM,
  BSC_MOH_UPLOAD,
  BSC_MOH_UPLOADING,
} from './BridgeSettingsController';

import s from './strings';

const BRIDGE_FIELDS = [
  'pin', 'name', 'confMode', 'confStart', 'confEnd',
  'entryChimes', 'exitChimes', 'recordCalls', 'recordName',
  'announceCount', 'musicOnHold', 'tollNumber', 'tollFreeNumber',
  'blockUnlisted', 'blockAnonymous',
  'validateUserID', 'playBackRecording', 'timezone',
  'enableReports', 'notificationList',
];

export default class Settings extends Subpage {
  constructor() {
    super();

    this._metadata = {
      params: {
      },

      options: {
      }
    };
  }

  init(config) {
    const ctrl = this.ctrl.bridgeSettingsController;
    const hooks = this.hooks = new Hooks();
    ctrl.on('update', () => hooks.run(ctrl));

    this._welcomePromptForm = new RefFormData();

    hooks.add('state', state => {
      switch (state) {
      case BSC_MOH_UPLOAD:
        this._audioUploadControl.render();
        this._audioUploadControl.clear();
        break;

      case BSC_WELCOME_PROMPT_CHOOSE_ACTION:
        this._welcomePromptUploadControl.render();
        this._welcomePromptUploadControl.clear();
        this._ttsUploadControl.clear();
        break;

      case BSC_WELCOME_PROMPT_FILE:
        this._welcomePromptUploadControl.render();
        break;

      case BSC_WELCOME_PROMPT_CONFIRM:
        this._welcomePromptForm.set('description', ctrl.welcomePromptNewDescription);
        break;
      }
    });

    const { bridgePinLength } = config;

    const mohPromptOnSubmit = e => {
      const { file, description } = e;

      ctrl
        .mohPromptUploadFile(file, description, e => this._audioUploadControl.renderProgress(e))
        .catch(err => {
          if (err.cancelled) {
            return;
          }

          this._audioUploadControl.showError('ERR_UNKNOWN');
        });
    };

    const welcomePromptOnSubmit = e => {
      ctrl
        .welcomePromptUploadFile(e.fileInput, e => this._welcomePromptUploadControl.renderProgress(e))
        .catch(welcomePromptOnError);
    };

    const welcomePromptTTSOnSubmit = e => {
      const { ttsVoice, ttsText } = e;

      ctrl
        .welcomePromptUploadTTS(ttsVoice, ttsText, e => this._ttsUploadControl.renderProgress(e))
        .catch(welcomePromptOnError);
    };

    const welcomePromptRecordSubmit = () => {
      this._convertingRecord.hide();

      const renderProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;

        this._progressRecord.value = loaded / total;

        if (loaded === total) {
          this._convertingRecord.show();
        }
      };

      ctrl
        .welcomePromptUploadRecord(ctrl.audioRecorderController.audioFile, e => renderProgress(e))
        .catch(welcomePromptOnError);
    };

    const welcomePromptOnError = err => {
      if (err.cancelled) {
        return;
      }

      this._welcomePromptUploadControl.showError('ERR_UNKNOWN');
    };

    const root = (
      <div class="subpage subpage-settings">
        <div class="subpage-content">
          <form
            class="form-horizontal bridge-settings-form"
            use:hook={hooks.show('state', state => state === BSC_DEFAULT)}
            onsubmit={wrapSubmitHandler(() => this._save())}
          >
            <BridgeSettingsForm
              ctrl={ctrl}
              bridgePinLength={bridgePinLength}
              ref={this._form}
            />

            <FormField commentCol>
              <div class="btn-toolbar-discrete">
                <button type="submit" class="btn btn-primary">{s.lblSave}</button>
                <button type="button" class="btn btn-primary" onclick={() => this.back()}>{s.lblCancel}</button>
              </div>
            </FormField>
          </form>

          <div
            class="panel panel-primary subpage-panel subpage-panel-sm"
            use:hook={hooks.show('isMohPromptVisible')}
            ref={this._uploadPanel}
          >
            <div class="panel-heading">
              <PanelDismissButton onclick={() => ctrl.uploadPromptDismiss()} />
              <h3 class="panel-title">{s.uploadMusicOnHold.title}</h3>
            </div>

            <div class="panel-body">
              <AudioUploadControl ref={this._audioUploadControl} showAbortButton={false} onSubmit={mohPromptOnSubmit} />
            </div>
            <div class="panel-footer">
              <div class="btn-toolbar-discrete">
                <button
                  type="button"
                  class="btn btn-primary"
                  use:hook={hooks.hide('state', state => state !== BSC_MOH_UPLOADING)}
                  onclick={() => ctrl.uploadPromptBack()}
                >
                  {s.Settings.back}
                </button>

                <span class="ml-auto" />

                <button
                  type="button"
                  class="btn btn-primary"
                  use:hook={hooks.hide('state', state => state === BSC_MOH_UPLOADING)}
                  onclick={() => this._audioUploadControl.submit()}
                >
                  {s.lblUpload}
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onclick={() => ctrl.uploadPromptDismiss()}
                >
                  {s.lblCancel}
                </button>
              </div>
            </div>
          </div>

          <div
            class="panel panel-primary subpage-panel subpage-panel-sm"
            use:hook={hooks.show('isWelcomePromptVisible')}
            ref={this._welcomePromptUploadPanel}
          >
            <div class="panel-heading">
              <PanelDismissButton onclick={() => ctrl.uploadPromptDismiss()} />
              <h3 class="panel-title">{s.Settings.welcomePromptTitle}</h3>
            </div>

            <div use:hook={hooks.show('state', state => state === BSC_WELCOME_PROMPT_CHOOSE_ACTION)}>
              <div class="panel-body">
                <Cond test={ctrl.isAudioRecorderSupported}>
                  <p>
                    <button
                      type="button"
                      class="btn btn-link btn-link-large"
                      onclick={() => ctrl.welcomePromptChooseRecord()}
                    >
                      {s.Settings.welcomePromptChooseRecord}
                    </button>
                  </p>
                </Cond>
                <Cond test={ctrl.isTtsEnabled}>
                  <p>
                    <button
                      type="button"
                      class="btn btn-link btn-link-large"
                      onclick={() => ctrl.welcomePromptChooseTTS()}
                    >
                      {s.Settings.welcomePromptChooseTTS}
                    </button>
                  </p>
                </Cond>
                <p>
                  <button
                    type="button"
                    class="btn btn-link btn-link-large"
                    onclick={() => ctrl.welcomePromptChooseUpload()}
                  >
                    {s.Settings.welcomePromptChooseUpload}
                  </button>
                </p>
                <Cond test={ctrl.dialInInstructions}>
                  <p>
                    <button
                      type="button"
                      class="btn btn-link btn-link-large"
                      onclick={() => ctrl.welcomePromptChooseDialIn()}
                    >
                      {s.Settings.welcomePromptChooseDialIn}
                    </button>
                  </p>
                </Cond>
              </div>
              <div class="panel-footer">
                <div class="btn-toolbar-discrete">
                  <span class="ml-auto" />

                  <button
                    type="button"
                    class="btn btn-primary"
                    onclick={() => ctrl.uploadPromptDismiss()}
                  >
                    {s.lblCancel}
                  </button>
                </div>
              </div>
            </div>

            <div use:hook={hooks.show('state', state => state === BSC_WELCOME_PROMPT_FILE || state === BSC_WELCOME_PROMPT_FILE_UPLOADING)}>
              <div class="panel-body">
                <AudioUploadControl
                  ref={this._welcomePromptUploadControl}
                  showAbortButton={false}
                  showDescriptionInput={false}
                  onSubmit={welcomePromptOnSubmit}
                />
              </div>
              <div class="panel-footer">
                <div class="btn-toolbar-discrete">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onclick={() => ctrl.uploadPromptBack()}
                  >
                    {s.Settings.back}
                  </button>

                  <span class="ml-auto" />

                  <button
                    type="button"
                    class="btn btn-primary"
                    use:hook={hooks.hide('state', state => state === BSC_WELCOME_PROMPT_FILE_UPLOADING)}
                    onclick={() => this._welcomePromptUploadControl.submit()}
                  >
                    {s.lblUpload}
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onclick={() => ctrl.uploadPromptDismiss()}
                  >
                    {s.lblCancel}
                  </button>
                </div>
              </div>
            </div>

            <div use:hook={hooks.show('state', state => state === BSC_WELCOME_PROMPT_TTS || state === BSC_WELCOME_PROMPT_TTS_UPLOADING)}>
              <div class="panel-body">
                <TTSUploadControl
                  ctrl={ctrl}
                  options={ctrl.ttsVoiceOptions}
                  onSubmit={welcomePromptTTSOnSubmit}
                  selectLabel={s.Settings.promptTTSVoice}
                  textAreaLabel={s.Settings.promptTTSText}
                  ref={this._ttsUploadControl}
                />
              </div>
              <div class="panel-footer">
                <div class="btn-toolbar-discrete">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onclick={() => ctrl.uploadPromptBack()}
                  >
                    {s.Settings.back}
                  </button>

                  <span class="ml-auto" />

                  <button
                    type="button"
                    class="btn btn-primary"
                    use:hook={hooks.hide('state', state => state === BSC_WELCOME_PROMPT_TTS_UPLOADING)}
                    onclick={() => this._ttsUploadControl.submit()}
                  >
                    {s.Settings.promptTTSubmit}
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onclick={() => ctrl.uploadPromptDismiss()}
                  >
                    {s.lblCancel}
                  </button>
                </div>
              </div>
            </div>

            <div use:hook={hooks.show('state', state => state === BSC_WELCOME_PROMPT_DIAL_IN)}>
              <div class="panel-body">
                <div innerHTML={ctrl.dialInInstructions} />
              </div>
              <div class="panel-footer">
                <div class="btn-toolbar-discrete">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onclick={() => ctrl.uploadPromptBack()}
                  >
                    {s.Settings.back}
                  </button>

                  <span class="ml-auto" />

                  <button
                    type="button"
                    class="btn btn-primary"
                    onclick={() => ctrl.uploadPromptDismiss()}
                  >
                    {s.lblClose}
                  </button>
                </div>
              </div>
            </div>

            <div use:hook={hooks.show('state', state => state === BSC_WELCOME_PROMPT_RECORD || state === BSC_WELCOME_PROMPT_RECORD_UPLOADING)}>
              <div class="panel-body">
                <div class="form-horizontal" use:hook={hooks.show('state', state => state === BSC_WELCOME_PROMPT_RECORD)}>
                  <FormField label={s.lblRecording}>
                    <AudioRecorder ctrl={ctrl.audioRecorderController} />
                  </FormField>
                  <FormField label={s.lblDuration}>
                    <div class="audio-recorder-duration">
                      <span use:hook={hooks.textContent('audioRecorderDuration')} />
                    </div>
                  </FormField>
                </div>

                <div class="d-flex" use:hook={hooks.show('state', state => state === BSC_WELCOME_PROMPT_RECORD_UPLOADING)} ref={this._pending}>
                  <label class="control-label">{s.lblUploading}</label>
                  <div class="flex-grow-1 ml-3">
                    <progress class="progress-primary d-block w-100 mb-3" min="0" max="1" ref={this._progressRecord}></progress>
                  </div>
                </div>
              </div>
              <div class="panel-footer">
                <div class="btn-toolbar-discrete">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onclick={() => ctrl.uploadPromptBack()}
                  >
                    {s.Settings.back}
                  </button>

                  <span class="ml-auto" />

                  <button
                    type="button"
                    class="btn btn-primary"
                    use:hook={hooks.hide('state', state => state === BSC_WELCOME_PROMPT_RECORD_UPLOADING)}
                    use:hook={hooks.prop('audioRecorderHasData', 'disabled', audioRecorderHasData => !audioRecorderHasData)}
                    onclick={() => welcomePromptRecordSubmit()}
                  >
                    {s.lblUpload}
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onclick={() => ctrl.uploadPromptDismiss()}
                  >
                    {s.lblCancel}
                  </button>
                </div>
              </div>
            </div>

            <div use:hook={hooks.show('state', state => state === BSC_WELCOME_PROMPT_CONFIRM)}>
              <div class="panel-body">
                <div class="form-horizontal">
                  <FormField label={s.lblPreview}>
                    <PlayStopButton uri={() => ctrl.welcomePromptNewPreviewUrl} />
                  </FormField>
                  <FormText form={this._welcomePromptForm} name="description" label={s.lblDescription} />
                  <div use:hook={hooks.show('welcomePromptTtsText')}>
                    <FormField label={s.Settings.promptTTSText}>
                      <div class="mt-2" use:hook={hooks.text('welcomePromptTtsText')} />
                    </FormField>
                  </div>
                </div>
              </div>
              <div class="panel-footer">
                <div class="btn-toolbar-discrete">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onclick={() => ctrl.uploadPromptBack()}
                  >
                    {s.Settings.back}
                  </button>

                  <span class="ml-auto" />

                  <button
                    type="button"
                    class="btn btn-primary"
                    onclick={() => ctrl.welcomePromptConfirmFinish(this._welcomePromptForm.get('description'))}
                  >
                    {s.lblSubmit}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    this._convertingRecord = new LoadingIndicator(this._pending, {
      label: s.lblConverting,
    });

    super.init(root);

    this._baseHiddenFields = [];

    if (!config.enableUserIDValidation)
      this._baseHiddenFields.push('validateUserID');

    if (!config.allowPinChangeFlag)
      this._baseHiddenFields.push('pin');

    if (!config.allowBridgeNameChangeFlag)
      this._baseHiddenFields.push('name');

    if (!config.allowEmailListChangeFlag)
      this._baseHiddenFields.push('notificationList');

    if (!config.allowEnableReportsChangeFlag)
      this._baseHiddenFields.push('enableReports');

    this._bridgePinLength = bridgePinLength;

    if (ctrl.isWelcomePromptEnabled) {
      this._welcomePrompt = new AudioSelect({
        select: this._form.getInput('welcomePrompt'),
        showAlways: true,
      });

      this._welcomePrompt.root.append(
        <button type="button" class="btn btn-primary" onclick={() => ctrl.welcomePromptOpen()}>{s.Settings.welcomePromptTitle}</button>
      );

      this._welcomePromptUploadControl.addDropzone(this._welcomePromptUploadPanel);

      hooks.add('welcomePromptItems', items => {
        this._welcomePrompt.setItems(items);

        this._form.set('welcomePrompt', ctrl.welcomePrompt);

        this._welcomePrompt.update();
      });
    }

    this._musicOnHold = new AudioSelect({
      select: this._form.getInput('musicOnHold'),
      showAlways: true,
    });

    this._musicOnHold.root.append(
      <button type="button" class="btn btn-primary" onclick={() => ctrl.mohPromptOpen()}>{s.uploadMusicOnHold.title}</button>
    );

    this._audioUploadControl.addDropzone(this._uploadPanel);

    hooks.add('musicOnHoldItems', items => {
      this._musicOnHold.setItems(items);

      this._form.set('musicOnHold', ctrl.musicOnHold);

      this._musicOnHold.update();
    });

    this._accessNumbers = this._getAccessNumbers(config);
    const { tollNumbers, tollFreeNumbers } = this._accessNumbers;

    if (tollNumbers) {
      this._tollNumber = new AudioSelect({
        select: this._form.getInput('tollNumber'),
      });
    } else {
      this._baseHiddenFields.push('tollNumber');
    }

    if (tollFreeNumbers) {
      this._tollFreeNumber = new AudioSelect({
        select: this._form.getInput('tollFreeNumber'),
      });
    } else {
      this._baseHiddenFields.push('tollFreeNumber');
    }
  }

  _save() {
    const hiddenFields = this._getHiddenFields();
    const params = this._form.getAllValues();

    hiddenFields.forEach(_ => delete params[_]);

    if ('pin' in params) {
      const { pin }  = params;
      if (pin.length !== this._bridgePinLength || !pin.match(/^(\d+)$/)) {
        this.displayError('ERR_INVALID_PIN');
        return;
      }
    }

    if ('notificationList' in params) {
      const list = params.notificationList.trim();

      params.notificationList = [];

      if (list.length > 0) {
        let valid = true;
        list.split(/,/).forEach(_ => {
          const addr = _.trim();
          if (!validateEmail(addr)) {
            valid = false;
          }

          params.notificationList.push(addr);
        });

        if (!valid) {
          this.displayError('ERR_INVALID_NOTIFICATION_LIST');
          return;
        }
      }

      // auto-set enableReports if the partner does not allow directly modifying the allowEnableReportsChangeFlag
      if (!('enableReports' in params) && params.notificationList.length) {
        params.enableReports = 1;
      }
    }

    if (this._isPlayBackRecordingAvailable) {
      const { playBackRecording } = params;

      delete params.playBackRecording;

      if ([ '', 'all', 'selected' ].includes(playBackRecording)) {
        params.playBackMode = playBackRecording;
        params.playBackRecordingID = null;
      } else {
        params.playBackMode = 'selected';
        params.playBackRecordingID = playBackRecording;
      }
    }

    if (this.ctrl.bridgeSettingsController.isWelcomePromptEnabled) {
      params.welcomePromptDescription = this.ctrl.bridgeSettingsController.welcomePromptDescription;
    }
    params.musicOnHoldPromptDescription = this.ctrl.bridgeSettingsController.musicOnHoldPromptDescription;

    Api.get('Bridge', 'setBridge', params)
      .then(() => this.back())
      .catch(err => this.displayError(appErrorHandler(err)));
  }

  _normalizeConfEnd(confEnd) {
    if (confEnd === '' || confEnd === null)
      return '';
    else if (confEnd <= 60000)
      return 60000;
    else if (confEnd <= 300000)
      return 300000;
    else if (confEnd <= 900000)
      return 900000;
    else
      return 1800000;
  }

  update(data) {
    const hiddenFields = this._getHiddenFields();
    BRIDGE_FIELDS.forEach(_ => this._form.setVisibility(_, !hiddenFields.includes(_)));

    if (this._isPlayBackRecordingAvailable) {
      const { playBackMode, playBackRecordingData } = data;

      if (playBackMode === 'selected' && !playBackRecordingData) {
        data.playBackRecording = 'selected';
      }

      this._updatePlayBackRecording(playBackRecordingData);
    }

    if (this._tollNumber) {
      if (!data.tollNumber) data.tollNumber = '';
      this._updateAccessNumberOptions(
        this._accessNumbers.tollNumbers, this._tollNumber, data.tollNumber
      );
    }

    if (this._tollFreeNumber) {
      if (!data.tollFreeNumber) data.tollFreeNumber = '';
      this._updateAccessNumberOptions(
        this._accessNumbers.tollFreeNumbers, this._tollFreeNumber, data.tollFreeNumber
      );
    }

    data.confEnd = this._normalizeConfEnd(data.confEnd);

    this._form.render(data);

    if (this._tollNumber) {
      this._tollNumber.update();
    }
    if (this._tollFreeNumber) {
      this._tollFreeNumber.update();
    }
  }

  activate() {
    return this.ctrl.fetchBridgeData()
      .then(bridgeData => {
        const { recordingAllowedFlag, playBackAccessNumber } = bridgeData;

        this._recordingAllowedFlag = recordingAllowedFlag;
        this._isPlayBackRecordingAvailable = recordingAllowedFlag && playBackAccessNumber;

        if (this._isPlayBackRecordingAvailable) {
          bridgeData.playBackRecording = this._mungePlayBackRecording(bridgeData);

          if (bridgeData.playBackMode === 'selected' && bridgeData.playBackRecordingID) {
            return this._getPlayBackRecording(bridgeData)
              .then(playBackRecordingData => {
                return {
                  ...bridgeData,
                  playBackRecordingData,
                };
              });
          }
        }

        return bridgeData;
      })
      .then(bridgeData => this.ctrl.bridgeSettingsController.update(bridgeData))
      .then(bridgeData => this.update(bridgeData));
  }

  _getAccessNumbers(config) {
    let opts;

    if (config.accessNumberOptions) {
      try {
        opts = JSON.parse(config.accessNumberOptions);
      } catch (err) {
      }
    }

    return opts || {};
  }

  _updateAccessNumberOptions(numbers, audioSelect, curNumber) {
    const numberFound = numbers.find(n => n.value === curNumber);

    audioSelect.setItems([
      {
        value: '',
        label: s.lblNone,
      },
      ...numbers,
      ...((curNumber && !numberFound)
        ? [
          {
            value: curNumber,
            label: curNumber,
          }
        ]
        : []
      ),
    ]);
  }

  _mungePlayBackRecording(bridgeData) {
    const { playBackMode, playBackRecordingID } = bridgeData;

    if (playBackMode === 'all') {
      return 'all';
    } else if (playBackMode === 'selected') {
      if (playBackRecordingID === null) {
        return 'selected';
      } else {
        return playBackRecordingID;
      }
    }

    return '';
  }

  _getPlayBackRecording(bridgeData) {
    const { timezone, playBackRecordingID } = bridgeData;
    const [ cdrID, recID ] = playBackRecordingID.split('-');

    const params = {
      cdrID,
      recID,
      timezone,
      dateFormat: 'shortDateTime12Hr',
    };

    return Api.get('CDR', 'getRecordingInfo', params)
      .then(result => (result.conferenceRecording || [])[0]);
  }

  _updatePlayBackRecording(data) {
    const el = this._form.getInput('playBackRecording');

    emptyNode(el);

    el.appendChild(new Option(s.lblDisabled, ''));
    el.appendChild(new Option(s.lblRecordingPlaybackAll, 'all'));
    el.appendChild(new Option(s.lblRecordingPlaybackSelectedOnly, 'selected'));

    if (data) {
      const duration = secondsToHHMMSS(data.duration);
      const val = `${data.cdrID}-${data.recID}`;
      const label = `${s.lblRecordingPlaybackPrefix}${data.startedDate} - ${s.lblDuration}: ${duration}`;

      el.appendChild(new Option(label, val));
    }
  }

  _getHiddenFields() {
    const ret = [ ...this._baseHiddenFields ];

    if (!this._recordingAllowedFlag) {
      ret.push('recordCalls');
    }
    if (!this._isPlayBackRecordingAvailable) {
      ret.push('playBackRecording');
    }

    return ret;
  }
}

class BridgeSettingsForm extends RefFormData {
  static isClassComponent = true;

  constructor({ ctrl, bridgePinLength, ref }) {
    super();

    if (ref) {
      ref(this);
    }

    const Comment = props => {
      return (
        <span class="note">{props.children}</span>
      );
    };

    const HelpButton = props => {
      return (
        <button type="button" class="btn btn-link btn-help" onclick={() => this._openHelp(props.topic)} />
      );
    };

    const CHIME_OPTS = [
      {
        value: 'chime',
        label: s.lblChime,
      },
      {
        value: 'name',
        label: s.lblName,
      },
      {
        value: 'none',
        label: s.lblNone,
      },
    ];

    const YES_NO_OPTS = [
      {
        value: '1',
        label: s.lblYes,
      },
      {
        value: '0',
        label: s.lblNo,
      },
    ];

    this.root = (
      <>
        <FormText form={this} type="tel" name="pin" label={s.lblHostPIN}
          commentCol
          comment={
            <Comment>{bridgePinLength} {s.BridgeFieldDescriptions.pin}</Comment>
          }
        />

        <FormText form={this} name="name" label={s.lblDescription}
          commentCol
          comment=""
        />

        <FormSelect form={this} name="confMode" label={s.lblConferenceMode}
          options={[
            {
              value: 'qa',
              label: s.CONF_MODE_MAP.qa,
            },
            {
              value: 'conversation',
              label: s.CONF_MODE_MAP.conversation,
            },
            {
              value: 'presentation',
              label: s.CONF_MODE_MAP.presentation,
            },
            {
              value: 'hostsOnly',
              label: s.CONF_MODE_MAP.hostsOnly,
            },
          ]}
          commentCol
          comment={
            <>
              <Comment>{s.BridgeFieldDescriptions.confMode}</Comment>
              <HelpButton topic="confMode" />
            </>
          }
        />

        <FormSelect form={this} name="confStart" label={s.lblConferenceStart}
          options={[
            {
              value: 'instant',
              label: s.lbl2ndCaller,
            },
            {
              value: 'hostJoins',
              label: s.lblHostJoins,
            },
            {
              value: 'hostConfirms',
              label: s.lblHostConfirms,
            },
          ]}
          commentCol
          comment={
            <Comment>{s.BridgeFieldDescriptions.confStart}</Comment>
          }
        />

        <FormSelect form={this} name="confEnd" label={s.lblConferenceEnd}
          options={[
            {
              value:'',
              label: s.lblLastCaller,
            },
            {
              value:'60000',
              label: s.lbl1Min,
            },
            {
              value:'300000',
              label: s.lbl5Min,
            },
            {
              value:'900000',
              label: s.lbl15Min,
            },
            {
              value:'1800000',
              label: s.lbl30Min,
            },
          ]}
          commentCol
          comment={
            <Comment>{s.BridgeFieldDescriptions.confEnd}</Comment>
          }
        />

        <FormSelect form={this} name="entryChimes" label={s.lblEntryNotice}
          options={CHIME_OPTS}
          commentCol
          comment={
            <Comment>{s.BridgeFieldDescriptions.entryChimes}</Comment>
          }
        />

        <FormSelect form={this} name="exitChimes" label={s.lblExitNotice}
          options={CHIME_OPTS}
          commentCol
          comment={
            <Comment>{s.BridgeFieldDescriptions.exitChimes}</Comment>
          }
        />

        <FormSelect form={this} name="recordCalls" label={s.lblRecordCalls}
          options={YES_NO_OPTS}
          commentCol
          comment={
            <Comment>{s.BridgeFieldDescriptions.recordCalls}</Comment>
          }
        />

        <FormSelect form={this} name="recordName" label={s.lblRecordName}
          options={YES_NO_OPTS}
          commentCol
          comment={
            <>
              <Comment>{s.BridgeFieldDescriptions.recordName}</Comment>
              <HelpButton topic="recordName" />
            </>
          }
        />

        <FormSelect form={this} name="announceCount" label={s.lblAnnounceCallerCount}
          options={YES_NO_OPTS}
          commentCol
          comment={
            <Comment>{s.BridgeFieldDescriptions.announceCount}</Comment>
          }
        />

        <Cond test={ctrl.isWelcomePromptEnabled}>
          <FormSelect form={this} name="welcomePrompt" label={s.lblWelcomePrompt}
            commentCol
            comment={
              <Comment>{s.BridgeFieldDescriptions.welcomePrompt}</Comment>
            }
          />
        </Cond>

        <FormSelect form={this} name="musicOnHold" label={s.lblMusicOnHold}
          commentCol
          comment={
            <Comment>{s.BridgeFieldDescriptions.musicOnHold}</Comment>
          }
        />

        <FormSelect form={this} name="tollNumber" label={s.lblTollNumber}
          commentCol
          comment={
            <Comment>{s.BridgeFieldDescriptions.tollNumber}</Comment>
          }
        />

        <FormSelect form={this} name="tollFreeNumber" label={s.lblTollFreeNumber}
          commentCol
          comment={
            <Comment>{s.BridgeFieldDescriptions.tollFreeNumber}</Comment>
          }
        />

        <FormSelect form={this} name="blockUnlisted" label={s.lblParticipantAccess}
          options={[
            {
              value: '0',
              label: s.lblParticipantAccessAllowAll,
            },
            {
              value: '1',
              label: s.lblParticipantAccessWhitelist,
            },
          ]}
          commentCol
          comment={
            <>
              <Comment>{s.BridgeFieldDescriptions.blockUnlisted}</Comment>
              <HelpButton topic="blockUnlisted" />
            </>
          }
        />

        <FormSelect form={this} name="blockAnonymous" label={s.lblBlockAnonymous}
          options={YES_NO_OPTS}
          commentCol
          comment={
            <>
              <Comment>{s.BridgeFieldDescriptions.blockAnonymous}</Comment>
              <HelpButton topic="blockAnonymous" />
            </>
          }
        />

        <FormSelect form={this} name="validateUserID" label={s.lblValidateUserID}
          options={[
            {
              value:'0',
              label: s.lblNo,
            },
            {
              value:'1',
              label: s.lblValidUserIDRequired,
            },
            {
              value:'2',
              label: s.lblValidUserIDOptional,
            },
            {
              value:'3',
              label: s.lblUserIDRequiredNotValidated,
            },
          ]}
          commentCol
          comment={
            <>
              <Comment>{s.BridgeFieldDescriptions.validateUserID}</Comment>
              <HelpButton topic="validateUserID" />
            </>
          }
        />

        <FormSelect form={this} name="playBackRecording" label={s.lblRecordingPlayback}
          commentCol
          comment={
            <>
              <Comment>{s.BridgeFieldDescriptions.playBackRecording}</Comment>
              <HelpButton topic="playBackRecording" />
            </>
          }
        />

        <FormSelect form={this} name="timezone" label={s.lblTimeZone}
          options={getTimezoneOptions()}
          commentCol
          comment=""
        />

        <FormSelect form={this} name="enableReports" label={s.lblEnableReports}
          options={YES_NO_OPTS}
          commentCol
          comment={
            <Comment>{s.BridgeFieldDescriptions.enableReports}</Comment>
          }
        />

        <FormTextarea form={this} name="notificationList" label={s.lblNotificationList}
          inputAttributes={{rows: 3}}
          commentCol
          comment={
            <Comment>{s.BridgeFieldDescriptions.notificationList}</Comment>
          }
        />

        <Modal ref={this._modal}>
          <div class="modal-body" ref={this._modalContent} />
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" onclick={() => this._modal.hide()}>{s.lblOK}</button>
          </div>
        </Modal>
      </>
    );

    this._modalData = {
      confMode: {
        title: s.lblConferenceMode,
        content: (
          <div>
            <p><strong>{s.CONF_MODE_MAP.conversation}:</strong> {s.SettingsHelp.Conversation}</p>
            <p><strong>{s.CONF_MODE_MAP.presentation}:</strong> {s.SettingsHelp.Presentation}</p>
            <p><strong>{s.CONF_MODE_MAP.qa}:</strong> {s.SettingsHelp.QuestionAnswer}</p>
            <p><strong>{s.CONF_MODE_MAP.hostsOnly}:</strong> {s.SettingsHelp.HostsOnly}</p>
          </div>
        ),
      },
      recordName: {
        title: s.lblRecordName,
        content: <p innerHTML={s.SettingsHelp.recordName_html} />
      },
      validateUserID: {
        title: s.lblValidateUserID,
        content: <p>{s.SettingsHelp.validateUserID}</p>
      },
      blockUnlisted: {
        title: s.lblParticipantAccess,
        content: <p>{s.SettingsHelp.blockUnlisted}</p>
      },
      blockAnonymous: {
        title: s.lblBlockAnonymous,
        content: <p>{s.SettingsHelp.blockAnonymous}</p>
      },
      playBackRecording: {
        title: s.lblRecordingPlayback,
        content: <p>{s.SettingsHelp.playBackRecording}</p>
      },
    };
  }

  _openHelp(topic) {
    const { title, content } = this._modalData[topic];

    this._modal.title = title;
    this._modalContent.textContent = '';
    this._modalContent.appendChild(content);
    this._modal.show();
  }

  render(data) {
    this.getAllFields().forEach(field => {
      this.set(field.name, data[field.name]);
    });
  }
}

class TTSUploadControl {
  static isClassComponent = true;

  constructor({ ref, ctrl, options, onSubmit, selectLabel, textAreaLabel }) {
    if (ref) ref(this);

    this._form = new RefFormData();

    const hooks = new Hooks();
    ctrl.on('update', () => hooks.run(ctrl));

    this._onSubmit = onSubmit;

    this._textAreaName = 'ttsText';

    this.root = (
      <div>
        <div class="form-horizontal" use:hook={hooks.show('state', state => state === BSC_WELCOME_PROMPT_TTS)}>
          <FormSelect form={this._form} name="ttsVoice" label={selectLabel} options={options} />
          <FormTextarea form={this._form} name={this._textAreaName} label={textAreaLabel} />
        </div>
        <div class="d-flex" use:hook={hooks.show('state', state => state === BSC_WELCOME_PROMPT_TTS_UPLOADING)} ref={this._pending}>
          <label class="control-label">{s.lblUploading}</label>
          <div class="flex-grow-1 ml-3">
            <progress class="progress-primary d-block w-100 mb-3" min="0" max="1" ref={this._progress}></progress>
          </div>
        </div>
      </div>
    );

    this._converting = new LoadingIndicator(this._pending, {
      label: s.lblConverting,
    });
  }

  clear() {
    this._form.set(this._textAreaName, '');
  }

  submit() {
    const textContent = this._form.get(this._textAreaName);

    if (textContent === '') {
      return;
    }

    this._converting.hide();

    this._onSubmit({...this._form.getAllValues()});
  }

  renderProgress(progressEvent) {
    const { loaded, total } = progressEvent;

    this._progress.value = loaded / total;

    if (loaded === total) {
      this._converting.show();
    }
  }
}
