import $ from 'jquery';

import { ApiErrorResult } from 'Api/ApiErrors';
import Api from 'Api/Api';
import { RefFormData, FormSelect, FormCheckbox, wrapSubmitHandler } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import Modal from 'Components/Modal';
import TbIcons from 'Components/TbIcons';
import { getTimezoneLabel, getTimezoneOptions } from 'DateTime';

import CommentDialog from './CommentDialog';
import Subpage from './Subpage';
import { Table } from './Tables';
import { PagingToolbar } from './Paging';
import { DatePicker, RecordingButton } from './LcmComponents';
import getRedirectErrorURL from './getRedirectErrorURL';
import s from  './strings';

import Columns from './Report/Columns';
import validateTimezone from './Report/validateTimezone';

const TZ_OPTS = getTimezoneOptions();

export default class Report extends Subpage {
  constructor() {
    super();

    this._metadata = {
      object: {
        requestGroup: 'CDR',
      },
      params: {
        startedDate: {
          defaultVal: '',
        },
        endedDate: {
          defaultVal: '',
        },
        timezone: {
          validateFunc: validateTimezone,
        },
        recordedOnlyFlag: {
          defaultVal: 0,
          validateFunc: this.validateBool,
        },
        hideSinglePartyFlag: {
          defaultVal: 0,
          validateFunc: this.validateBool,
        },
        hidePlaybackFlag: {
          defaultVal: 0,
          validateFunc: this.validateBool,
        },
      },

      options: {
        print: {
          defaultVal: 0,
          validateFunc: this.validateBool,
        },
      }
    };
  }

  init(config) {
    const hooks = this.hooks = new Hooks();

    const CSV_TYPES = [
      {
        value: 'details',
        label: s.lblCallerDetails,
      },
      {
        value: 'summary',
        label: s.lblConferenceSummary,
      },
      {
        value: 'playback',
        label: s.lblRecordingPlayback,
      },
    ];

    this._form = new RefFormData();
    this._csvForm = new RefFormData();

    const root = (
      <div class="subpage subpage-report">
        <div class="subpage-content">
          <form
            class="print-hide"
            onsubmit={wrapSubmitHandler(() => this.redispatchReport())}
          >
            <div class="form-inline btn-toolbar-discrete">
              <DatePicker inline form={this._form} rootClass="fg-datepicker" name="startedDate" label={s.lblStartDate} />
              <DatePicker inline form={this._form} rootClass="fg-datepicker" name="endedDate" label={s.lblEndDate} />

              <button type="submit" class="btn btn-primary">{s.lblShow}</button>

              <button type="button" class="btn btn-primary btn-print">{s.lblPrint}</button>
              <button type="button" class="btn btn-primary" onclick={() => this.showCSVModal()}>{s.Report.downloadCSV}</button>
              <FormCheckbox inline form={this._form} name="recordedOnlyFlag" labelRight={s.Report.recordedOnlyFlag} />
            </div>

            <div class="form-inline btn-toolbar-discrete">
              <FormSelect inline form={this._form} name="timezone" label={s.lblTimeZone} options={TZ_OPTS} />

              <FormCheckbox inline form={this._form} name="hideSinglePartyFlag" labelRight={s.Report.hideSinglePartyFlag} />
              <FormCheckbox inline form={this._form} name="hidePlaybackFlag" labelRight={s.Report.hidePlaybackFlag} />
            </div>
          </form>

          <div class="print-header justify-content-between">
            <div class="logo-container"></div>
            <div class="info-table-container">
              <table>
                <tbody>
                  <tr>
                    <td colspan="2" class="cell-title">{s.lblConferenceHistory}</td>
                  </tr>

                  <tr>
                    <td colspan="2" class="cell-data" use:hook={hooks.text('bridgeName')} />
                  </tr>

                  <tr>
                    <td>{s.lblConferenceID}:</td>
                    <td class="cell-data" use:hook={hooks.text('conferenceIDFormatted')} />
                  </tr>

                  <tr>
                    <td>{s.lblDateRange}:</td>
                    <td class="cell-data">
                      <span use:hook={hooks.text('startedDate')} />
                      {s.lblPrintDateRangeSeparator}
                      <span use:hook={hooks.text('endedDate')} />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2" class="cell-data" use:hook={hooks.text('timezoneDisplay')} />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <ReportTable ref={this._table} onCellButtonClick={e => this._onCellButtonClick(e)} />
          <PagingToolbar
            ref={this._pagingToolbar}
            resultCountOpts={this._resultCountOpts}
            onPageChange={page => this.onPageChange(page)}
            onResultCountChange={resultCount => this.onResultCountChange(resultCount)} />
        </div>
      </div>
    );

    super.init(root, {
      enablePaging: true,
    });

    this._commentDialog = new CommentDialog('report', () => this.redispatch());

    this._csvConfDetailsExtraColumns = config.confDetailsCsvExtraColumns;
    this._csvModal = new Modal({
      appendToBody: true,
      title: s.Report.downloadCSV,
      focusFirst: false,
      onClose: () => this.hideCSVModal(),
      children: (
        <form onsubmit={wrapSubmitHandler(() => this._downloadCSV())}>
          <div class="modal-body">
            <div class="form-horizontal">
              <DatePicker form={this._csvForm} name="startedDate" label={s.lblStartDate} />
              <DatePicker form={this._csvForm} name="endedDate" label={s.lblEndDate} />
              <FormSelect form={this._csvForm} name="timezone" label={s.lblTimeZone} options={TZ_OPTS} />
              <FormSelect form={this._csvForm} name="type" label={s.lblReportType} options={CSV_TYPES} />
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">{s.lblDownload}</button>
            <button type="button" class="btn btn-primary" onclick={() => this.hideCSVModal()}>{s.lblCancel}</button>
          </div>
        </form>
      ),
    });

    const flags = [
      'recordedOnlyFlag',
      'hideSinglePartyFlag',
      'hidePlaybackFlag',
    ];
    flags.forEach(flag => {
      this._form.getInput(flag).onchange = e => {
        const { startedDate, endedDate, timezone } = this._form.getAllValues();

        this.redispatch({
          startedDate,
          endedDate,
          timezone,
          page: 1,
          [flag]: e.target.checked ? 1 : 0
        });
      };
    });
  }

  activate() {
    return this.ctrl.fetchBridgeData()
      .then(bridgeData => {
        this.setFieldDefault('timezone', bridgeData.timezone);

        const {
          startedDate,
          endedDate,
          timezone,
          recordedOnlyFlag,
          hideSinglePartyFlag,
          hidePlaybackFlag,
        } = this._params;

        this._form.setValues({
          startedDate,
          endedDate,
          timezone,
          recordedOnlyFlag,
          hideSinglePartyFlag,
          hidePlaybackFlag,
        });

        this.hooks.run({
          bridgeName: bridgeData.name,
          conferenceIDFormatted: bridgeData.conferenceIDFormatted,
          startedDate,
          endedDate,
          timezoneDisplay: getTimezoneLabel(timezone),
        });
      })
      .then(() => Api.get('CDR', 'getConferenceCDR', {
        ...this.getStateValues([
          'startOffset', 'resultCount', 'startedDate', 'endedDate', 'timezone', 'recordedOnlyFlag'
        ]),
        minParticipants: this._params.hideSinglePartyFlag ? 2 : null,
        showPlaybackFlag: !this._params.hidePlaybackFlag,
        dateFormat: 'D, M-d-Y g:i A',
        fields: `${Columns.CONFERENCE_CDR_BASE_FIELDS}, passcode:anyRecordingPasscode, playBackSelected:anyRecordingPlayBackSelected, playBackDefault:anyRecordingPlayBackDefault, recordingNumber:firstRecordingNumber, `,
      }))
      .then(result => {
        this._result = result;
        this._result.items = result.conferenceCDR || [];

        const regex = /(.*) (\d+:\d+ (?:AM|PM))$/;
        this._result.items.forEach(item => {
          const matches = item.startedDate.match(regex);
          const [ , startedDateDate, startedDateTime ] = matches;
          item.startedDateDate = startedDateDate;
          item.startedDateTime = startedDateTime;
        });

        if (!this.updatePaging(result)) {
          return;
        }

        this.render();
      })
      .catch(err => {
        if (err instanceof ApiErrorResult) {
          let errorCode = null;
          const { code, parameterName } = err;
          if (code === 'ERR_API_REQUEST_PARAMETER_INVALID') {
            switch (parameterName) {
            case 'startedDate':
              errorCode = 'ERR_INVALID_STARTED_DATE';
              break;

            case 'endedDate':
              errorCode = 'ERR_INVALID_ENDED_DATE';
              break;
            }
          }

          if (errorCode) {
            this.displayError(errorCode);
            return;
          }
        }

        throw err;
      });
  }

  redispatchReport() {
    const { startedDate, endedDate, timezone } = this._form.getAllValues();

    this.redispatch({
      startedDate,
      endedDate,
      timezone,
      page: 1
    });
  }

  render() {
    const print = !!this._options.print;

    this.setPrintView(print);

    this._pagingToolbar.render(this.getPagingState());

    const { items } = this._result;
    let recordingExists = false;
    let recordingNumberExists = false;
    const comments = [];
    items.forEach((cur, idx) => {
      if (cur.recordingExists)
        recordingExists = true;

      if (cur.recordingNumber)
        recordingNumberExists = true;

      if (cur.comment && cur.confType === 'conference')
        comments.push([idx, cur.comment]);
    });

    const extra = {
      print,
      recordingExists,
      recordingNumberExists,
    };

    this._table.clear();
    this._table.render(items, extra);
    this._table.renderComments(comments);
  }

  _onCellButtonClick({ key: cdrID, colId }) {
    switch (colId) {
    case 'confDetails':
      this.openSubpage('confDetails', {
        id: cdrID,
        timezone: this._params.timezone,
        back: this.getCanonicalHash(),
      });
      break;

    case 'editReference':
      this._commentDialog.fetchByID(cdrID);
      break;

    case 'conferenceRecording':
      this.openSubpage('conferenceRecording', {
        id: cdrID,
        timezone: this._params.timezone,
        back: this.getCanonicalHash(),
      });
      break;
    }
  }

  _downloadCSV() {
    const { timezone, startedDate, endedDate, type } = this._csvForm.getAllValues();
    let requestName;
    let fields;

    switch (type) {
    case 'details':
      requestName = 'getConferenceCallCDR';
      fields = 'Conference ID:conferenceIDFormatted, Bridge Name:bridgeName, Conf #:cdrID, CallID:cdrCallID, ' +
        'Conference Start:confStartedDate, Conference Duration:confDurationMinutes, Conference Minutes Used:confTotalMinutes, ' +
        'From Name:fromName, From Number:fromNumber, Called Number:toNumber, ' +
        'Location:location, Name:name, Access Method:accessMethodDisplay, Host:hostFlagDisplay, ' +
        'Call Start:callStartedDate, Call Duration:callBilledMinutes, SIP Call ID:sipCallID, ' +
        'Jitter Maximum:jitterMax, Jitter Average:jitterAvg, Packets Sent:sentPackets, Packets Received:receivedPackets, Packets Dropped:droppedPackets, From Address:fromAddress, ' +
        'Reference:comment, UserID:userID,';
      if (this._csvConfDetailsExtraColumns) {
        fields += this._csvConfDetailsExtraColumns;
      }
      break;

    case 'summary':
      requestName = 'getConferenceCDR';
      fields = 'Conference ID:conferenceIDFormatted, Bridge Name:bridgeName, Conf #:cdrID, Start:startedDate, ' +
        'Callers:participants, Duration:durationMinutes, Minutes Used:totalMinutes, Reference:comment, ' +
        'Recorded:recordingExists, ';
      break;

    case 'playback':
      requestName = 'getPlaybackCallCDR';
      fields = 'Conference ID:conferenceIDFormatted, Bridge Name:bridgeName, Conf #:cdrID, ' +
        'From Name:fromName, From Number:fromNumber, Called Number:toNumber, ' +
        'Location:location, Name:name, Access Method:accessMethodDisplay, ' +
        'Call Start:callStartedDate, Call Duration:callBilledMinutes, SIP Call ID:sipCallID, ' +
        'Jitter Maximum:jitterMax, Jitter Average:jitterAvg, Packets Sent:sentPackets, Packets Received:receivedPackets, Packets Dropped:droppedPackets, From Address:fromAddress, ' +
        'Reference:comment, UserID:userID,';
      if (this._csvConfDetailsExtraColumns) {
        fields += this._csvConfDetailsExtraColumns;
      }
      break;
    }

    const url = Api.getCsvURL(this.requestGroup, requestName, {
      timezone,
      startedDate,
      endedDate,
      dateFormat: 'longDateTime12Hr',
      fields,
    }, getRedirectErrorURL());

    window.open(url, '_blank');

    this.hideCSVModal();
  }

  showCSVModal() {
    this._csvForm.setValues({
      ...this._form.getValues([ 'startedDate', 'endedDate', 'timezone' ]),
      type: 'details'
    });

    this._csvModal.show();
  }

  hideCSVModal() {
    const hideDatepicker = fieldName => {
      const el = this._csvForm.getInput(fieldName);
      $(el).datepicker('hide');
    };

    this._csvModal.hide();
    hideDatepicker('startedDate');
    hideDatepicker('endedDate');
  }
}

class ReportTable extends Table {
  constructor({ ref, onCellButtonClick }) {
    super({
      ref,
      onCellButtonClick,
      className: 'nowrap striped-old dataTable subpage-table',
      itemKey: 'cdrID',
      noDataString: s.lblNoData,
      noDataRowClass: 'even',
      onTrCreated(tr, idx) {
        tr.classList.add(idx % 2 === 0 ? 'even' : 'odd');
      },
      columns: [
        {
          id: 'confDetails',
          className: 'hover-cell text-center',
          title: s.Report.details,
          visibility: ({ print }) => !print,
          create() {
            return <button type="button" class="btn-table-icon" title={s.lblShowCallDetail}>{TbIcons.LIST}</button>;
          },
        },
        {
          colKey: 'conferenceIDFormatted',
          title: s.lblConfID,
          visibility: ({ print }) => print,
        },
        {
          colKey: 'startedDateDate',
          title: s.Report.startedDateDate,
        },
        {
          colKey: 'startedDateTime',
          className: 'text-right',
          title: s.Report.startedDateTime,
        },
        {
          colKey: 'durationMinutes',
          className: 'text-right',
          title: s.lblDuration,
          create(cell) {
            return `${cell} ${s.lblMin}`;
          },
        },
        {
          colKey: 'totalMinutes',
          className: 'text-right',
          title: s.lblMinutes,
          create(cell) {
            return `${cell} ${s.lblMin}`;
          },
        },
        {
          colKey: [ 'participants', 'confType' ],
          className: 'text-right',
          title: s.lblCallers,
          create({ participants, confType }) {
            if (confType === 'playback')
              return s.Report.playbackLabel;

            return participants;
          },
        },
        {
          id: 'editReference',
          colKey: 'confType',
          className: 'hover-cell text-center',
          title: s.Report.reference,
          visibility: ({ print }) => !print,
          create(confType) {
            if (confType !== 'conference')
              return '';

            return <button type="button" class="btn-table-icon" title={s.lblEditReference}>{TbIcons.COMMENT}</button>;
          },
        },
        {
          id: 'conferenceRecording',
          colKey: [ 'recordingExists', 'passcode', 'playBackSelected', 'playBackDefault' ],
          className: 'hover-cell text-center',
          title: s.lblRecording,
          visibility: ({ print, recordingExists }) => !print && recordingExists,
          create(cell) {
            if (!cell.recordingExists) {
              return '';
            }

            return <RecordingButton {...cell} />;
          },
        },
        {
          colKey: 'recordingExists',
          title: s.lblRecordedColumn,
          visibility: ({ print }) => print,
          create(cell) {
            return cell ? s.lblYes : '';
          },
        },
        {
          colKey: 'recordingNumber',
          className: 'text-right',
          title: s.lblRecordingNumberColumn,
          visibility: ({ recordingNumberExists }) => recordingNumberExists,
        },
      ]
    });
  }

  renderComments(comments) {
    const colspan = this._columns.length;
    let idxOffset = 0;
    comments.forEach(([idx, comment]) => {
      this.parent.children[idx + idxOffset].after(
        <tr class="striped-adjacent">
          <td colspan={colspan}>
            <div class="comment">{comment}</div>
          </td>
        </tr>
      );
      idxOffset++;
    });
  }
}
