import { EmbeddedConfAppController } from 'WebCall/EmbeddedConfAppController';
import { EmbeddedPlayerAppController } from 'WebCall/EmbeddedPlayerAppController';
import EmbeddedConfAppUI from 'WebCall/EmbeddedConfAppUI';
import EmbeddedPlayerAppUI from 'WebCall/EmbeddedPlayerAppUI';

import { isWebRtcSupported, isWebAudioSupported } from 'WebCall/BrowserSupport';

export default class WebCallLoader {
  constructor(options) {
    const { confController, confConfig, playerConfig } = options;

    if (!isWebRtcSupported())
      return;

    const getConferenceID = () => {
      const sendPin = confController.writable;

      const { authInfo } = confController;
      let conferenceID = authInfo.conferenceID;
      if (sendPin)
        conferenceID += '*' + authInfo.pin;

      return conferenceID;
    };

    const isConfigured = conf =>
      conf.wsSipURI &&
      conf.toUriPrefix &&
      conf.toUriSuffix;

    if (isConfigured(confConfig)) {
      this.confAppController = this._initConfApp({
        getConferenceID,
        config: confConfig,
      });

      this.confAppController.callController.on('confUpdate', e => confController.onWebCallConfUpdate(e));

      confController.on('update', () => {
        const {
          userIDEnabled,
          userIDRequired,
        } = confController.features;

        this.confAppController.userIDEnabled = userIDEnabled;
        this.confAppController.userIDRequired = userIDRequired;
      });
    }

    if (!isWebAudioSupported())
      return;

    if (isConfigured(playerConfig)) {
      this.playerAppController = this._initPlayerApp({
        getConferenceID,
        config: playerConfig,
      });
    }
  }

  start() {
    if (this.confAppController)
      this.confAppController.start();
    if (this.playerAppController)
      this.playerAppController.start();
  }

  _initConfApp({ getConferenceID, config }) {
    const {
      wsSipURI,
      toUriPrefix,
      toUriSuffix,

      fetchLocationURL,
      fetchRTCConfigurationURL,
      fetchIceServersURL,
    } = config;

    const ctrl = new EmbeddedConfAppController({
      appConfig: {
        wsSipURI,
        toUriPrefix,
        toUriSuffix,

        fetchLocationURL,
        fetchRTCConfigurationURL,
        fetchIceServersURL,
      },
      getConferenceID,
    });

    this.confAppUI = (
      <EmbeddedConfAppUI ctrl={ctrl} />
    );

    return ctrl;
  }

  _initPlayerApp({ getConferenceID, config }) {
    const {
      wsSipURI,
      toUriPrefix,
      toUriSuffix,

      fetchLocationURL,
      fetchRTCConfigurationURL,
      fetchIceServersURL,

      fromSipURI,
      fromName,
    } = config;

    const ctrl = new EmbeddedPlayerAppController({
      appConfig: {
        wsSipURI,
        toUriPrefix,
        toUriSuffix,

        fetchLocationURL,
        fetchRTCConfigurationURL,
        fetchIceServersURL,

        fromSipURI,
        fromName,
      },
      getConferenceID,
    });

    this.playerAppUI = (
      <EmbeddedPlayerAppUI ctrl={ctrl} />
    );

    WebCallLoader.playerAppUI = this.playerAppUI;
    WebCallLoader.startPlayer = () => {
      ctrl.startConnect();
    };

    return ctrl;
  }

  isConnected() {
    return this._isConfConnected() || this._isPlayerConnected();
  }

  _isConfConnected() {
    return this.confAppController && this.confAppController.isConnected;
  }

  _isPlayerConnected() {
    return this.playerAppController && this.playerAppController.isConnected;
  }

  disconnect() {
    if (this._isConfConnected()) {
      this.confAppController.callController.disconnect();
    }

    if (this._isPlayerConnected()) {
      this.playerAppController.callController.disconnect();
    }
  }
}
