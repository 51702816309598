import EventEmitter from 'events';

import localStorage from 'Browser/localStorage';
import MediaElementController from 'Components/MediaElementController';
import logger from 'Log/logger';
import report from 'Error/report';

import Client from './Client';
import {
  DebugClientStatusController,
  DebugCallStatsController,
  DebugWarningStatusController,
  DebugDevicesController,
} from './DebugController';
import Exceptions from './Exceptions';

import s from './strings';

const CLIENT_STATE = Client.STATE;

const INITIAL_MOS = 5;
const INITIAL_QUALITY_LEVEL = 4;

const BACKDROP_MIN_WAIT = 100;

export const MIC_STATE_NO_MIC = 0;
export const MIC_STATE_HOLD   = 1;
export const MIC_STATE_MUTED  = 2;
export const MIC_STATE_ACTIVE = 3;

export const DEFAULT_AUDIO_OPTIONS = {
  autoGainControl: true,
  noiseSuppression: true,
  echoCancellation: true,
};

export class CallController extends EventEmitter {
  constructor({ instanceName, listenOnlyAllowed = true, alwaysListenOnly = false }) {
    super();

    this._instanceName = instanceName;
    this._listenOnlyAllowed = listenOnlyAllowed;
    this._alwaysListenOnly = alwaysListenOnly;

    this.log = logger(`WebCall:${this._instanceName}:CallController`);

    this._debugMode = false;
    this._view = 'disconnected';

    this._muted = false;
    this._isSettingsOpen = false;
    this._isDialpadOpen = false;
    this._isQualityMeterOpen = false;

    this._getUserMediaAction = null;

    this._showBackdrop = false;
    this._backdropTimer = null;

    this._mediaErrorMessage = null;

    this._timerID  = null;
    this._callTime = 0;

    // create <audio> element for WebRTC audio
    const audioElement = new Audio();
    audioElement.title = s.lblAudioTitleRtc;
    audioElement.autoplay = true;

    this._initClient({
      audioElement,
      instanceName: this._instanceName
    });

    this.client
      .on('stateChange', this._stateChange.bind(this))
      .on('enumerateDevices', this._onEnumerateDevices.bind(this))
      .on('statsSample', this._onStatsSample.bind(this))
      .on('statsWarningUpdate', this._onStatsWarningUpdate.bind(this))
      .on('error', this._onError.bind(this));

    this._deviceConfig = {
      inputDevices: [],
      outputDevices: [],
      inputDeviceId: this.client.getInputDeviceId(),
      outputDeviceId: this.client.getOutputDeviceId(),
      options: this.client.getCurrentAudioOptions(),
    };

    this._mosFixed = INITIAL_MOS.toFixed(2);
    this._qualityLevel = INITIAL_QUALITY_LEVEL;
    this._hasWarnings = false;

    this.debugClientStatusController = new DebugClientStatusController();
    this.on('update', () => this._updateDebugClientStatusController());

    this.debugCallStatsController = new DebugCallStatsController();
    this.debugWarningStatusController = new DebugWarningStatusController();
    this.debugDevicesController = new DebugDevicesController();
  }

  _initClient(clientOpts) {
    this.client = new Client(clientOpts);
  }

  start() {
    this.emit('update');

    this.debugWarningStatusController.clear();
  }

  setConnectParams(connectParams) {
    this.client.setConnectParams(connectParams);
  }

  connect(listenOnly = false) {
    if (this._alwaysListenOnly)
      listenOnly = true;

    this.log(`connect() | listenOnly=${listenOnly}`);

    this._getUserMediaAction = 'connect';

    this.onConnectStart();

    this._mediaErrorMessage = null;

    this._setBackdrop(true);

    this.emit('update');

    this.client.connect(this._storedDeviceConfig, listenOnly)
      .catch(err => {
        this.log('connect() | failure', err);

        if (err instanceof Exceptions.MediaError) {
          this._setMediaError(err);
        } else {
          this.emit('error', err);
        }
      })
      .then(() => {
        this._setBackdrop(false);
      });
  }

  disconnect() {
    if (!this.client.isDisconnected())
      this.client.disconnect();
  }

  onConnectStart() {
    this.debugCallStatsController.clear();
    this.debugWarningStatusController.clear();
  }

  onDisconnected() {
    this.client.setHold(false);
    this._muted = false;

    this._mediaErrorMessage = null;

    this._hasWarnings = false;

    this._isSettingsOpen = false;
    this._isDialpadOpen = false;
    this._isQualityMeterOpen = false;
  }

  toggleMute() {
    this.log('toggleMute()');

    if (this.client.getHold()) {
      this._muted = !this._muted;
    } else {
      const muted = this.client.getMute();

      this._muted = !muted;
      this.client.setMute(this._muted);
    }

    this.emit('update');
  }

  toggleHold() {
    this.log('toggleHold()');

    const hold = this.client.getHold();

    if (!hold) { // not already on hold
      this.client.setMute(true);
    } else {
      this.client.setMute(this._muted);
    }

    this.client.setHold(!hold);

    this.emit('update');
  }

  toggleDialpad() {
    this._isDialpadOpen = !this._isDialpadOpen;

    this.emit('update');
  }

  sendDtmf(digit) {
    this.client.sendDTMF(digit);

    this._playAudio('audio/beep.wav', s.lblAudioTitleDTMF);
  }

  playTestSound() {
    this._playAudio('audio/testSound.wav', s.lblAudioTitleTestSound);
  }

  openQualityMeter() {
    this._isQualityMeterOpen = true;
    this.emit('update');
  }

  closeQualityMeter() {
    this._isQualityMeterOpen = false;
    this.emit('update');
  }

  clearLocalStorage() {
    Object.keys(localStorage).forEach(key => {
      if (key.indexOf('webCall') !== 0) {
        return;
      }

      this.log(`deleting localStorage key ${key}`);

      delete localStorage[key];
    });
  }

  mediaErrorRetry() {
    this._mediaErrorMessage = null;

    switch (this._getUserMediaAction) {
    case 'connect':
      this.connect(); // try to connect again
      break;

    case 'settings':
      this.openSettings();
      break;
    }
  }

  mediaErrorListenOnly() {
    this._mediaErrorMessage = null;

    switch (this._getUserMediaAction) {
    case 'connect':
      this.connect(true); // try to connect again in listen only mode
      break;

    case 'settings':
      this._closeSettings();
      break;
    }
  }

  mediaErrorAbort() {
    this._mediaErrorMessage = null;

    switch (this._getUserMediaAction) {
    case 'connect':
      this.client.disconnect('mediaError');

      break;

    case 'settings':
      if (this.client.isConnected()) {
        this.client.disconnect('mediaError');
      } else {
        this._closeSettings();
      }

      break;
    }
  }

  get view() {
    return this._view;
  }

  get isConnected() {
    return !this.client.isDisconnected();
  }

  get hold() {
    return this.client.getHold();
  }

  get muted() {
    return this._muted;
  }

  get muteLocked() {
    return false;
  }

  get statusMessageText() {
    return '';
  }

  get statusMessageVisible() {
    return true;
  }

  get micState() {
    if (!this.client.isMicConnected())
      return MIC_STATE_NO_MIC;

    const { hold, muted } = this;

    if (hold)
      return MIC_STATE_HOLD;

    if (muted)
      return MIC_STATE_MUTED;

    return MIC_STATE_ACTIVE;
  }

  get inputVolume() {
    return this.client.getInputVolume();
  }

  get deviceConfig() {
    return this._deviceConfig;
  }

  get callTime() {
    return this._callTime;
  }

  get isSettingsOpen() {
    return this._isSettingsOpen;
  }

  get isDialpadOpen() {
    return this._isDialpadOpen;
  }

  get isQualityMeterOpen() {
    return this._isQualityMeterOpen;
  }

  get showBackdrop() {
    return this._showBackdrop;
  }

  get mediaErrorMessage() {
    return this._mediaErrorMessage;
  }

  get hasWarnings() {
    return this._hasWarnings;
  }

  get listenOnlyAllowed() {
    return this._listenOnlyAllowed && (this.client.isConnected() || this._getUserMediaAction === 'connect');
  }

  get mosFixed() {
    return this._mosFixed;
  }

  get qualityLevel() {
    return this._qualityLevel;
  }

  get debugMode() {
    return this._debugMode;
  }

  _stateChange(e) {
    this.log(`_stateChange(${e.stateName})`);

    const state = e.state;

    if (state !== CLIENT_STATE.CONNECTED && state !== CLIENT_STATE.CONNECTING && state !== CLIENT_STATE.DISCONNECTED) {
      return;
    }

    switch (e.state) {
    case CLIENT_STATE.CONNECTED:
      this._startTimer();
      this._view = 'connected';

      break;

    case CLIENT_STATE.CONNECTING:
      this._view = 'connecting';

      break;

    case CLIENT_STATE.DISCONNECTED:
      this._stopAudio();

      this._stopTimer();
      this._setBackdrop(false);

      this._view = 'disconnected';

      this.onDisconnected();

      if (e.error) {
        this.emit('error', e.error);
      }

      break;
    }

    this.emit('update');
  }

  _onEnumerateDevices(devices) {
    this.debugDevicesController.update(devices);
  }

  _onStatsSample(sample) {
    const {
      packetsReceived,
      packetsLost,
      packetsSent,
      totalPpl,
      bytesReceived,
      bytesSent,
      maxJitter,
    } = sample.totals;

    const {
      timestamp,

      qualityLevel,
      mos,
      rFactor,
      ppl,
      rtt,
    } = sample;

    const mosFixed = mos.toFixed(2);
    let changed = false;
    if (this._mosFixed !== mosFixed) {
      this._mosFixed = mosFixed;
      changed = true;
    }
    if (this._qualityLevel !== qualityLevel) {
      this._qualityLevel = qualityLevel;
      changed = true;
    }
    if (changed)
      this.emit('update');

    this.debugCallStatsController.update({
      timestamp,

      packetsReceived,
      packetsLost,
      packetsSent,
      totalPpl,
      bytesReceived,
      bytesSent,
      maxJitter,

      qualityLevel,
      mos,
      rFactor,
      ppl,
      rtt,
    });
  }

  _onStatsWarningUpdate(warnings) {
    const hasWarnings = !!Object.keys(warnings).length;
    if (this._hasWarnings !== hasWarnings) {
      this._hasWarnings = hasWarnings;
      this.emit('update');
    }

    this.debugWarningStatusController.update(
      Object.entries(warnings).map(([ name, props ]) => ({
        name,
        ...props,
      }))
    );
  }

  _onError(error) {
    report.send('Client error', null, error);
  }

  get _storedDeviceConfig() {
    return {
      inputDeviceId: localStorage.webCallInputDevice,
      outputDeviceId: localStorage.webCallOutputDevice,
      audioOptions: {
        ...DEFAULT_AUDIO_OPTIONS,
        ...this._storedAudioOptions
      }
    };
  }

  get _storedAudioOptions() {
    return Object.keys(DEFAULT_AUDIO_OPTIONS).reduce((acc, k) => {
      if (localStorage['webCall_ao_' + k] !== undefined) {
        acc[k] = localStorage['webCall_ao_' + k] === '1';
      }
      return acc;
    }, {});
  }

  _startTimer() {
    this._callTime = 0;

    this._timerID = setInterval(() => {
      this._callTime++;
      this.emit('update');
    }, 1000);
  }

  _stopTimer() {
    if (this._timerID)
      clearInterval(this._timerID);
    this._timerID = null;
  }

  _setBackdrop(show) {
    if (show) {
      this._backdropTimer = setTimeout(() => {
        this._showBackdrop = true;
        this.emit('update');
      }, BACKDROP_MIN_WAIT);
    } else {
      if (this._backdropTimer) {
        clearTimeout(this._backdropTimer);
        this._backdropTimer = null;
      }

      this._showBackdrop = false;
      this.emit('update');
    }
  }

  _setMediaError(err) {
    this.log(`_setMediaError(${err.code})`);
    this._mediaErrorMessage = err.message;
    this._isSettingsOpen = false;
  }

  _playAudio(url, title) {
    this.log(`_playAudio(${url}, ${title})`);

    MediaElementController
      .init()
      .play(url, title, this.client.getOutputDeviceId())
      .catch(() => {
        // swallow errors
      });
  }

  _stopAudio() {
    MediaElementController
      .stop()
      .catch(() => {
        // swallow errors
      });
  }

  openSettings() {
    this._getUserMediaAction = 'settings';

    this._setBackdrop(true);

    this.client.beginSettings(this._storedDeviceConfig)
      .then(({ inputDevices, outputDevices }) => {
        this._deviceConfig = {
          inputDevices,
          outputDevices,
          inputDeviceId: this.client.getInputDeviceId(),
          outputDeviceId: this.client.getOutputDeviceId(),
          options: this.client.getCurrentAudioOptions(),
        };

        // emit update before setting isSettingsOpen so that
        // SettingsForm is populated before modal is displayed
        this.emit('update');

        this._isSettingsOpen = true;
      })
      .catch(err => {
        if (err instanceof Exceptions.MediaError) {
          this._setMediaError(err);
        }
      })
      .then(() => {
        this._setBackdrop(false);
      });
  }

  saveSettings() {
    const options = this.client.getCurrentAudioOptions();
    const inputDeviceId = this.client.getInputDeviceId();
    const outputDeviceId = this.client.getOutputDeviceId();

    localStorage.webCallInputDevice = inputDeviceId;
    localStorage.webCallOutputDevice = outputDeviceId;

    Object.keys(DEFAULT_AUDIO_OPTIONS).forEach(name => {
      localStorage['webCall_ao_' + name] = options[name] ? 1 : 0;
    });

    this.client.endSettings()
      .then(() => this._closeSettings())
      .catch(err => {
        // ignore nonMediaError errors. These are handled by Client
        if (err instanceof Exceptions.MediaError) {
          this._setMediaError(err);
        }
      });
  }

  cancelSettings() {
    this._setBackdrop(true);

    this.client.endSettings(this._storedDeviceConfig)
      .then(() => this._closeSettings())
      .catch(err => {
        // ignore nonMediaError errors. These are handled by Client
        if (err instanceof Exceptions.MediaError) {
          this._setMediaError(err);
        }
      })
      .then(() => {
        this._setBackdrop(false);
      });
  }

  _closeSettings() {
    this._stopAudio();

    this._isSettingsOpen = false;
    this.emit('update');
  }

  inputDeviceChange(deviceId, audioOptions) {
    this.log('_inputDeviceChange()');

    this._setBackdrop(true);

    this.client.getUserMedia(deviceId, audioOptions)
      .then(() => {
        this.log('_inputDeviceChange() | success');
      })
      .catch(err => {
        this._setMediaError(err);
      })
      .then(() => {
        this._setBackdrop(false);
      });
  }

  outputDeviceChange(deviceId) {
    this.log('_outputDeviceChange()');

    this._setBackdrop(true);

    this.client.setOutputDeviceId(deviceId)
      .then(() => {
        this.log('_outputDeviceChange() | success');
      })
      .catch(err => {
        this._setMediaError(err);
      })
      .then(() => {
        this._setBackdrop(false);
      });
  }

  setDebugMode(debugMode) {
    this._debugMode = debugMode;
    this.emit('update');
  }

  _updateDebugClientStatusController() {
    this.debugClientStatusController.update({
      clientMute: this.client.getMute(),
      clientHold: this.client.getHold(),
    });
  }
}
