import Api from 'Api/Api';

import Subpage from './Subpage';
import SubpagePanel from './SubpagePanel';
import AudioUploadControl from './AudioUploadControl';
import { FormField } from 'Components/FormComponents';

import s from './strings';

const CANCEL_ID = 'UploadRecording';

export default class UploadRecording extends Subpage {
  constructor() {
    super();

    this._metadata = {
      params: {
      },

      options: {
        back: {
          defaultVal: '',
        },
      }
    };
  }

  init(config) {
    const onSubmit = e => {
      const { file, description } = e;

      const params = {
        customID: description,
      };
      const opts = {
        cancelID: CANCEL_ID,
        timeout: 0,
        files: {
          recording: file,
        },
        onUploadProgress: e => this._audioUploadControl.renderProgress(e),
      };

      Api.get('CDR', 'uploadRecording', params, opts)
        .then(result => {
          const { cdrID, recID } = result.conferenceRecording;

          this.openSubpage('conferenceRecording', {
            id: cdrID,
            recID,
            fromTab: 'recordings',
            back: this._options.back,
          });
        })
        .catch(err => {
          if (err.cancelled) {
            return;
          }

          this._audioUploadControl.showError('ERR_UNKNOWN');
        });
    };

    const root = (
      <div class="subpage">
        <div class="subpage-content">
          <SubpagePanel small ref={this._uploadPanel} title={s.uploadRecording.title} onDismiss={() => this.back()}>
            <AudioUploadControl ref={this._audioUploadControl} onSubmit={onSubmit} onAbort={() => this._abort()}>
              <FormField>
                <div class="btn-toolbar-discrete">
                  <button type="button" class="btn btn-primary" onclick={() => this._audioUploadControl.submit()}>{s.lblUpload}</button>
                  <button type="button" class="btn btn-primary" onclick={() => this.back()}>{s.lblCancel}</button>
                </div>
              </FormField>
            </AudioUploadControl>
          </SubpagePanel>
        </div>
      </div>
    );

    super.init(root, {
      defaultBackSubpage: 'recordings',
    });

    this._audioUploadControl.addDropzone(this._uploadPanel.root);
  }

  activate() {
    this._audioUploadControl.render();
    this._audioUploadControl.clear();
  }

  detach() {
    this._abort();
  }

  _abort() {
    Api.defaultContext.abort(CANCEL_ID);
  }
}
