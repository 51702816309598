import { ConfCallController } from './ConfCallController';

import s from './strings';

export class TestCallController extends ConfCallController {
  constructor(opts) {
    super({
      ...opts,
      listenOnlyAllowed: false,
    });
  }

  get testState() {
    return this.client.status && this.client.status.state || null;
  }

  get isRecording() {
    return this.testState === 'recordingAudio';
  }

  get statusMessageText() {
    return s.TEST_STATUS_MAP[this.testState] || '';
  }

  get statusMessageVisible() {
    return !this.isRecording;
  }
}
