import localStorage from 'Browser/localStorage';
import validateEmail from 'Util/validateEmail';

import { AppController } from './AppController';
import {
  ConfCallController,
  OUT_GAIN_MIN,
  OUT_GAIN_MAX,
} from './ConfCallController';
import { TestCallController } from './TestCallController';
import WindowEventHandler from './WindowEventHandler';
import Exceptions from './Exceptions';

function getStoredVolume() {
  if (localStorage.webCallConfVolume) {
    const val = parseInt(localStorage.webCallConfVolume, 10);
    if (!isNaN(val) && val >= OUT_GAIN_MIN && val <= OUT_GAIN_MAX)
      return val;
  }

  return 0;
}

export class ConfAppController extends AppController {
  constructor({
    appConfig,
    localStorageKey = null,
    storedFields = null,
    defaultConferenceID = null,
    defaultFromName = null,
    defaultEmail = null,
    userIDEnabled = false,
    userIDRequired = false,
    alwaysListenOnly = false,
    enableTestApp = false,
    enableSettings = true,
    disableCommandButtons = false,
    hideHostControls = false,
    handleHash = false,
  }) {
    super({
      appConfig,
      localStorageKey,
      storedFields: storedFields || [
        'conferenceID',
        'fromName',
        'email',
        'userID',
      ],

      enableSettings,
    });

    this._defaultConferenceID = defaultConferenceID;
    this._defaultFromName = defaultFromName;
    this._defaultEmail = defaultEmail;
    this._userIDEnabled = userIDEnabled;
    this._userIDRequired = userIDRequired;
    this._alwaysListenOnly = alwaysListenOnly;
    this._enableTestApp = enableTestApp;
    this._disableCommandButtons = disableCommandButtons;

    this._currentCall = 'conf';
    this._debugMode = false;

    this.callController = new ConfCallController({
      instanceName: 'ConfApp',
      alwaysListenOnly,
      hideHostControls,
    });
    this.addCallController(this.callController);

    this.testCallController = new TestCallController({
      instanceName: 'TestApp',
    });
    this.addCallController(this.testCallController);

    this.callController.on('update', () => this.emit('update'));
    this.testCallController.on('update', () => this.emit('update'));

    this.windowEventHandler = new WindowEventHandler({ ctrl: this, handleHash });

    this.loadStoredParams();

    if (handleHash) {
      const { hashParams } = this.windowEventHandler;
      if (hashParams.id)
        this._formDefaults.conferenceID = hashParams.id;

      if (hashParams.name)
        this._formDefaults.fromName = hashParams.name;

      if (hashParams.email)
        this._formDefaults.email = hashParams.email;

      if (hashParams.userid)
        this._formDefaults.userID = hashParams.userid;
    }
  }

  start() {
    this.callController.start();
    this.testCallController.start();
    this.emit('update');
  }

  connect(formData) {
    this._currentCall = 'conf';
    this.emit('update');
    this.initiateConnection(this.callController, formData, formData => this._getConnectParams(formData));
  }

  connectTestCall(formData) {
    this._currentCall = 'test';
    this.emit('update');
    this.initiateConnection(this.testCallController, formData, formData => this._getConnectParamsTest(formData));
  }

  openSettings() {
    this._currentCall = 'conf';
    super.openSettings();
  }

  setDebugMode(debugMode) {
    this._debugMode = debugMode;
    this.callController.setDebugMode(debugMode);
    this.testCallController.setDebugMode(debugMode);
  }

  get isConnected() {
    return this.callController.isConnected || this.testCallController.isConnected;
  }

  get debugMode() {
    return this._debugMode;
  }

  get currentCall() {
    return this._currentCall;
  }

  get defaultConferenceID() {
    return this._defaultConferenceID;
  }

  get defaultFromName() {
    return this._defaultFromName;
  }

  get defaultEmail() {
    return this._defaultEmail;
  }

  get userIDEnabled() {
    return this._userIDEnabled;
  }

  set userIDEnabled(val) {
    if (this._userIDEnabled === val)
      return;

    this._userIDEnabled = !!val;
    this.emit('update');
  }

  get userIDRequired() {
    return this._userIDRequired;
  }

  set userIDRequired(val) {
    if (this._userIDRequired === val)
      return;

    this._userIDRequired = !!val;
    // userIDRequired is only used internally, no update required
  }

  get alwaysListenOnly() {
    return this._alwaysListenOnly;
  }

  get enableTestApp() {
    return this._enableTestApp;
  }

  get disableCommandButtons() {
    return this._disableCommandButtons;
  }

  createFormDefaults(appConfig) {
    return {
      conferenceID: '',
      fromName: '',
      email: '',
      userID: '',

      wsSipURI: appConfig.wsSipURI,
      toUriPrefix: appConfig.toUriPrefix,
      toUriSuffix: appConfig.toUriSuffix,
      fetchLocationURL: appConfig.fetchLocationURL,
      fetchRTCConfigurationURL: appConfig.fetchRTCConfigurationURL,
      fetchIceServersURL: appConfig.fetchIceServersURL,
    };
  }

  _getConnectParams(formData) {
    let conferenceID;
    if (this._getConferenceID) {
      // used by embedded
      conferenceID = this._getConferenceID();
    } else {
      // used by standalone
      if (this._defaultConferenceID !== null) {
        conferenceID = this._defaultConferenceID;
      } else {
        conferenceID = formData.conferenceID.replace(/[^\d#*]/g, '');

        if (!conferenceID.length)
          throw new Exceptions.ConnectionError('ERR_INVALID_CONFERENCE_ID');
      }
    }

    let fromName;
    if (this._defaultFromName !== null) {
      fromName = this._defaultFromName;
    } else if (formData.fromName) {
      fromName = formData.fromName;
    }

    let email;
    if (this._defaultEmail !== null) {
      email = this._defaultEmail;
    } else {
      if (formData.email && !validateEmail(formData.email))
        throw new Exceptions.ConnectionError('ERR_INVALID_EMAIL');

      email = formData.email;
    }

    let userID;
    if (this._userIDEnabled) {
      userID = formData.userID;

      if (userID && !userID.match(/^\d+$/))
        throw new Exceptions.ConnectionError('ERR_INVALID_USERID');

      if (!userID && this._userIDRequired)
        throw new Exceptions.ConnectionError('ERR_INVALID_USERID');
    }

    let authString = conferenceID;
    if (userID)
      authString += `#${userID}`;

    const ret = {
      ...this._getConnectParamsCommon(formData, authString),
      fromName,
      fromSipURI: email
        ? `sip:${email}`
        : 'sip:caller@invalid',
    };

    return ret;
  }

  _getConnectParamsTest(formData) {
    const ret = {
      ...this._getConnectParamsCommon(formData, ''),
      fromSipURI: 'sip:caller@invalid',
    };

    ret.toSipURI += ';callTest=1';

    return ret;
  }

  _getConnectParamsCommon(formData, authString) {
    const ret = {
      wsSipURI: this._appConfig.wsSipURI,
      fetchLocationURL: this._appConfig.fetchLocationURL,
      fetchRTCConfigurationURL: this._appConfig.fetchRTCConfigurationURL,
      fetchIceServersURL: this._appConfig.fetchIceServersURL,

      toSipURI: this._appConfig.toUriPrefix + authString + this._appConfig.toUriSuffix,

      outGain: getStoredVolume(),
    };

    if (this._debugMode) {
      ret.wsSipURI = formData.wsSipURI;
      ret.toSipURI = formData.toUriPrefix + authString + formData.toUriSuffix;
    }

    ret.toSipURI += ';outGain=' + ret.outGain;

    return ret;
  }
}
