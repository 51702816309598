import JsSIP from './JsSIPWrapper';
import EventEmitter from 'events';

import Exceptions from './Exceptions';

import createAudioMeter from './createAudioMeter';

import logger from 'Log/logger';

import StatsMonitor from './StatsMonitor';

import sdp_transform from 'sdp-transform';

const STATE = {
  DISCONNECTED: 0,
  GETUSERMEDIA: 1,
  CONNECTING:   2,
  TRYING:       3,
  RINGING:      4,
  CONNECTED:    5
};

const STATE_NAMES = {
  [STATE.DISCONNECTED]: 'DISCONNECTED',
  [STATE.GETUSERMEDIA]: 'GETUSERMEDIA',
  [STATE.CONNECTING]:   'CONNECTING',
  [STATE.TRYING]:       'TRYING',
  [STATE.RINGING]:      'RINGING',
  [STATE.CONNECTED]:    'CONNECTED'
};

const C = {
  RENEGOTIATE_TIMEOUT: 1500,
};

const RTC_SESSION_REMOVE_EVENTS = [
  'failed', 'accepted', 'progress', 'peerconnection', 'rtpTimeout',
  'byeReceived', 'ended', 'sdp'
];

const audioOptionsConstraintMap = {
  autoGainControl: [ 'autoGainControl', 'googAutoGainControl', 'googAutoGainControl2', 'mozAutoGainControl' ],
  noiseSuppression: [ 'noiseSuppression', 'googNoiseSuppression', 'googNoiseSuppression2', 'googHighpassFilter', 'mozNoiseSuppression' ],
  echoCancellation: [ 'echoCancellation', 'googEchoCancellation', 'googEchoCancellation2' ]
};

const audioOptionsExtraConstraints = {
  googAudioMirroring: false
};

const statsHeaderMap = {
  PS: {
    total: true,
    field: 'packetsSent',
  },
  OS: {
    total: true,
    field: 'bytesSent'
  },
  OR: {
    total: true,
    field: 'bytesReceived'
  },
  PL: {
    total: true,
    field: 'packetsLost'
  },
  JI: {
    total: true,
    field: 'maxJitter'
  },
  PR: {
    total: true,
    field: 'packetsReceived'
  },
  LA: {
    total: true,
    field: 'maxLatency'
  },
  EN: {
    field: 'encodeCodec'
  },
  DE: {
    field: 'decodeCodec'
  }
};

const DISCONNECT_REASONS = {
  normal: {
    status_code: 200,
    reason_phrase: 'User Triggered',
  },
  mediaError: {
    status_code: 400,
    reason_phrase: 'Get User Media Failure',
  },
};

export default class Client extends EventEmitter {
  constructor(options) {
    super();

    // init member vars
    this._instanceName         = options.instanceName;

    this.ua                    = null;
    this._session              = null;
    this._peerConnection       = null;
    this._statsMonitor         = null;
    this._lastStatsSample      = null;

    this._state                = null;

    this._rtcAudioElement      = options.audioElement;
    this._actualInputDeviceId  = null;
    this._actualOutputDeviceId = null;
    this._sendingAudio         = false;
    this._localMediaStream     = null;
    this._audioIn              = null;
    this._currentAudioOptions  = null;

    this._renegotiateTimer     = null;

    this._connectParams        = null;

    this.log = logger(`WebCall:${this._instanceName}:Client`);

    this.log('new');

    // browser feature detection

    this.AudioContext = window.AudioContext || window.webkitAudioContext;
    this._canChangeOutputDevice = typeof this._rtcAudioElement.sinkId !== 'undefined';

    this._hasTransceivers = JsSIP.hasTransceivers();

    if (this._hasTransceivers) {
      this.log('_replaceStream - using replaceTrack strategy');
      this._replaceStream = this._replaceStream_replaceTrack;
    } else {
      this.log('_replaceStream - using remove/addTrack strategy');
      this._replaceStream = this._replaceStream_removeAddTrack;
    }
  }

  getState() {
    return this._state;
  }

  isConnected() {
    return this._state === STATE.CONNECTED;
  }

  isDisconnected() {
    return this._state === null || this._state === STATE.DISCONNECTED;
  }

  connect(deviceConfig, listenOnly) {
    if (!this._audioIn) {
      this.getAudioContext();
    }

    return Promise.resolve()
      .then(() => {
        if (!this._connectParams) {
          throw new Exceptions.ConnectionError('ERR_WEBCALL_CLIENT_UNCONFIGURED');
        }

        this._sendingAudio = false;

        this._setState(STATE.GETUSERMEDIA);

        if (!listenOnly) {
          return this._getUserMediaInitial(deviceConfig);
        }
      })
      .then(() => {
        this._sendingAudio = !listenOnly;

        // If getUserMedia is successful, the promise returned by
        // connect() will always be resolved regardless of any
        // _connectWS() errors. The Client object's user is notified
        // of such errors via the DISCONNECTED stateChange event

        return Promise.resolve()
          .then(() => this._connectWS())
          .catch(err => {
            this._disconnected(new Exceptions.ConnectionError('ERR_WEBCALL_WS_CONNECTION', err));
          });
      });
  }

  setConnectParams(connectParams) {
    this._connectParams = connectParams;
  }

  _setState(state, error = null) {
    const emit = state !== this._state,
        stateName = STATE_NAMES[state];

    this.log(`_setState(${stateName})`);

    this._state = state;

    if (emit) {
      this._emitEvent('stateChange', {
        state,
        stateName,
        error
      });
    }
  }

  getHold() {
    return this._rtcAudioElement.muted;
  }

  setHold(flag) {
    this.log(`setHold(${flag})`);

    this._rtcAudioElement.muted = flag;
  }

  getMute() {
    var track = this._getLocalAudioTrack();

    if (track && !track.enabled)
      return true;

    return false;
  }

  setMute(flag) {
    this.log(`setMute(${flag})`);

    var track = this._getLocalAudioTrack();
    if (track !== null) {
      track.enabled = !flag;
    }
  }

  sendDTMF(digit) {
    this.log(`sendDTMF(${digit})`);

    this._session.sendDTMF(digit);
  }

  isMicConnected() {
    return !!(this._localMediaStream);
  }

  _getLocalAudioTrack() {
    if (!this._localMediaStream)
      return null;

    var tracks = this._localMediaStream.getAudioTracks();

    if (tracks.length > 0)
      return tracks[0];

    return null;
  }

  _closeLocalMediaStream() {
    this.log('_closeLocalMediaStream()');

    this._sendingAudio = false;
    this._actualInputDeviceId = null;

    if (this._localMediaStream) {
      this._localMediaStream.getTracks().forEach(track => {
        this.log('_closeLocalMediaStream() | stopping track');
        track.stop();
      });
    }

    this._localMediaStream = null;
  }

  _setSipURIs(toURI, fromURI) {
    this.toURI = toURI;
    this.fromURI = fromURI;
  }

  _connectWS() {
    const connectParams = this._connectParams;

    this.log('_connectWS()', connectParams);

    this._setState(STATE.CONNECTING);
    this._setSipURIs(this._connectParams.toSipURI, this._connectParams.fromSipURI);

    var configuration = {
      uri          : this.fromURI,
      register     : false
    };

    if (connectParams.fromName)
      configuration.display_name = connectParams.fromName;

    configuration.sockets = [ new JsSIP.WebSocketInterface(connectParams.wsSipURI) ];

    this.ua = new JsSIP.UA(configuration);

    this.ua
      .on('connected', e => {
        this.log('ua.connected');

        this._call();
      })
      .on('disconnected', e => {
        this.log('ua.disconnected', e && e.error);

        if (e && e.error) {
          this._disconnected(new Exceptions.ConnectionError('ERR_WEBCALL_WS_CONNECTION'));
          return;
        }

        this.ua.stop();
      })
      .on('newMessage', e => {
        this.log('ua.newMessage', e);

        e.parsedBody = null;

        if (e.originator == 'remote' && e.request && e.request.getHeader('content-type').toLowerCase() == 'application/json') {
          // attempt to parse response
          try {
            e.parsedBody = JSON.parse(e.request.body);
          } catch (error) {
            this.log('_newMessage() | error parsing response JSON', error);
          }
        }

        this._emitEvent('newMessage', e);
      })
      .on('newTransaction', e => {
        const transaction = e.transaction,
            request = transaction.request;

        if (request.method === 'INVITE' || request.method === 'BYE' ||
            (request.method === 'UPDATE' && request.method.body)) {
          this.log(`transaction started:\n\n${request.toString()}\n`);

          if (transaction.eventHandlers && transaction.eventHandlers.onReceiveResponse) {
            const origOnReceiveResponse = transaction.eventHandlers.onReceiveResponse;
            transaction.eventHandlers.onReceiveResponse = response => {
              this.log(`transaction onReceiveResponse:\n\n${response.toString()}\n`);
              origOnReceiveResponse(response);
            };
          }
        }
      })
      .on('newAckClientTransaction', e => {
        const request = e.transaction.request;
        this.log(`transaction AckClientTransaction:\n\n${request.toString()}\n`);
      })
      .start();
  }

  _call() {
    this.log('_call()');

    const peerConnectionErrorHandler = (label, error) => {
      this.log(`${label} | error`, error);
      this._emitEvent('error', error);
    };

    const eventHandlers = {
      failed: e => {
        this.log('session.call.failed', e);

        let canceled = e.cause && e.cause === JsSIP.C.causes.CANCELED,
            error = null;

        if (!canceled) {
          const sipStatusCode = e.message && e.message.status_code || null;

          error = new Exceptions.ConnectionError('ERR_WEBCALL_SIP_CONNECTION', null, sipStatusCode);
        }

        this._disconnected(error);
      },

      accepted: e => {
        if (e.originator == 'remote') {
          this._setupStatsMonitor();
        }
      },

      progress: e => {
        if (e.originator === 'remote') {
          switch (e.response.status_code) {
          case 100:
            this._setState(STATE.TRYING);
            break;

          case 180:
            this._setState(STATE.RINGING);
            break;
          }
        }
      },

      peerconnection: e => {
        this._peerConnection = e.peerconnection;

        this._peerConnection.ontrack = e => {
          this.log('peerconnection.call.ontrack', e);

          this._ontrack(e);

          this._setState(STATE.CONNECTED);
        };

        if (this._hasTransceivers && !this._sendingAudio) {
          this._peerConnection.addTransceiver('audio');
        }
      },

      rtpTimeout: e => {
        this.log('session.call.rtpTimeout', e);

        var statsHeader = this._getStatsHeader();

        if (statsHeader) {
          e.terminateExtraHeaders.push(statsHeader);
        }
      },

      byeReceived: e => {
        this.log('session.call.byeReceived', e);

        var statsHeader = this._getStatsHeader();

        if (statsHeader) {
          e.responseExtraHeaders.push(statsHeader);
        }
      },

      ended: e => {
        this.log('session.call.ended', e);

        let errorCode = null,
            error = null;

        switch (e.cause) {
        case JsSIP.C.causes.RTP_TIMEOUT:
          errorCode = 'ERR_WEBCALL_RTP_CONNECTION';
          break;

        case JsSIP.C.causes.BYE:
        case JsSIP.C.causes.CANCELED:
          // these should not emit an error
          break;

        default:
          errorCode = 'ERR_WEBCALL_SIP_CONNECTION';
          break;
        }

        if (!errorCode && this._terminateWithErrorCode) {
          errorCode = this._terminateWithErrorCode;
        }

        this._terminateWithErrorCode = null;

        if (errorCode) {
          error = new Exceptions.ConnectionError(errorCode);
        }

        this._disconnected(error);
      },

      sdp: e => {
        this.log(`session.call.sdp type = ${e.type}, originator = ${e.originator}`);

        let sdp = sdp_transform.parse(e.sdp);

        if (e.type == 'offer' && e.originator == 'local') {
          this._offerMids = sdp.media.map(mediaSection => {
            return mediaSection.mid;
          });
        } else if (e.type == 'answer' && e.originator == 'remote') {
          this._offerMids.forEach((mid, idx) => {
            if (sdp.media[idx] && sdp.media[idx].mid === undefined) {
              this.log(`explicitly setting mid for media section ${idx}`);
              sdp.media[idx].mid = mid;
            }
          });

          e.sdp = sdp_transform.write(sdp);
        }
      },

      'peerconnection:createofferfailed': error => peerConnectionErrorHandler('createOffer()', error),
      'peerconnection:createanswerfailed': error => peerConnectionErrorHandler('createAnswer()', error),
      'peerconnection:setlocaldescriptionfailed': error => peerConnectionErrorHandler('setLocalDescription()', error),
      'peerconnection:setremotedescriptionfailed': error => peerConnectionErrorHandler('setRemoteDescription()', error)
    };

    const options = {
      eventHandlers,
      mediaStream         : this._localMediaStream,
      mediaConstraints    : { audio : false, video : false },
      rtcConstraints : {
        optional : [ { googIPv6 : false }]
      },
      ...(this._connectParams.rtcConfiguration && {
        pcConfig: this._connectParams.rtcConfiguration,
      }),
      ...(!this._hasTransceivers && !this._sendingAudio && {
        rtcOfferConstraints: {
          offerToReceiveAudio: true
        }
      })
    };

    try {
      this._session = this.ua.call(this.toURI, options);
    } catch (err) {
      this._disconnected(new Exceptions.ConnectionError('ERR_WEBCALL_SIP_CONNECTION', err));
    }
  }

  _getMediaConstraints(inputDeviceId, audioOptions) {
    const audioConstraints = {
      advanced : [],
      ...(inputDeviceId && {
        deviceId : {
          exact : inputDeviceId
        }
      }),
    };

    // construct contraints object using map
    for (let optionName in audioOptions) {
      if (!audioOptionsConstraintMap[optionName])
        continue; // skip option if it is not in the constraint map

      const optionValue = audioOptions[optionName];
      audioOptionsConstraintMap[optionName].forEach(constraintName => {
        audioConstraints.advanced.push({
          [constraintName]: optionValue
        });
      });
    }

    // add additional unmapped constraints
    for (let constraintName in audioOptionsExtraConstraints) {
      audioConstraints.advanced.push({
        [constraintName]: audioOptionsExtraConstraints[constraintName]
      });
    }

    return {
      video : false,
      audio : audioConstraints
    };
  }

  getCurrentAudioOptions() {
    // return copy
    return Object.assign({}, this._currentAudioOptions);
  }

  getInputDeviceId() {
    const track = this._getLocalAudioTrack();
    if (track) {
      const trackSettings = track.getSettings();
      if ('deviceId' in trackSettings) {
        return trackSettings.deviceId;
      }
    }

    return this._actualInputDeviceId;
  }

  getOutputDeviceId() {
    return this._actualOutputDeviceId;
  }

  setOutputDeviceId(deviceId) {
    if (typeof this._rtcAudioElement.sinkId !== 'undefined') {
      return this._rtcAudioElement.setSinkId(deviceId).then(() => {
        this.log('setOutputDeviceId() | success');

        this._actualOutputDeviceId = deviceId;
      }, err => {
        this.log('setOutputDeviceId() | error', err);
        throw new Exceptions.MediaError('ERR_WEBCALL_OUTPUT_DEVICE_ERROR', err);
      });
    } else {
      this.log('setOutputDeviceId() | output device selection is not supported for this browser');

      return Promise.resolve();
    }
  }

  beginSettings(deviceConfig) {
    if (!this._audioIn) {
      this.getAudioContext();
    }

    return Promise.resolve()
      .then(() => {
        if (this._sendingAudio) {
          return this._replaceStream(null)
            .then(() => this._closeLocalMediaStream())
            .then(() => this._renegotiate());
        }
      })
      .then(() => this._getUserMediaInitial(deviceConfig))
      .then(() => this._enumerateDevices())
      .catch(err => {
        this.log('beginSettings() | error', err);

        if (!(err instanceof Exceptions.MediaError) && this.isConnected()) {
          this._disconnectWithError('ERR_WEBCALL_SIP_CONNECTION');
        }

        throw err;
      });
  }

  endSettings(deviceConfig) {
    this.log(`endSettings() | deviceConfig set: ${!!deviceConfig}`);

    if (this.isConnected()) {
      return Promise.resolve()
        .then(() => {
          if (deviceConfig) {
            return this._getUserMediaInitial(deviceConfig);
          }
        })
        .then(() => this._replaceStream(this._localMediaStream))
        .then(() => this._renegotiate())
        .catch(err => {
          this.log('endSettings() | error', err);

          // end call for non MediaError errors
          if (!(err instanceof Exceptions.MediaError)) {
            this._disconnectWithError('ERR_WEBCALL_SIP_CONNECTION');
          }

          throw err;
        });
    } else {
      this._closeLocalMediaStream();
      return Promise.resolve();
    }
  }

  _enumerateDevices() {
    return navigator.mediaDevices.enumerateDevices().then(devices => {
      this.log(`_enumerateDevices() | success ${JSON.stringify(devices)}`);

      this._emitEvent('enumerateDevices', devices, true);

      return this._processDevices(devices);
    }).catch(err => {
      this.log('_enumerateDevices() | error', err);
      throw err;
    });
  }

  _getPreferredDevice(type, devices, storedId) {
    var comm        = null, // communications device
        def         = null, // device with deviceId of "default"
        first       = null, // first device in array
        stored      = null; // found deviceId in deviceList

    devices.forEach(function(device) {
      if (device.deviceId === 'communications' && !comm)
        comm = device;

      if (device.deviceId === 'default' && !def)
        def = device;

      if (!first)
        first = device;

      if (storedId && device.deviceId === storedId) {
        stored = device;
      }
    });

    if (stored) {
      this.log(type + ': using stored device', stored);
      return stored.deviceId;
    } else if (comm) {
      this.log(type + ': using communications device', comm);
      return comm.deviceId;
    } else if (def) {
      this.log(type + ': using default device', def);
      return def.deviceId;
    } else if (first) {
      this.log(type + ': using first device', first);
      return first.deviceId;
    } else {
      this.log(type + ': not setting device');
    }

    return null;
  }

  _processDevices(devices) {
    const groupLabels = {};
    const inputDevices = [];
    const outputDevices = [];

    function setDeviceLabel(device) {
      // if there is no label, see if another device
      // with that groupId has a label
      if (!device.label && groupLabels[device.groupId]) {
        device.label = groupLabels[device.groupId];
      }
    }

    // add devices
    devices.forEach(cur => {
      const newDev = {
        deviceId: cur.deviceId,
        groupId: cur.groupId,
        kind: cur.kind,
        label: cur.label
      };

      if (cur.groupId && cur.label) {
        groupLabels[cur.groupId] = cur.label;
      }

      if (cur.kind === 'audioinput') {
        inputDevices.push(newDev);
      }

      if (cur.kind === 'audiooutput' && this._canChangeOutputDevice) {
        outputDevices.push(newDev);
      }
    });

    inputDevices.forEach(setDeviceLabel);
    outputDevices.forEach(setDeviceLabel);

    return {
      inputDevices,
      outputDevices
    };
  }

  getUserMedia(inputDeviceId, audioOptions) {
    var constraints = this._getMediaConstraints(inputDeviceId, audioOptions);

    this.log('getUserMedia()', constraints);

    if (this._localMediaStream) {
      // don't call getUserMedia again if we already have a stream
      // with the requested deviceId and audioOptions

      let deviceMatch = this.getInputDeviceId() === inputDeviceId;
      if (deviceMatch) {
        for (let curSetting in this._currentAudioOptions) {
          if (this._currentAudioOptions[curSetting] !== audioOptions[curSetting]) {
            deviceMatch = false;
            break;
          }
        }
      }

      if (deviceMatch) {
        this.log('getUserMedia() | returning early, device already open');
        return Promise.resolve(this._localMediaStream);
      }
    }

    this._closeLocalMediaStream();

    return navigator.mediaDevices.getUserMedia(constraints).then(stream => {
      this.log('getUserMedia() | success');

      this._localMediaStream = stream;
      if (inputDeviceId) {
        this._actualInputDeviceId = inputDeviceId;
      }
      this._currentAudioOptions = audioOptions;

      this._attachVolumeMeter(stream);

      return stream;
    }, err => {
      this.log('getUserMedia() | error', err);

      throw new Exceptions.MediaError('ERR_WEBCALL_INPUT_DEVICE_ERROR', err);
    });
  }

  _getUserMediaInitial(deviceConfig) {
    this.log('_getUserMediaInitial() | deviceConfig', this._getDeviceConfigLogObject(deviceConfig));

    return Promise.resolve()
      .then(() => {
        if (deviceConfig.inputDeviceId) {
          return this.getUserMedia(deviceConfig.inputDeviceId, deviceConfig.audioOptions)
            .catch(err => {
              if (err.code !== 'ERR_WEBCALL_DEVICE_OVERCONSTRAINED') {
                throw err;
              }
            });
        }
      })
      .then(() => this._enumerateDevices())
      .then(devices => {
        if (this._localMediaStream) {
          return devices;
        }

        if (!devices.inputDevices.length) {
          throw new Exceptions.MediaError('ERR_WEBCALL_NO_INPUT_DEVICES');
        }

        const inputDeviceId = this._getPreferredDevice('input', devices.inputDevices, deviceConfig.inputDeviceId);
        return this.getUserMedia(inputDeviceId, deviceConfig.audioOptions);
      })
      .then(() => this._enumerateDevices())
      .then(devices => {
        const outputDeviceId = this._getPreferredDevice('output', devices.outputDevices, deviceConfig.outputDeviceId);
        return this.setOutputDeviceId(outputDeviceId)
          .catch(err => {
            // ignore setOutputDeviceId for getUserMediaInitial
          });
      });
  }

  _getDeviceConfigLogObject(deviceConfig) {
    const { inputDeviceId, outputDeviceId, audioOptions } = deviceConfig;

    return {
      inputDeviceId,
      outputDeviceId,
      ...audioOptions
    };
  }

  _replaceStream_replaceTrack(stream) {
    this.log(`_replaceStream(${stream})`);

    this._sendingAudio = false;

    const track = stream ? stream.getAudioTracks()[0] : null;

    return Promise.all(
      this._peerConnection.getSenders().map(sender => sender.replaceTrack(track))
    ).then(() => {
      this._sendingAudio = !!track;
      this.log(`_replaceStream() | complete. sendingAudio = ${this._sendingAudio} senderTrackCount = ${this._peerConnection.getSenders().length}`);
    });
  }

  _replaceStream_removeAddTrack(stream) {
    this.log(`_replaceStream(${stream})`);

    this._sendingAudio = false;

    return Promise.all(
      // remove existing streams
      this._peerConnection.getSenders().map(sender => new Promise((resolve, reject) => {
        this.log('_replaceStream() | removing track from sender');

        this._peerConnection.removeTrack(sender);

        resolve();
      }))
    ).then(() => {
      if (stream) {
        this.log('_replaceStream() | adding track to RTCPeerConnection');
        this._sendingAudio = true;
        this._peerConnection.addTrack(stream.getAudioTracks()[0], stream);
      }
      this.log(`_replaceStream() | complete. sendingAudio = ${this._sendingAudio} senderTrackCount = ${this._peerConnection.getSenders().length}`);
    });
  }

  _renegotiate() {
    this.log('_renegotiate()');

    return new Promise((resolve, reject) => {
      this._renegotiateTimer = setTimeout(() => {
        this.log('_renegotiate() | renegotiation timed out');

        reject(new Exceptions.ConnectionError('ERR_WEBCALL_SIP_CONNECTION'));
      }, C.RENEGOTIATE_TIMEOUT);

      this._session.renegotiate({}, () => {
        this.log('_renegotiate() | success');

        this._clearRenegotiateTimer();

        resolve();
      });
    });
  }

  _clearRenegotiateTimer() {
    if (this._renegotiateTimer) {
      clearTimeout(this._renegotiateTimer);
    }

    this._renegotiateTimer = null;
  }

  getAudioContext() {
    if (!this._audioIn) {
      this.log('getAudioContext()');

      this._audioIn = {};

      this._audioIn.context = new this.AudioContext();
      this._audioIn.meter   = createAudioMeter(this._audioIn.context);
    }

    return this._audioIn.context;
  }

  _attachVolumeMeter(stream) {
    // remove previous source from AudioContext graph
    if (this._audioIn.source)
      this._audioIn.source.disconnect();

    this._audioIn.source = this._audioIn.context.createMediaStreamSource(stream);
    this._audioIn.source.connect(this._audioIn.meter);
  }

  getInputVolume() {
    if (this._audioIn && this._audioIn.meter)
      return this._audioIn.meter.volume;

    return 0;
  }

  _ontrack(e) {
    const stream = e.streams[0];

    this._remoteMediaStream = stream;

    if (this._rtcAudioElement.srcObject !== undefined) {
      this._rtcAudioElement.srcObject = stream;
    } else {
      this._rtcAudioElement.src = window.URL.createObjectURL(stream);
    }
  }

  get localMediaStream() {
    return this._localMediaStream || null;
  }

  get remoteMediaStream() {
    return this._remoteMediaStream || null;
  }

  _getStatsHeader() {
    if (!this._lastStatsSample) {
      return;
    }

    const sample = this._lastStatsSample;
    let ret = '';

    for (let key in statsHeaderMap) {
      const cur = statsHeaderMap[key];
      const val = cur.total ? sample.totals[cur.field] : sample[cur.field];

      if (val !== undefined) {
        if (ret) {
          ret += ', ';
        }

        ret += key + '=' + val;
      }
    }

    return 'P-RTP-Stat: ' + ret;
  }

  disconnect(reasonCode = 'normal') {
    this.log('disconnect()');

    if (this._session) {
      const terminateOptions = {};

      const statsHeader = this._getStatsHeader();
      if (statsHeader) {
        terminateOptions.extraHeaders = [ statsHeader ];
      }

      if (reasonCode in DISCONNECT_REASONS) {
        const reason = DISCONNECT_REASONS[reasonCode];
        terminateOptions.status_code = reason.status_code;
        terminateOptions.reason_phrase = reason.reason_phrase;
      }

      this._session.terminate(terminateOptions);
    } else { // no RTCSession has been created yet. cancel call
      this._disconnected();
    }
  }

  _disconnectWithError(errorCode) {
    this.log(`_disconnectWithError(${errorCode})`);

    this._terminateWithErrorCode = errorCode;
    this.disconnect();
  }

  _disconnected(err) {
    this.log('_disconnected()');
    if (err) {
      this.log('_disconnected() | error', err);
      if (err.cause)
        this.log('_disconnected() | error cause', err.cause);
    }

    // Cleanup WebRTC objects
    this._peerConnection = null;
    this._destroyStatsMonitor();
    this._clearRenegotiateTimer();

    if (this._session) {
      RTC_SESSION_REMOVE_EVENTS.forEach(type => {
        this._session.removeAllListeners(type);
      });
    }
    this._session = null;

    if (this.ua) {
      this.ua.stop();
      this.ua.removeAllListeners();
    }
    this.ua = null;

    // Cleanup Audio objects
    this._closeLocalMediaStream();

    this._remoteMediaStream = null;
    if (this._rtcAudioElement.srcObject !== undefined) {
      this._rtcAudioElement.srcObject = null;
    } else {
      this._rtcAudioElement.src = '';
    }

    // Emit state event
    this._setState(STATE.DISCONNECTED, err);
  }

  sendJsonINFO(jsonObject) {
    if (!this._session)
      return;

    this._session.sendRequest(JsSIP.C.INFO, {
      extraHeaders: [
        'Content-Type: application/json'
      ],
      body: JSON.stringify(jsonObject)
    });
  }

  _setupStatsMonitor() {
    this.log('_setupStatsMonitor()');

    this._statsMonitor = new StatsMonitor();
    this._statsMonitor.start(this._peerConnection);

    this._statsMonitor.on('sample', sample => {
      this._lastStatsSample = sample;

      this._emitEvent('statsSample', sample, true);
    });

    this._statsMonitor.on('warningUpdate', warnings => {
      this._emitEvent('statsWarningUpdate', warnings);
    });

    this._statsMonitor.on('error', error => {
      this.log('StatsMonitor Error', error);
      this._emitEvent('error', error);
    });
  }

  _destroyStatsMonitor() {
    this.log('_destroyStatsMonitor()');

    if (this._statsMonitor) {
      this._statsMonitor.removeAllListeners();
      this._statsMonitor.stop();
    }

    this._statsMonitor = null;
    this._lastStatsSample = null;
  }

  _emitEvent(eventName, event, noLog) {
    if (!noLog)
      this.log(`_emitEvent(${eventName})`);

    this.emit(eventName, event);
  }

  static get STATE() {
    return STATE;
  }
}
