import { cachedUpdate, toggleClass } from 'Components/domHelpers';
import { Cond } from 'Components/FormComponents';

import Subpage from './Subpage';
import CallList from './CallList';
import { BroadcastPanel } from './Broadcast';
import ConfPendingPanel from './ConfPendingPanel';
import ConfSettings from './ConfSettings';
import HandRaising from './HandRaising';
import NameTranscribe from './NameTranscribe';
import WebCallLoader from './WebCallLoader';

export default class Lcm extends Subpage {
  constructor() {
    super();

    this._metadata = {
      params: {
        subConfID: {
          key: 'id',
        },
      },

      options: {
        back: {
          defaultVal: '',
        },
      },
    };
  }

  init(config) {
    const root = (
      <div class="subpage subpage-lcm">
        <div class="subpage-content">
          <ConfSettings ref={this._confSettings} ctrl={this.ctrl} />
          <Cond test={WebCallLoader.playerAppUI}>
            {WebCallLoader.playerAppUI}
          </Cond>
          <BroadcastPanel ctrl={this.ctrl.broadcastController} />
          <ConfPendingPanel ctrl={this.ctrl} ref={this._confPendingPanel} />
          <NameTranscribe ctrl={this.ctrl} ref={this._nameTranscribe} />
          <HandRaising
            ctrl={this.ctrl}
            ref={this._handRaising} />
          <CallList
            config={config}
            onRetry={() => this.ctrl.retry()}
            ctrl={this.ctrl}
            ref={this._callList} />
        </div>
      </div>
    );

    super.init(root);

    this.ctrl.on('update', () => this.render());

    this._updaters = {
      isConfActive: cachedUpdate(val => {
        toggleClass(this.root, 'state-conf-active', val);
      }),
      isSubConfActive: cachedUpdate(val => {
        toggleClass(this.root, 'state-in-sub-conf', val);
      }),
      makeCallAllowed: cachedUpdate(val => {
        toggleClass(this.root, 'forbid-make-call', !val);
      }),
    };
  }

  processParams(rawParams, fromHash) {
    super.processParams(rawParams, fromHash);
    this.ctrl.setSubConfID(this._params.subConfID);
  }

  getActiveTabKey() {
    let key = 'lcm';
    if (this._params.subConfID)
      key += `_${this._params.subConfID}`;

    return key;
  }

  activate() {
    return this.ctrl.fetchBridgeData();
  }

  detach() {
    this.ctrl.setSubConfID(null);
  }

  render() {
    if (!this.isActive) return;

    this._updaters.isConfActive(this.ctrl.isConfActive);
    this._updaters.isSubConfActive(this.ctrl.isSubConfActive);
    this._updaters.makeCallAllowed(this.ctrl.features.makeCall);

    this._confSettings.render();
    this._confPendingPanel.render();
    this._nameTranscribe.render();
    this._handRaising.render();
    this._callList.render();
  }
}
