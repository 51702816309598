import EventEmitter from 'events';
import { hook, Hooks } from 'Components/Hooks';
import UserMediaManager from './UserMediaManager';
import Alert from 'Components/Alert';
import TbIcons from 'Components/TbIcons';

import s from './strings';

export const ARC_READY = 0;
export const ARC_RECORDING = 1;
export const ARC_PLAYING = 2;
export const ARC_DATA_AVAILABLE = 3;

const AUDIO_FILE_NAME = 'audio.ogg';
const MIME_TYPE = 'audio/ogg; codecs=opus';

const DURATION_DELAY = 1000;

export function AudioRecorder({ ctrl }) {
  const hooks = new Hooks();

  ctrl.on('update', () => {
    hooks.run(ctrl);
    alertError.textContent = ctrl.errorMessage;
  });

  let volumeSlider;
  let alertError;

  setInterval(() => {
    volumeSlider.style.width = `${ctrl.inputVolume * 100}%`;
  }, 10);

  return (
    <>
      <div use:hook={hooks.show('isError')}>
        <Alert ref={alertError} />
      </div>
      <div class="audio-recorder" use:hook={hooks.hide('isError')}>
        <button
          type="button"
          class="btn btn-primary tbicon btn-record"
          use:hook={hooks.hide('state', state => state === ARC_RECORDING)}
          use:hook={hooks.prop('state', 'disabled', state => state === ARC_PLAYING)}
          onclick={() => ctrl.record()}
          title={s.AudioRecorder.record}
        >
          {TbIcons.RECORDING}
        </button>
        <button
          type="button"
          class="btn btn-primary tbicon"
          use:hook={hooks.show('state', state => state === ARC_RECORDING)}
          onclick={() => ctrl.stop()}
          title={s.AudioRecorder.stop}
        >
          {TbIcons.STOP}
        </button>
        <button
          type="button"
          class="btn btn-primary tbicon"
          use:hook={hooks.prop('canPlay', 'disabled')}
          use:hook={hooks.hide('state', state => state === ARC_PLAYING)}
          onclick={() => ctrl.play()}
          title={s.AudioRecorder.play}
        >
          {TbIcons.PLAY}
        </button>
        <button
          type="button"
          class="btn btn-primary tbicon"
          use:hook={hooks.show('state', state => state === ARC_PLAYING)}
          onclick={() => ctrl.stop()}
          title={s.AudioRecorder.stop}
        >
          {TbIcons.STOP}
        </button>
        <div class="volume-visualizer-outer">
          <div class="volume-visualizer-inner" ref={volumeSlider}></div>
        </div>
      </div>
    </>
  );
}

export class AudioRecorderController extends EventEmitter {
  constructor({ maxRecordingDurationMS }) {
    super();

    this._audioElement = new Audio();
    this._userMediaManager = new UserMediaManager(this._audioElement);

    this._duration = 0;
    this._durationInterval = null;
    this._maxRecordingDurationMS = maxRecordingDurationMS;

    this._errorMessage = null;

    this._audioElement.onended = () => {
      this._state = ARC_DATA_AVAILABLE;
      this.emit('update');
    };

    this.clear();
  }

  _startDurationInterval() {
    this._durationInterval = setInterval(() => {
      this._duration++;
      this.emit('update');

      if (this._duration * 1000 > this._maxRecordingDurationMS) {
        this._stopRecording()
          .then(audioBlob => this._finalizeRecording(audioBlob));
      }
    }, DURATION_DELAY);
  }

  _stopDurationInterval() {
    clearInterval(this._durationInterval);
  }

  _stopRecording() {
    return new Promise((resolve) => {
      this._mediaRecorder.ondataavailable = e => {
        this._mediaRecorder.ondataavailable = null;

        resolve(new Blob([e.data], { type: MIME_TYPE }));
      };

      this._mediaRecorder.stop();
    });
  }

  _finalizeRecording(audioBlob) {
    this._audioBlob = audioBlob;
    this._audioFile = new File([this._audioBlob], AUDIO_FILE_NAME, { type: MIME_TYPE });

    this._stopDurationInterval();

    this._userMediaManager.closeLocalMediaStream();
    this._mediaRecorder = null;

    this._state = ARC_DATA_AVAILABLE;
    this.emit('update');
  }

  clear() {
    this._audioBlob = null;
    this._audioFile = null;
    this._state = ARC_READY;
    this.emit('update');
  }

  record() {
    if (this._state === ARC_RECORDING || this._state === ARC_PLAYING) {
      return;
    }

    const audioOptions = {
      audioOptions: {
        autoGainControl: true,
        noiseSuppression: true,
        echoCancellation: true,
      },
    };

    this._userMediaManager.init();
    this._userMediaManager.getUserMediaInitial(audioOptions)
      .then(() => {
        this._mediaRecorder = new MediaRecorder(this._userMediaManager.localMediaStream);
        this._mediaRecorder.start();

        this._mediaRecorder.onstart = () => {
          this._startDurationInterval();
        };

        this._state = ARC_RECORDING;
        this._duration = 0;
        this.emit('update');
      })
      .catch(err => {
        this._errorMessage = err.message;
        this.emit('update');
      });
  }

  stop() {
    switch (this._state) {
    case ARC_RECORDING:
      this._stopRecording()
        .then(audioBlob => this._finalizeRecording(audioBlob));
      break;

    case ARC_PLAYING:
      this._state = ARC_DATA_AVAILABLE;
      this._audioElement.pause();
      this.emit('update');
      break;
    }
  }

  play() {
    if (this._audioBlob) {
      if (this._state === ARC_RECORDING) {
        return;
      }

      if (this._audioElement.paused) {
        this._state = ARC_PLAYING;
        this.emit('update');

        this._audioElement.src = URL.createObjectURL(this._audioBlob);
        this._audioElement.play();
      }
    }
  }

  static get isSupported() {
    return !!window.MediaRecorder;
  }

  get audioFile() {
    return this._audioFile;
  }

  get inputVolume() {
    return this._userMediaManager.getInputVolume();
  }

  get hasAudioData() {
    return !!this._audioBlob;
  }

  get state() {
    return this._state;
  }

  get canPlay() {
    return !this.hasAudioData || this._state === ARC_RECORDING;
  }

  get duration() {
    return this._duration;
  }

  get isError() {
    return !!this._errorMessage;
  }

  get errorMessage() {
    return this._errorMessage;
  }
}
