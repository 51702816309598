import { PlayerAppController } from './PlayerAppController';

export class EmbeddedPlayerAppController extends PlayerAppController {
  constructor({
    appConfig,
    getConferenceID,
  }) {
    super({
      appConfig,
      formDefaults: {},
    });

    this._getConferenceID = getConferenceID;

    this._isConnectModalOpen = false;
  }

  startConnect() {
    this._isConnectModalOpen = true;
    this.emit('update');
  }

  cancelConnect() {
    this._isConnectModalOpen = false;
    this.emit('update');
  }

  connect(formData) {
    this._isConnectModalOpen = false;
    super.connect(formData);
  }

  get isConnectModalOpen() {
    return this._isConnectModalOpen;
  }
}
