import Alert from 'Components/Alert';
import { show, hide } from 'Components/domHelpers';
import { RefFormData, FormText, wrapSubmitHandler } from 'Components/FormComponents';
import Modal from 'Components/Modal';

import s from './strings';

export default class SingleInputModal {
  static isClassComponent = true;

  constructor({ title, label, invalidErrorMsg = null, onSave, ref }) {
    if (ref) {
      ref(this);
    }

    this._form = new RefFormData();

    const onSaveClick = () => {
      const data = this._form.get('data');

      if (invalidErrorMsg && !data) {
        show(this._alert);
        return;
      }

      this._modal.hide();
      onSave(data);
    };

    this._modal = new Modal({
      appendToBody: true,
      title,
      children: (
        <form onsubmit={wrapSubmitHandler(onSaveClick)}>
          <div class="modal-body">
            <Alert ref={this._alert} msg={invalidErrorMsg} />
            <FormText
              inline
              form={this._form}
              name="data"
              label={label}
            />
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">{s.lblOK}</button>
            <button type="button" class="btn btn-primary" onclick={() => this._modal.hide()}>{s.lblCancel}</button>
          </div>
        </form>
      ),
    });
  }

  display(initialValue = '') {
    hide(this._alert);
    this._form.set('data', initialValue);
    this._modal.show();
  }
}
