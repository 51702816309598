import { NotFoundError, InvalidRequestError } from 'CommonExceptions';
import { ApiErrorResult } from 'Api/ApiErrors';
import Api from 'Api/Api';
import { toggle } from 'Components/domHelpers';
import { hook, Hooks } from 'Components/Hooks';
import MediaElementController from 'Components/MediaElementController';
import MediaElementSupport from 'Components/MediaElementSupport';
import escapeHtml from 'Util/escapeHtml';
import { getTimezoneLabel } from 'DateTime';

import AccessMethods from './AccessMethods';
import Subpage from './Subpage';
import appErrorHandler from './appErrorHandler';
import isPSTN from './isPSTN';
import { Table } from './Tables';
import { PagingToolbar } from './Paging';

import { CallerInfoModal } from './CallerInfoModal';
import CommentDialog from './CommentDialog';
import ModalAlert from './ModalAlert';
import getRecordingURLs from './getRecordingURLs';

import Columns from './Report/Columns';
import SendConferenceReportModal from './Report/SendConferenceReportModal';
import validateTimezone from './Report/validateTimezone';
import validateCdrID from './validateCdrID';

import s from './strings';

const GET_RECORDING = 'getCallerNameRecording';
const GET_RECORDING_ID = 'getCallerNameRecording_confDetails';

export default class ConfDetails extends Subpage {
  constructor() {
    super();

    this._metadata = {
      params: {
        cdrID: {
          key: 'id',
          validateFunc: validateCdrID,
        },
        timezone: {
          validateFunc: validateTimezone,
        },
      },

      options: {
        print: {
          defaultVal: 0,
          validateFunc: this.validateBool,
        },
        back: {
          defaultVal: '',
        },
      }
    };
  }

  init(config) {
    const hooks = this.hooks = new Hooks();

    const root = (
      <div class="subpage subpage-confDetails">
        <div class="subpage-content">
          <div class="subpage-header justify-content-between">
            <div class="logo-container"></div>
            <div class="info-table-container">
              <table>
                <tbody>
                  <tr>
                    <td>{s.lblID}:</td>
                    <td class="cell-data" use:hook={hooks.text('conferenceIDFormatted')} />
                    <td>{s.lblCallers}:</td>
                    <td class="cell-data" use:hook={hooks.text('participants')} />
                  </tr>
                  <tr>
                    <td>{s.lblStart}:</td>
                    <td class="cell-data" use:hook={hooks.text('startedDate')} />
                    <td>{s.lblDuration}:</td>
                    <td class="cell-data" use:hook={hooks.text('duration')} />
                  </tr>
                  <tr>
                    <td>{s.lblEnd}:</td>
                    <td class="cell-data" use:hook={hooks.text('endedDate')} />
                    <td>{s.lblTotalMinutes}:</td>
                    <td class="cell-data" use:hook={hooks.text('totalMinutes')} />
                  </tr>
                  <tr>
                    <td></td>
                    <td colspan="3" class="cell-data" use:hook={hooks.text('timezoneDisplay')} />
                  </tr>
                  <tr use:hook={hooks.show('showComment')}>
                    <td>{s.lblReference}:</td>
                    <td colspan="3" class="cell-data" use:hook={hooks.text('comment')} />
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="btn-toolbar-discrete align-self-start print-hide">
              <button
                type="button"
                class="btn btn-primary"
                use:hook={hooks.show('recordingExists')}
                onclick={() => this._openRecording()}
              >
                {s.lblRecording}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                use:hook={hooks.show('recordingPlayback')}
                onclick={() => this._openRecordingPlayback()}
              >
                {s.Report.recordingPlayback}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                use:hook={hooks.show('showComment')}
                onclick={() => this._commentDialog.fetchByID(this._params.cdrID)}
              >
                {s.lblEditReference}
              </button>
              <button
                type="button"
                class="btn btn-primary btn-print"
              >
                {s.lblPrint}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onclick={() => this._sendConferenceReportModal.display(this._params.cdrID, this._notificationList)}
              >
                {s.lblEmail}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onclick={() => this.back()}
              >
                {s.lblBack}
              </button>
            </div>
          </div>

          <ConfDetailsTable ref={this._table} onCellButtonClick={e => this._onCellButtonClick(e)} />
          <PagingToolbar
            ref={this._pagingToolbar}
            resultCountOpts={this._resultCountOpts}
            onPageChange={page => this.onPageChange(page)}
            onResultCountChange={resultCount => this.onResultCountChange(resultCount)} />
        </div>
      </div>
    );

    super.init(root, {
      enablePaging: true,
      defaultBackSubpage: 'report',
    });

    this._config = config;

    this._wavSupported = MediaElementSupport.hasCodec('wav');

    this._sendConferenceReportModal = new SendConferenceReportModal();
    this._commentDialog = new CommentDialog('report', () => this.redispatch());

    this._callerInfoModal = new ConfDetailsCallerInfoModal({
      ctrl: this.ctrl,
      onOpenAddressBook: entryID => {
        let prepopulateData = null;
        if (!entryID) {
          const { number: phoneNumber, name, hostFlag, notesType, notes, notesObject } = this._currentCall;
          prepopulateData = {
            phoneNumber,
            name,
            hostFlag,
            ...(notesType === 'object'
              ? {
                callDataPerm: notesObject,
              }
              : {
                notes,
              }
            ),
          };
        }

        this.openSubpage('addressBookEntry', {
          back: this.getCanonicalHash(),
          ...(entryID && { id: entryID }),
          prepopulateData,
        });
      },
      onSaved: () => this.redispatch(),
    });
  }

  _openRecording() {
    this.openSubpage('conferenceRecording', {
      id: this._params.cdrID,
      timezone: this._params.timezone,
      back: this.getCanonicalHash(),
    });
  }

  _openRecordingPlayback() {
    if (!(this._resultConf && this._resultConf.recordingPlayback))
      return;

    const { recordingPlayback } = this._resultConf;

    this.openSubpage('conferenceRecording', {
      id: recordingPlayback.conferenceID,
      recID: recordingPlayback.recordingID,
      timezone: this._params.timezone,
      back: this.getCanonicalHash(),
    });
  }

  _onCellButtonClick({ key: cdrCallID, itemIdx, colId }) {
    const item = this._result.items[itemIdx];

    switch (colId) {
    case 'callerInfo':
      this._currentCall = item;
      this._callerInfoModal.display(item);
      break;

    case 'playCallerName':
      this._playCallerName(item.cdrID, item.cdrCallID);
      break;
    }
  }

  _playCallerName(cdrID, cdrCallID) {
    const stopOnly = this._activeRecordingID === cdrCallID;

    const params = {
      cdrID,
      cdrCallID,
    };

    const checkParams = {
      ...params,
      checkOnlyFlag: true,
    };

    if (!this._wavSupported) {
      this._downloadWindowRef = window.open('', '_blank');
      if (this._downloadWindowRef.document)
        this._downloadWindowRef.document.write(escapeHtml(s.lblDownloadingInProgress));
    }

    Promise.resolve()
      .then(() => {
        Api.defaultContext.abort(GET_RECORDING_ID);
        if (this._wavSupported) {
          return MediaElementController
            .init()
            .stop();
        }
      })
      .then(() => {
        if (!stopOnly) {
          this._setActiveRecordingID(cdrCallID);
          return Api.get('CDR', GET_RECORDING, checkParams, { cancelID: GET_RECORDING_ID });
        }
      })
      .then(result => {
        if (stopOnly) {
          return;
        }
        let url = result.callerNameRecording && result.callerNameRecording.url;

        const { srcURL, downloadURL } = getRecordingURLs(url, 'CDR', GET_RECORDING, params);

        if (!this._wavSupported) {
          this._downloadWindowRef.location.href = downloadURL;
          return;
        }

        return MediaElementController.play(srcURL, s.lblRecording);
      })
      .catch(err => {
        if (err.cancelled) {
          return;
        }

        if (this._downloadWindowRef) {
          this._downloadWindowRef.close();
        }

        ModalAlert.display(appErrorHandler(err));
      })
      .then(() => {
        this._downloadWindowRef = null;
        this._setActiveRecordingID(null);
      });
  }

  _setActiveRecordingID(id) {
    this._activeRecordingID = id;
    this.render();
  }

  activate() {
    return Promise.resolve()
      .then(() => {
        if (!this._params.cdrID) {
          throw new InvalidRequestError();
        }
      })
      .then(() => this.ctrl.fetchBridgeData())
      .then(bridgeData => {
        this.setFieldDefault('timezone', bridgeData.timezone);
        this._notificationList = '';

        if (this._config.allowEmailListChangeFlag && bridgeData.notificationList && bridgeData.enableReports) {
          this._notificationList = bridgeData.notificationList;
        }

        const { cdrID, timezone } = this._params;

        return Api.get('CDR', 'getConferenceCDR', {
          cdrID,
          timezone,
          dateFormat: 'shortDateTime12Hr',
          showPlaybackFlag: true,
          fields: Columns.CONFERENCE_CDR_BASE_FIELDS,
        });
      })
      .then(result => {
        const conf = result.conferenceCDR && result.conferenceCDR[0];
        if (!conf) {
          throw new NotFoundError();
        }

        conf.recordingPlayback = null;
        if (conf.confType === 'playback' && conf.comment) {
          let dec;
          try {
            dec = JSON.parse(conf.comment);
          } catch (err) {
          }
          if (dec && dec.recordingPlayback) {
            const { recordingPlayback } = dec;
            if (recordingPlayback.accountID && recordingPlayback.conferenceID && recordingPlayback.recordingID)
              conf.recordingPlayback = recordingPlayback;
          }
        }

        this._resultConf = conf;
      })
      .then(() => Api.get('CDR', 'getConferenceCallCDR', {
        ...this.getStateValues([
          'cdrID', 'startOffset', 'resultCount', 'timezone'
        ]),
        showPlaybackFlag: true,
        dateFormat: 'time12Hr',
        fields: 'cdrID, cdrCallID, callerID, location, name, userID, ' +
          'accessMethod, accessMethodDisplay, hostFlag, ' +
          'number:fromNumber, numberFormatted:fromNumberFormatted, ' +
          'toNumberFormatted, ' +
          'callStartedDate, callEndedDate, durationMinutes:callDurationMinutes, callerNameRecordedFlag, ' +
          'notes,',
      }))
      .then(result => {
        this._result = result;
        this._result.items = result.conferenceCallCDR || [];

        if (!this.updatePaging(result)) {
          return;
        }

        this._result.hasNameRecording = false;
        this._result.items.forEach(cur => {
          this._mungeConferenceCall(cur);

          if (cur.callerNameRecordedFlag)
            this._result.hasNameRecording = true;
        });

        this.render();
      })
      .catch(err => {
        if (err instanceof InvalidRequestError) {
          this.displayError('ERR_INVALID_REQUEST', true);
          return;
        }
        if (err instanceof NotFoundError) {
          this.displayError('ERR_CDR_NOT_FOUND', true);
          return;
        }

        throw err;
      });
  }

  detach() {
    Api.defaultContext.abort(GET_RECORDING_ID);
  }

  render() {
    const print = !!this._options.print;

    this.setPrintView(print);

    const {
      conferenceIDFormatted,
      startedDate,
      endedDate,
      participants,
      comment,
      confType,

      durationMinutes,
      totalMinutes,

      recordingExists,
      recordingPlayback,
    } = this._resultConf;

    this.hooks.run({
      conferenceIDFormatted,
      startedDate,
      endedDate,
      participants: confType !== 'playback'
        ? participants
        : s.Report.playbackLabel,
      comment,
      showComment: comment && confType === 'conference',
      duration: `${durationMinutes} ${s.lblMin}`,
      totalMinutes: `${totalMinutes} ${s.lblMin}`,
      timezoneDisplay: getTimezoneLabel(this._params.timezone),
      recordingExists,
      recordingPlayback,
    });

    this._pagingToolbar.render(this.getPagingState());

    const { items, hasNameRecording } = this._result;
    const extra = {
      print,
      hasNameRecording,
      activeRecordingID: this._activeRecordingID,
    };

    this._table.clear();
    this._table.render(items, extra);
  }

  _mungeConferenceCall(call) {
    call.callerIDDisplay = call.callerID;
    if (call.accessMethod != AccessMethods.PSTN) {
      call.callerIDDisplay = call.accessMethodDisplay + ' - ' + call.callerIDDisplay;
    }

    if (!call.name)
      call.name = s.lblUnknown;

    call.nameDisplay = call.name;
    if (call.userID)
      call.nameDisplay = call.userID + ' - ' + call.nameDisplay;

    let notesType = null;
    let notes = null;
    let notesObject = null;
    if (this.ctrl.notesFields) {
      notesType = 'object';
      if (call.notes) {
        if (typeof call.notes === 'object') {
          notesObject = call.notes;
        } else if (typeof call.notes === 'string') {
          // fallback to notesType string if call.notes is a string
          notesType = 'string';
          notes = call.notes;
        }
      }
    } else {
      notesType = 'string';
      if (call.notes && typeof call.notes === 'string')
        notes = call.notes;
    }
    call.notesType = notesType;
    call.notes = notes;
    call.notesObject = notesObject;
  }
}

class ConfDetailsTable extends Table {
  constructor({ ref, onCellButtonClick }) {
    super({
      ref,
      onCellButtonClick,
      className: 'nowrap striped dataTable subpage-table',
      itemKey: 'cdrCallID',
      noDataString: s.lblNoData,
      columns: [
        {
          colKey: 'callerID',
          className: 'column-callerID',
          title: s.lblCallerID,
        },
        {
          colKey: 'location',
          title: s.lblCallerLocation,
        },
        {
          id: 'callerInfo',
          colKey: 'nameDisplay',
          title: s.lblCallerName,
          create(cell) {
            return (
              <>
                <button class="btn btn-link caller-name print-hide" type="button">{cell}</button>
                <div class="caller-name print-only">{cell}</div>
              </>
            );
          }
        },
        {
          colKey: 'accessMethodDisplay',
          title: s.lblAccessMethod,
        },
        {
          colKey: 'hostFlag',
          className: 'shrink',
          title: s.lblReportHost,
          create(cell) {
            return cell ? s.lblYes : '';
          },
        },
        {
          colKey: 'callStartedDate',
          className: 'text-right shrink',
          title: s.lblJoined,
        },
        {
          colKey: 'callEndedDate',
          className: 'text-right shrink',
          title: s.lblDeparted,
        },
        {
          colKey: 'durationMinutes',
          className: 'text-right shrink',
          title: s.lblDuration,
          create(cell) {
            return `${cell} ${s.lblMin}`;
          },
        },
        {
          id: 'playCallerName',
          colKey: 'callerNameRecordedFlag',
          extraKey: 'activeRecordingID',
          className: 'hover-cell shrink',
          visibility: ({ print, hasNameRecording }) => !print && hasNameRecording,
          create(callerNameRecordedFlag, activeRecordingID, cdrCallID) {
            if (!callerNameRecordedFlag) return '';

            const playClass = activeRecordingID === cdrCallID
              ? 'btnStop'
              : 'btnPlay';

            return <button type="button" class={`btn-table-icon ${playClass}`} />;
          },
        },
      ],
    });
  }
}

class ConfDetailsCallerInfoModal {
  constructor({ ctrl, onOpenAddressBook, onSaved }) {
    const openAddressBook = () => {
      this._modal.hide();
      onOpenAddressBook(this._entryID);
    };

    this._onSaved = onSaved;

    const getPlaybackURLs = () => {
      return getRecordingURLs('', 'CDR', GET_RECORDING, { ...this._idParams });
    };

    this._modal = new CallerInfoModal({
      ctrl,
      getPlaybackURLs,
      footer: (
        <div class="d-flex">
          <button type="button" class="btn btn-primary" onclick={openAddressBook} ref={this._addressBookLink}>{s.lblCallerList}</button>
          <button type="submit" class="btn btn-primary ml-auto">{s.lblSave}</button>
          <button type="button" class="btn btn-primary" onclick={() => this._modal.hide()}>{s.lblCancel}</button>
        </div>
      ),
      onSubmit: () => this.save(),
    });

    this._entryID = null;
  }

  display(call) {
    this._modal.showStart();

    this._idParams = {
      cdrID: call.cdrID,
      cdrCallID: call.cdrCallID,
    };
    this._entryID = null;

    let showAddressBookLink = isPSTN(call);

    Promise.resolve()
      .then(() => {
        if (showAddressBookLink) {
          return this._lookup(call)
            .then(lookupCall => {
              if (lookupCall)
                this._entryID = lookupCall.entryID;
              return call;
            })
            .catch(err => {
              if (err instanceof ApiErrorResult) {
                if (err.isInvalidParamError() && err.parameterName === 'phoneNumber') {
                  // render without showAddressBookLink
                  showAddressBookLink = false;
                  return call;
                }
              }

              throw err;
            });
        }

        return call;
      })
      .then(call => {
        toggle(this._addressBookLink, showAddressBookLink);

        this._modal.showFinish({
          ...call,
          nameRecorded: call.callerNameRecordedFlag,
        });
      })
      .catch(err => {
        this._modal.hide();
        ModalAlert.display(appErrorHandler(err));
      });
  }

  _lookup(call) {
    const params = {
      phoneNumber: call.number,
      resultCount: 1,
    };

    return Api.get('Bridge', 'getAddressBook', params)
      .then(res => res.addressBookEntry && res.addressBookEntry[0]);
  }

  save() {
    const data = this._modal.form.getData();

    const { name, notesType } = data;
    const params = {
      ...this._idParams,
      name,
      notesType,
      notes: notesType === 'string'
        ? data.notes
        : data.notesObject,
    };

    this._modal.loading.show();

    Api.get('CDR', 'setCallNotes', params)
      .then(() => {
        this._modal.hide();
        this._onSaved();
      })
      .catch(err => {
        if (err.cancelled) {
          return;
        }

        let errorCode;

        if (err instanceof ApiErrorResult) {
          if (err.isInvalidParamError() && err.parameterName === 'name') {
            errorCode = 'ERR_INVALID_NAME';
          }
        }

        if (!errorCode)
          errorCode = appErrorHandler(err);

        this._modal.displayError(errorCode);
      })
      .then(() => this._modal.loading.hide());
  }
}
