import Loader from 'Loader';
import { AuthError } from 'CommonExceptions';
import errorReport from 'Error/report';
import Alert from 'Components/Alert';
import { cachedUpdate, toggleClass, hide } from 'Components/domHelpers';
import { Cond } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import Tabs from 'Components/Tabs';

import BridgeInfoTable from './BridgeInfoTable';
import { LoginController } from './LoginController';
import LoginControl from './LoginControl';
import { LoginInfo } from './LcmComponents';
import ConfController from './ConfController';
import SubConfManager from './SubConfManager';
import SubpageDispatcher from './SubpageDispatcher';
import ModalConfirm from './ModalConfirm';
import WebCallLoader from './WebCallLoader';
import ErrorReportModal from './ErrorReportModal';

import Chat from './Chat';

// subpages
import Lcm from './Lcm';
import Report from './Report';
import ConfDetails from './ConfDetails';
import Settings from './Settings';
import AddressBook from './AddressBook';
import AddressBookEntry from './AddressBookEntry';
import Users from './Users';
import UserID from './UserID';
import Info from './Info';
import Wallet from './Wallet';
import ConferenceRecording from './ConferenceRecording';
import Recordings from './Recordings';
import UploadRecording from './UploadRecording';
import EmailBridgeInfo from './EmailBridgeInfo';
import AccountAdminSettings from './AccountAdminSettings';

import s from './strings';

export default class LcmPortal {
  constructor(options) {
    const { portal: portalConfig } = options.moduleConfig;

    const onSubpageChange = tabKey => {
      this._tabs.setActive(tabKey);
    };
    const onDispatchError = err => {
      if (err instanceof AuthError) {
        setTimeout(() => {
          this.loginController.logoutWithError('ERR_AUTHENTICATION');
        }, 0);

        // SubpageDispatcher should ignore
        return true;
      }

      // SubpageDispatcher should handle
      return false;
    };
    this.subpageDispatcher = new SubpageDispatcher({ onSubpageChange, onDispatchError });

    this.ctrl = new ConfController(options.moduleConfig.confController);
    this.ctrl.on('update', () => this.render());
    this.ctrl.on('changeSubConfID', subConfID => this.subpageDispatcher.openSubpage('lcm', { id: subConfID }));

    this.loginController = new LoginController(portalConfig.login);
    this.loginController
      .on('update', () => this.render())
      .on('login', identity => {
        this.ctrl.start(identity);
        this.subpageDispatcher.start();
      })
      .on('logout', () => {
        this.ctrl.stop();
        this.subpageDispatcher.stop();
      });

    this._webCallLoader = new WebCallLoader({
      confController: this.ctrl,
      confConfig: options.moduleConfig.webCallConfApp,
      playerConfig: options.moduleConfig.webCallPlayer,
    });

    this._modalWebCallLogout = new ModalConfirm({
      title: s.lblWebCall,
      message: s.lblWebCallModalLogoutLabel,
      confirmLabel: s.lblHangupAndLogout,
      confirm: () => {
        if (this._webCallLoader.isConnected()) {
          this._webCallLoader.disconnect();
        }

        this.loginController.logout();
      }
    });

    const modules = {
      lcm         : Lcm,
      report      : Report,
      confDetails : ConfDetails,
      settings    : Settings,
      addressBook : AddressBook,
      addressBookEntry: AddressBookEntry,
      users       : Users,
      userID      : UserID,
      info        : Info,
      wallet      : Wallet,
      conferenceRecording: ConferenceRecording,
      recordings: Recordings,
      uploadRecording: UploadRecording,
      emailBridgeInfo: EmailBridgeInfo,
      accountAdminSettings: AccountAdminSettings,
    };

    const tabsRight = [
      {
        label: s.lblLogout,
        onclick: () => this._onLogoutClick(),
      },
    ];

    const hooks = this.hooks = new Hooks();
    const hooksFeatures = this.hooksFeatures = new Hooks();
    const hooksLogin = this.hooksLogin = new Hooks();

    this.root =
      <div class="portal-container">
        <div class="portal-container-content" use:hook={hooksLogin.show('loggedIn')}>
          <div class="app-main" ref={this._appMain}>
            <div class="app-header">
              <div class="logo-container"></div>
              <BridgeInfoTable ctrl={this.ctrl} maskPin hideConferenceID={portalConfig.hideConferenceID} />
              <Cond test={this._webCallLoader.confAppUI}>
                <div
                  class="webcall-conf-app-container print-hide"
                  use:hook={hooksFeatures.show('webCall')}
                >
                  {this._webCallLoader.confAppUI}
                </div>
              </Cond>
            </div>

            <Tabs ref={this._tabs} tabsRight={tabsRight} />

            <div class="container-fluid">
              {this.subpageDispatcher.root}
              <div class="app-main-subpages" />
              <LoginInfo hooks={hooks} />
            </div>
          </div>
          <Cond test={this.ctrl.chatController}>
            <Chat ctrl={this.ctrl.chatController} />
          </Cond>
        </div>
        <div class="portal-container-panel" use:hook={hooksLogin.hide('loggedIn')}>
          <div class="portal-container-panel-inner">
            <div class="logo-container" />

            <Cond test={portalConfig.noticeMarkup}>
              <Alert type="warning" ref={this._noticeAlert} />
            </Cond>

            <LoginControl ctrl={this.loginController} />
          </div>
        </div>
      </div>;

    document.body.append(this.root);

    if (portalConfig.noticeMarkup)
      this._noticeAlert.innerHTML = portalConfig.noticeMarkup;

    const tabs = [];

    options.internalTabs.forEach(tab => {
      const { moduleName, hash, label, cssClass, parent } = tab;

      if (!modules[moduleName])
        return;

      if (moduleName === 'accountAdminSettings' && !portalConfig.login.useAccountAuth)
        return;

      if (!parent) {
        tabs.push({
          key: moduleName,
          href: `#${hash}`,
          label,
          cssClass,
          onclick: () => {
            this.subpageDispatcher.openSubpage(moduleName);
          },
        });
      }

      const moduleClass = modules[moduleName];
      const config = options.moduleConfig[moduleName] || {};
      const module = new moduleClass();
      module.moduleName = moduleName;
      module.tabKey = parent || moduleName;
      module._portal = this;

      this.subpageDispatcher.addSubpage(moduleName, module, hash);

      module.init(config);
    });

    this._tabs.setTabs([
      ...tabs,

      ...options.externalTabs.map(tab => ({
        isLink: true,
        label: tab.label,
        href: tab.url,
        target: '_blank',
      })),

      ...(options.enableReportError && [{
        label: s.lblReportError,
        onclick: () => this._errorReportModal.display(),
      }] || []),

      ...(options.helpURL && [{
        isLink: true,
        label: s.lblHelp,
        href: options.helpURL,
        target: '_blank',
      }] || []),
    ]);

    this._errorReportModal = new ErrorReportModal();
    errorReport.on('preSend', e => {
      const {
        partnerID,
        accountID,
        accountName,
        bridgeID,
        conferenceID,
      } = this.ctrl.authInfo;

      e.reportObject.appData = {
        partnerID,
        accountID,
        accountName,
        bridgeID,
        conferenceID,
      };
    });

    this._subConfManager = new SubConfManager(this.subpageDispatcher, this._tabs, this.ctrl);

    if (options.appMainMaxWidth) {
      this._appMain.style.maxWidth = `${options.appMainMaxWidth}px`;
    }

    if (options.compact) {
      this.root.classList.add('app-compact');
    }

    if (!options.showTabs) {
      hide(this._tabs.root);
    }

    this._updaters = {
      logoPath: cachedUpdate(val => {
        Loader.setLogo(val);
      }),
      writable: cachedUpdate(val => {
        toggleClass(document.body, 'view-ro', !val);
      }),
    };

    this._webCallLoader.start();
  }

  start() {
    return this.loginController.start();
  }

  isLogoutAllowed() {
    if (this._webCallLoader.isConnected()) {
      this._modalWebCallLogout.display();
      return false;
    }

    return true;
  }

  _onLogoutClick() {
    if (!this.isLogoutAllowed()) return;

    this.loginController.logout();
  }

  render() {
    const { logoPath, writable } = this.ctrl;
    this._updaters.logoPath(logoPath);
    this._updaters.writable(writable);

    this.hooks.run(this.ctrl);
    this.hooksFeatures.run(this.ctrl.features);
    this.hooksLogin.run(this.loginController.loginState);
  }
}
