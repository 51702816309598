import parseQueryString from 'Browser/parseQueryString';

export default class WindowEventHandler {
  constructor({ ctrl, handleHash = false }) {
    this.ctrl = ctrl;
    this._handleHash = handleHash;

    this._hashParams = {};

    if (handleHash) {
      if (window.location.hash.length) {
        this._hashParams = this._parseHashParams();
      }
      this._applyHashChange();

      window.addEventListener('hashchange', this._hashChange.bind(this));
    }
  }

  _parseHashParams() {
    const tmp = parseQueryString(window.location.hash.substr(1)),
        ret = {};

    for (let i in tmp)
      ret[i.toLowerCase()] = tmp[i];

    return ret;
  }

  _hashChange() {
    this._hashParams = this._parseHashParams();

    this._applyHashChange();
  }

  _applyHashChange() {
    const debugMode = 'debug' in this._hashParams && (this._hashParams.debug || this._hashParams.debug === '');

    this.ctrl.setDebugMode(!!debugMode);
  }

  get hashParams() {
    return this._hashParams;
  }
}
