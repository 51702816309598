import { CallController } from './CallController';
import PlayerClient from './PlayerClient';

export class PlayerCallController extends CallController {
  constructor(opts) {
    super(opts);

    this._loadingState = 'unselected';
    this._fileName = '';
  }

  _initClient(clientOpts) {
    this.client = new PlayerClient(clientOpts);

    this.client.on('stateChange', e => {
      if (e.state === PlayerClient.STATE.CONNECTED) {
        this.client.play();
      }
    });
  }

  loadFile(files, fileName) {
    this._fileName = fileName;
    this._loadingState = 'unselected';

    if (files.length) {
      this._loadingState = 'loading';

      const file = files[0];
      const reader = new FileReader();

      reader.onload = e => {
        this.log('reader.onload success');

        this.client.setAudioData(e.target.result)
          .then(() => {
            this._loadingState = 'decodeSuccess';
          })
          .catch(err => {
            this._loadingState = 'decodeFailure';
          })
          .then(() => {
            this.emit('update');
          });
      };

      reader.readAsArrayBuffer(file);
    }

    this.emit('update');
  }

  togglePause() {
    this.client.togglePause();
    this.emit('update');
  }

  get loadingState() {
    return this._loadingState;
  }

  get fileName() {
    return this._fileName;
  }

  get isPlaying() {
    return this.client.isPlaying();
  }

  get isPaused() {
    return !this.client.isPlaying();
  }

  get playbackPosition() {
    if (!this.isConnected)
      return 0;

    return this.client.getCurrentTime();
  }
}
