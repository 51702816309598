import { ApiErrorResult } from 'Api/ApiErrors';
import Api from 'Api/Api';
import Alert from 'Components/Alert';
import { show, hide } from 'Components/domHelpers';
import { RefFormData, FormTextarea } from 'Components/FormComponents';

import appErrorHandler from './appErrorHandler';

import s from './strings';
import errors from './errors';

export default class EmailBridgeInfoForm {
  static isClassComponent = true;

  constructor({ onSuccess, ref }) {
    ref(this);

    this._onSuccess = onSuccess;
    this._form = new RefFormData();

    this.root = (
      <>
        <Alert ref={this._alert} />
        <FormTextarea form={this._form} inline name="email" label={s.EmailBridgeInfo.textarea} inputAttributes={{rows: 3}} />
        <p><strong>{s.EmailBridgeInfo.noteLabel}</strong> {s.EmailBridgeInfo.note1}</p>
        <p>{s.EmailBridgeInfo.note2}</p>
      </>
    );
  }

  render() {
    hide(this._alert);
    this._form.set('email', '');
  }

  send() {
    const params = {
      email: this._form.get('email'),
    };

    Api.get('Bridge', 'emailInfo', params)
      .then(() => {
        this._onSuccess();
      })
      .catch(err => {
        let errorCode;
        if (err instanceof ApiErrorResult) {
          if (err.isInvalidParamError() && err.parameterName === 'email')
            errorCode = 'ERR_INVALID_EMAIL';
        }
        if (!errorCode) {
          errorCode = appErrorHandler(err);
        }

        this._alert.textContent = errors[errorCode];
        show(this._alert);
      });
  }
}
