import Client from './Client';

const CONF_INT_ATTRIBUTES = [
  'host', 'muted', 'hold', 'handRaised', 'inGain', 'outGain', 'confLocked', 'muteLocked', 'recordCall', 'musicOnHold'
];

const CONF_BOOL_ATTRIBUTES = [
  'host', 'muted', 'hold', 'confLocked', 'muteLocked', 'musicOnHold'
];

export default class ConfClient extends Client {
  constructor(options) {
    super(options);

    this.status = null;
    this._outGain = 0;

    this._setupEvents();
  }

  setConnectParams(connectParams) {
    super.setConnectParams(connectParams);
    this._outGain = this._connectParams.outGain;
  }

  _setupEvents() {
    this.on('newMessage', this._newMessage.bind(this));
    this.on('stateChange', e => {
      if (e.state === Client.STATE.DISCONNECTED) {
        this.status = null;
      }
    });
  }

  _newMessage(e) {
    if (e.originator == 'remote' && e.parsedBody) {
      this.status = e.parsedBody;

      if (this.status)
        this._confUpdate();
    }
  }

  _confUpdate() {
    // sanitize
    CONF_INT_ATTRIBUTES.forEach(name => {
      if (this.status[name] === undefined)
        this.status[name] = 0;
      else
        this.status[name] = parseInt(this.status[name], 10);

      if (CONF_BOOL_ATTRIBUTES.indexOf(name) >= 0) {
        this.status[name] = !!this.status[name];
      }
    });

    var muted = this.getConfMute();
    if (muted != this.getMute() && !this.getHold()) {
      this.setMute(muted);
    }

    this._emitEvent('confUpdate');
  }

  /* getters */

  getOutGain() {
    if (this.status)
      return  this.status.outGain;

    return this._outGain;
  }

  getHost() {
    if (this.status)
      return  this.status.host;

    return false;
  }

  getConfMute() {
    if (this.status)
      return  this.status.muted;

    return false;
  }

  getHandRaised() {
    if (this.status)
      return  this.status.handRaised;

    return false;
  }

  getLocked() {
    if (this.status)
      return  this.status.confLocked;

    return false;
  }

  getRecording() {
    if (this.status)
      return  this.status.recordCall;

    return 0;
  }

  getCallStatus() {
    if (this.status)
      return  this.status.status;

    return null;
  }

  getConfStatus() {
    if (this.status)
      return  this.status.confStatus;

    return 'pending';
  }

  getConfMode() {
    if (this.status)
      return  this.status.confMode;

    return 'conversation';
  }

  getConfStartMode() {
    if (this.status)
      return  this.status.confStart;

    return 'instant';
  }

  getMuteLocked() {
    if (this.status)
      return  this.status.muteLocked;

    return false;
  }

  getMusicOnHold() {
    if (this.status)
      return  this.status.musicOnHold;

    return false;
  }

  /* Commands/setters */

  setConfMute(flag) {
    this.sendConfCommand('mute', flag ? 1 : 0);
  }

  toggleMute() {
    const mute = !this.getConfMute();
    this.setConfMute(mute);

    this.setMute(mute || this.getHold());
  }

  toggleHold() {
    const hold = !this.getHold();
    this.setHold(hold);

    this.setMute(hold || this.getConfMute());
  }

  toggleHandRaised() {
    this.sendConfCommand('raiseHand');
  }

  toggleMusicOnHold() {
    this.sendConfCommand('moh');
  }

  toggleLocked() {
    this.sendConfCommand('lock');
  }

  toggleRecording() {
    this.sendConfCommand('callRecording', this.getRecording() == 1 ? 0 : 1);
  }

  startConference() {
    this.sendConfCommand('hostConfirm');
  }

  endConference() {
    this.sendConfCommand('endConference');
  }

  setOutGain(value) {
    this.sendConfCommand('setOutGain', value);
  }

  setConfMode(value) {
    this.sendConfCommand('confMode', value);
  }

  changeRole(value) {
    this.sendConfCommand('changeRole', value);
  }

  setRecording(value) {
    this.sendConfCommand('callRecording', value);
  }

  setLocked(value) {
    this.sendConfCommand('lock', value);
  }

  /* Command implementation */

  sendConfCommand(command, value = null) {
    this.log(`sendConfCommand(${command}, ${value})`);

    this.sendJsonINFO({
      command,
      ...(value !== null && { value }),
    });
  }
}
